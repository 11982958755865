<div class="container mt-3 mw-70 w-mobile-100 mw-mobile-100">
  <div class="row" *ngIf="!this.loader">
      <div class="col-12 text-center">
          <img src="../../../assets/images/loader.gif" />
      </div>
  </div>

  <div class="row mb-3 justify-content-center text-center" *ngIf="this.loader">
    <div class="col-12">

      <div class="row">
        <div class="col-12 withoutPaddingCol">
          <img class="w-80" style="width: 100%" [src]="this.advserveDataPromotion.content.images.web.es[0].url"/>
        </div>
        <div class="col-12 mt-5 row justify-content-center blue-text bigger mx-2">
          <span class="titleLanding" [innerHTML]="this.advserveDataPromotion.content.item_ld.es"></span>
        </div>
        <div class="col-12 mt-2 row justify-content-center blue-text bigger mx-2">
          <span class="instructionLanding" [innerHTML]="this.advserveDataPromotion.content.instructions.es.web"></span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 d-flex justify-content-center mx-2">
          <div class="row text-center mb-4 mt-4">
            <div class="card card-menu"  >
              <h5 class="card-title my-4 blue-text px-5 pt-3">
                  Introduce tu código
                  <i *ngIf="this.advserveDataPromotion.content.data.es !== ''"
                    [placement]="['right', 'bottom', 'auto']"
                    [ngbPopover]="this.advserveDataPromotion.content.data.es.tooltip"
                    class="fas fa-sm fa-info-circle iconInfo2"></i>
              </h5>
              <div class="card-body d-flex flex-column p-5">
                <input class="text-center mb-5 inputRegister" name="code" [(ngModel)]="this.code" id="code" placeholder="000000" type="text"/>
                <div class="row mt-3" *ngIf="this.advserveDataPromotion.content.legalLink.es !== undefined">
                  <a class="linkLegal" target="_blank" [attr.href]="this.advserveDataPromotion.content.legalLink.es">Consulta las Condiciones legales de esta promoción</a>
                </div>
                <button class="btn btnEnviarForms mt-5" (click)="this.sendCode()">Validar código</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>


<!-- Modal -->
<div class="modal fade" id="exampleModalNotificationLanding" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 filaLogo">
                        <img src="../../../assets/images/LOGO-NaosClub.png" />
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12 filaLogo2" id="contenidodiv" [innerHTML]="this.textModal">
                        <!-- {{ this.textModal }} -->
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalNotificationLandingError401" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-body">
              <div class="row">
                  <div class="col-md-12 filaLogo">
                      <img src="../../../assets/images/LOGO-NaosClub.png" />
                  </div>
              </div><br>
              <div class="row">
                  <div class="col-md-12 filaLogo2" id="contenidodiv">
                    No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: <a href="mailto:infonaosclub@es.naos.com"></a>infonaosclub&#64;es.naos.com.
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
          </div>
      </div>
  </div>
</div>

<div class="modal fade" id="exampleModalNotificationLandingError406" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-body">
              <div class="row">
                  <div class="col-md-12 filaLogo">
                      <img src="../../../assets/images/LOGO-NaosClub.png" />
                  </div>
              </div><br>
              <div class="row">
                  <div class="col-md-12 filaLogo2" id="contenidodiv">
                    Para validar este código y que te podamos enviar tu regalo debes completar tu dirección postal y teléfono en tu <a href="/profilepage">Perfil</a>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
          </div>
      </div>
  </div>
</div>

<div class="modal fade" id="exampleModalNotificationLandingError4065" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-body">
              <div class="row">
                  <div class="col-md-12 filaLogo">
                      <img src="../../../assets/images/LOGO-NaosClub.png" />
                  </div>
              </div><br>
              <div class="row">
                  <div class="col-md-12 filaLogo2" id="contenidodiv">
                    El código introducido ha caducado.
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
          </div>
      </div>
  </div>
</div>