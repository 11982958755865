<div class="container w-mobile-100 mw-mobile-100">
    <ng-container *ngIf="!this.loader">
        <div class="row loader">
            <div class="col-md-12">
                <img src="../../../assets/images/loader.gif" />
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="this.loader">
        <div class="row justify-content-center">
            <div class="col-12 col-md-7 col-lg-4  text-center my-5 align-self-center"><img class="w-100 h-auto" src="https://lsp-images.s3.eu-west-1.amazonaws.com/f3da8c048aa2e1c3ddc2da150cec8768b960a0d7/pre/webcontent/logo_naosclub.png" /></div>
        </div>
        <div class="row rowButtons justify-content-center py-2 py-sm-5 mb-3">
            <div class="col-6 mt-2 mb-4" style="text-align: right;">
                <span class="buttonLogin" (click)="this.goToLogin()">Inicia sesión</span>
            </div>
            <div class="col-6 mt-2 mb-4">
                <span class="buttonRegister">Regístrate</span>
            </div>
        </div>
        <div class="row lineaContenedorRegister justify-content-center">
            <div class="col-12 col-lg-8 columnaRegister columnaRegisterResponsive">
                <div class="container containerMenuNaos w-100 px-0">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row" style="margin-bottom: 5%">
                                <div class="col-md-12 contenedorDatosRegister">
                                    <div class="row mb-2">
                                        <div class="col-md-12 titleDatosRegister">
                                          <div class="row">
                                            <div class="col-12">
                                              <h5>Datos personales</h5>
                                            </div>
                                          </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6>Los campos con * son obligatorios</h6>
                                                </div>
                                              </div>
                                        </div>
                                    </div>
                                    <div class="row rowDatosPersonalesRegister hidden-mobile">
                                        <div class="col-md-12 datosPersonales">
                                            <div class="row">
                                                <div class="col-md-12">
                                                  <div class="form-group row" style="margin-bottom: 5%">
                                                    <label class="col-sm-4 col-form-label">Nombre*</label>
                                                    <div class="col-sm-8">
                                                      <input type="text" [(ngModel)]="this.name" name="name" class="form-control inputRegister" (keyup)="this.saveDataObject();this.validate30Name(this.name)" id="name" autocomplete="off">
                                                    </div>
                                                  </div>
                                                  <div class="form-group row" style="margin-bottom: 5%">
                                                    <label class="col-sm-4 col-form-label">Apellidos</label>
                                                    <div class="col-sm-8">
                                                      <input type="text" [(ngModel)]="this.surname" name="surname" class="form-control inputRegister" (keyup)="this.saveDataObject();this.validate30Surname(this.surname)" id="surname" autocomplete="off">
                                                    </div>
                                                  </div>
                                                  <div class="form-group row" style="margin-bottom: 5%">
                                                    <label class="col-sm-4 col-form-label">Email*</label>
                                                    <div class="col-sm-8">
                                                      <input type="text" [(ngModel)]="this.email" name="email" class="form-control inputRegister" (keyup)="this.saveDataObject()" id="email" autocomplete="off">
                                                    </div>
                                                  </div>
                                                  <div class="form-group row" style="margin-bottom: 5%">
                                                    <label class="col-sm-3 col-form-label">Contraseña* </label>
                                                      <label class="col-sm-1 col-form-label">
                                                        <i placement="right"
                                                        ngbPopover="La contraseña debe tener una longitud entre 8 y 20 caracteres y contener mayúsculas, minúsculas y números."
                                                        class="fas fa-info-circle iconInfo"></i>
                                                      </label>
                                                    <div class="col-sm-8">
                                                      <div class="form-group">
                                                        <div class="input-group" id="show_hide_password">
                                                          <input class="form-control inputRegister" [(ngModel)]="this.password" name="password" type="password" (keyup)="this.saveDataObject()" autocomplete="off">
                                                          <div class="input-group-addon">
                                                            <a><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="form-group row" style="margin-bottom: 5%">
                                                    <label class="col-sm-3 col-form-label">Repetir<br>contraseña* </label>
                                                      <label class="col-sm-1 col-form-label"><i [placement]="['right', 'bottom', 'auto']" ngbPopover="La contraseña debe tener una longitud entre 8 y 20 caracteres y contener mayúsculas, minúsculas y números."
                                                        class="fas fa-info-circle iconInfo"></i></label>
                                                      <div class="col-sm-8">
                                                      <div class="form-group">
                                                        <div class="input-group" id="show_hide_password2">
                                                          <input class="form-control inputRegister" [(ngModel)]="this.repeatpassword" name="repeatpassword" type="password" (keyup)="this.saveDataObject()" autocomplete="off">
                                                          <div class="input-group-addon">
                                                            <a><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="form-group row" style="margin-bottom: 8%">
                                                    <label class="col-sm-4 col-form-label">Código Postal*</label>
                                                    <div class="col-sm-8">
                                                      <input type="text" [(ngModel)]="this.cp" name="cp" class="form-control inputRegister" (keyup)="this.saveDataObject()" id="cp" autocomplete="off">
                                                    </div>
                                                  </div>

                                                  <div class="form-group row" style="margin-bottom: 8%">
                                                    <label class="col-sm-3 col-form-label fontsizeminus">
                                                      Provincia punto de venta favorito
                                                    </label>
                                                    <label class="col-sm-1 col-form-label"><i [placement]="['right', 'bottom', 'auto']" ngbPopover="La provincia del punto de venta origen es el territorio donde se localiza la Farmacia o Centro de Estética donde te estás dando de alta en NAOS Club. En caso que sea una farmacia o centro de estética online, por favor selecciona el valor 'Farmacia o Centro de Estética online'"
                                                      class="fas fa-info-circle iconInfo"></i></label>
                                                    <div class="col-sm-8">
                                                        <select class="form-control input-select-naos" (change)="this.selectSitesPharmacy();this.saveDataObject()" [(ngModel)]="this.valuePronvinceImportant" id="exampleFormControlSelect1">
                                                          <option value=""> </option>
                                                          <option value="XX">FARMACIA O CENTRO DE ESTÉTICA ONLINE</option>
                                                          <option *ngFor="let province of this.listProvinces" [value]="province.cdprovincia">{{province.dcprovincia}}</option>
                                                        </select>
                                                    </div>
                                                  </div>

                                                  <div class="form-group row" style="margin-bottom: 8%">
                                                    <label class="col-sm-3 col-form-label fontsizeminus">
                                                      Código punto de venta favorito
                                                    </label>
                                                    <label class="col-sm-1 col-form-label">
                                                      <i [placement]="['right', 'bottom', 'auto']" ngbPopover="Es el código de la farmacia (Fxxxx) o centro de estética (CExxxx) donde te estás dando de alta en NAOS Club. En tu punto de venta origen obtendrás el doble de estrellas por tus compras. Si ahora no rellenas este campo, lo podrás completar una vez registrado dentro del apartado 'Mi Perfil'."
                                                      class="fas fa-info-circle iconInfo"></i>
                                                    </label>
                                                    <div class="col-sm-8">
                                                        <div class="dropdown" *ngIf="!this.showInputSite">
                                                            <div id="myDropdownPharmacy" class="dropdown-content">
                                                                <div class="cerrarInputSearchDiv">
                                                                  <a (click)="this.closedInputSearchPharmacy()" class="cerrarInputSearch">Cerrar</a>
                                                                </div>
                                                                <input type="text" placeholder="Buscar..." id="myInputPharmacy" (keyup)="this.filterFunctionPharmacy()" [(ngModel)]="this.stringNamePharmacy">
                                                                <a *ngFor="let itempharmacy of this.puntosVenta" (click)="this.setSelectedSitePharmacy(itempharmacy.where_id, itempharmacy.fullName);this.saveDataObject()">{{itempharmacy.fullName}}</a>
                                                            </div>
                                                        </div>
                                                        <ng-container *ngIf="this.showInputSite">
                                                            <input (click)="this.changeInputForSelect()" class="form-control inputRegister fontsize" type="text" id="myInputPharmacy" [(ngModel)]="this.stringNamePharmacy">
                                                        </ng-container>
                                                    </div>
                                                  </div>

                                                  <div style="background-color: #d3c22a;border-radius: 8px;" class="d-flex p-2 mb-1 mt-2 flex-column flex-md-row align-items-center">
                                                    <!--inicio::Svg Icon -->
                                                    <span style="padding-right: 7px;" class="text-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="width: 2.5rem;height: 2.5rem;fill: white !important;" fill="#fff">
                                                            <rect opacity="0.3" x="2" y="2" rx="10" width="20" height="20" fill="#fff" style="fill: white !important;opacity: 1;"></rect>
                                                            <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                                            <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                                        </svg>
                                                    </span>
                                                    <!--fin:Svg Icon-->
                                                    <!--inicio:Mensaje-->
                                                    <div class="d-flex flex-stack flex-grow-1">
                                                        <div class="fw-normal infoText" style="font-weight: 100;">
                                                            <p style="font-weight: 100;" class="mb-0">Infórmanos de tu punto de venta favorito y recupera el <span class="fw-bold" style="font-weight: 500;">10%</span> de tu compra.</p>
                                                            
                                                        </div>
                                                    </div>
                                                    <!--fin:Mensaje-->
                                                </div>
                                                  
                                                  <!-- <div class="form-group row" style="margin-bottom: 8%">
                                                      <label class="col-sm-3 col-form-label">
                                                        Código empleado de tu punto de venta favorito
                                                      </label>
                                                      <label class="col-sm-1 col-form-label"><i [placement]="['right', 'bottom', 'auto']" ngbPopover="Es el código del empleado de la farmacia o centro de estética que te está ayudando a darte de alta en NAOS Club."
                                                        class="fas fa-info-circle iconInfo"></i></label>
                                                      <div class="col-sm-8">
                                                          <div class="dropdown" *ngIf="!this.showInputEnrolador">
                                                              <div id="myDropdownEnrolador" class="dropdown-content-enrolador">
                                                                  <div class="cerrarInputSearchDiv">
                                                                      <a (click)="this.closedInputEnroladores()" class="cerrarInputSearch">Cerrar</a>
                                                                  </div>
                                                                  <input type="text" placeholder="Buscar..." id="myInputenrolador" (keyup)="this.filterFunctionenrolador()" [(ngModel)]="this.stringNameEnrolador">
                                                                  <a *ngFor="let item of this.enroladores" (click)="this.setSelectedSiteEnrolador(item.identityVld, item.name, item.surname, item.identityVld);this.saveDataObject()">{{item.identityVld}}</a>
                                                              </div>
                                                          </div>
                                                          <ng-container *ngIf="this.showInputEnrolador">
                                                              <input (click)="this.changeInputForSelectEnrolador()" class="form-control inputRegister fontsize" type="text" [(ngModel)]="this.stringNameEnrolador">
                                                          </ng-container>
                                                      </div>
                                                  </div> -->







                                                  




                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row rowDatosPersonalesRegister hidden-desktop hidden-tablet">
                                      <div class="col-md-12 datosPersonales">
                                          <div class="row">
                                              <div class="col-md-12">
                                                <div class="form-group row" style="margin-bottom: 5%">
                                                  <label class="col-sm-4 col-form-label">Nombre*</label>
                                                  <div class="col-sm-8">
                                                    <input type="text" [(ngModel)]="this.name" name="name" class="form-control inputRegister" (keyup)="this.saveDataObject();this.validate30Name(this.name)" id="name" autocomplete="off">
                                                  </div>
                                                </div>
                                                <div class="form-group row" style="margin-bottom: 5%">
                                                  <label class="col-sm-4 col-form-label">Apellidos</label>
                                                  <div class="col-sm-8">
                                                    <input type="text" [(ngModel)]="this.surname" name="surname" class="form-control inputRegister" (keyup)="this.saveDataObject();this.validate30Surname(this.surname)" id="surname" autocomplete="off">
                                                  </div>
                                                </div>
                                                <div class="form-group row" style="margin-bottom: 5%">
                                                  <label class="col-sm-4 col-form-label">Email*</label>
                                                  <div class="col-sm-8">
                                                    <input type="text" [(ngModel)]="this.email" name="email" class="form-control inputRegister" (keyup)="this.saveDataObject()" id="email" autocomplete="off">
                                                  </div>
                                                </div>
                                                <div class="form-group row" style="margin-bottom: 5%">
                                                  <label class="col-sm-4 col-form-label">Contraseña* <label class=" col-form-label"><i [placement]="['right', 'bottom', 'auto']" ngbPopover="La contraseña debe tener una longitud entre 8 y 20 caracteres y contener mayúsculas, minúsculas y números."
                                                    class="fas fa-info-circle iconInfo"></i></label></label>
                                                    
                                                  <div class="col-sm-8">
                                                    <div class="form-group">
                                                      <div class="input-group" id="show_hide_password">
                                                        <input class="form-control inputRegister" [(ngModel)]="this.password" name="password" type="password" (keyup)="this.saveDataObject()" autocomplete="off">
                                                        <div class="input-group-addon">
                                                          <a><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="form-group row" style="margin-bottom: 5%">
                                                  <label class="col-sm-4 col-form-label">Repetir contraseña* <label class="col-form-label"><i [placement]="['right', 'bottom', 'auto']" ngbPopover="La contraseña debe tener una longitud entre 8 y 20 caracteres y contener mayúsculas, minúsculas y números."
                                                    class="fas fa-info-circle iconInfo"></i></label></label>
                                                    
                                                    <div class="col-sm-8">
                                                    <div class="form-group">
                                                      <div class="input-group" id="show_hide_password2">
                                                        <input class="form-control inputRegister" [(ngModel)]="this.repeatpassword" name="repeatpassword" type="password" (keyup)="this.saveDataObject()" autocomplete="off">
                                                        <div class="input-group-addon">
                                                          <a><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="form-group row" style="margin-bottom: 8%">
                                                  <label class="col-sm-4 col-form-label">Código Postal*</label>
                                                  <div class="col-sm-8">
                                                    <input type="text" [(ngModel)]="this.cp" name="cp" class="form-control inputRegister" (keyup)="this.saveDataObject()" id="cp" autocomplete="off">
                                                  </div>
                                                </div>

                                                <div class="form-group row" style="margin-bottom: 8%">
                                                  <label class="col-sm-4 col-form-label fontsizeminus">
                                                    Provincia punto de venta favorito <label class="col-form-label"><i [placement]="['right', 'bottom', 'auto']" ngbPopover="La provincia del punto de venta origen es el territorio donde se localiza la Farmacia o Centro de Estética donde te estás dando de alta en NAOS Club. En caso que sea una farmacia o centro de estética online, por favor selecciona el valor 'Farmacia o Centro de Estética online'"
                                                      class="fas fa-info-circle iconInfo"></i></label>
                                                  </label>
                                                  
                                                  <div class="col-sm-8">
                                                      <select class="form-control input-select-naos" (change)="this.selectSitesPharmacy();this.saveDataObject()" [(ngModel)]="this.valuePronvinceImportant" id="exampleFormControlSelect1">
                                                        <option value=""> </option>
                                                        <option value="XX">FARMACIA O CENTRO DE ESTÉTICA ONLINE</option>
                                                        <option *ngFor="let province of this.listProvinces" [value]="province.cdprovincia">{{province.dcprovincia}}</option>
                                                      </select>
                                                  </div>
                                                </div>

                                                <div class="form-group row" style="margin-bottom: 8%">
                                                  <label class="col-sm-4 col-form-label fontsizeminus">
                                                    Código punto de venta favorito <label class="col-form-label">
                                                      <i [placement]="['right', 'bottom', 'auto']" ngbPopover="Es el código de la farmacia (Fxxxx) o centro de estética (CExxxx) donde te estás dando de alta en NAOS Club. En tu punto de venta origen obtendrás el doble de estrellas por tus compras. Si ahora no rellenas este campo, lo podrás completar una vez registrado dentro del apartado 'Mi Perfil'."
                                                      class="fas fa-info-circle iconInfo"></i>
                                                    </label>
                                                  </label>
                                                  
                                                  <div class="col-sm-8">
                                                      <div class="dropdown" *ngIf="!this.showInputSite">
                                                          <div id="myDropdownPharmacyMobile" class="dropdown-content">
                                                              <div class="cerrarInputSearchDiv">
                                                                <a (click)="this.closedInputSearchPharmacy()" class="cerrarInputSearch">Cerrar</a>
                                                              </div>
                                                              <input type="text" placeholder="Buscar..." id="myInputPharmacyMobile" (keyup)="this.filterFunctionPharmacyMobile()" [(ngModel)]="this.stringNamePharmacy">
                                                              <a *ngFor="let itempharmacy of this.puntosVenta" (click)="this.setSelectedSitePharmacy(itempharmacy.where_id, itempharmacy.fullName);this.saveDataObject()">{{itempharmacy.fullName}}</a>
                                                          </div>
                                                      </div>
                                                      <ng-container *ngIf="this.showInputSite">
                                                          <input (click)="this.changeInputForSelect()" class="form-control inputRegister fontsize" type="text" id="myInputPharmacyMobile" [(ngModel)]="this.stringNamePharmacy">
                                                      </ng-container>
                                                  </div>
                                                </div>

                                                <div style="background-color: #d3c22a;border-radius: 8px;" class="d-flex p-2 mb-1 mt-2 flex-column flex-md-row align-items-center">
                                                  <!--inicio::Svg Icon -->
                                                  <span style="padding-right: 7px;" class="text-center">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="width: 2.5rem;height: 2.5rem;fill: white !important;" fill="#fff">
                                                          <rect opacity="0.3" x="2" y="2" rx="10" width="20" height="20" fill="#fff" style="fill: white !important;opacity: 1;"></rect>
                                                          <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                                          <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                                      </svg>
                                                  </span>
                                                  <!--fin:Svg Icon-->
                                                  <!--inicio:Mensaje-->
                                                  <div class="d-flex flex-stack flex-grow-1">
                                                      <div class="fw-normal infoText" style="font-weight: 100;">
                                                          <p style="font-weight: 100;" class="mb-0">Infórmanos de tu punto de venta favorito y recupera el <span class="fw-bold" style="font-weight: 500;">10%</span> de tu compra.</p>
                                                          
                                                      </div>
                                                  </div>
                                                  <!--fin:Mensaje-->
                                              </div>
                                                
                                                <!-- <div class="form-group row" style="margin-bottom: 8%">
                                                    <label class="col-sm-3 col-form-label">
                                                      Código empleado de tu punto de venta favorito
                                                    </label>
                                                    <label class="col-sm-1 col-form-label"><i [placement]="['right', 'bottom', 'auto']" ngbPopover="Es el código del empleado de la farmacia o centro de estética que te está ayudando a darte de alta en NAOS Club."
                                                      class="fas fa-info-circle iconInfo"></i></label>
                                                    <div class="col-sm-8">
                                                        <div class="dropdown" *ngIf="!this.showInputEnrolador">
                                                            <div id="myDropdownEnrolador" class="dropdown-content-enrolador">
                                                                <div class="cerrarInputSearchDiv">
                                                                    <a (click)="this.closedInputEnroladores()" class="cerrarInputSearch">Cerrar</a>
                                                                </div>
                                                                <input type="text" placeholder="Buscar..." id="myInputenrolador" (keyup)="this.filterFunctionenrolador()" [(ngModel)]="this.stringNameEnrolador">
                                                                <a *ngFor="let item of this.enroladores" (click)="this.setSelectedSiteEnrolador(item.identityVld, item.name, item.surname, item.identityVld);this.saveDataObject()">{{item.identityVld}}</a>
                                                            </div>
                                                        </div>
                                                        <ng-container *ngIf="this.showInputEnrolador">
                                                            <input (click)="this.changeInputForSelectEnrolador()" class="form-control inputRegister fontsize" type="text" [(ngModel)]="this.stringNameEnrolador">
                                                        </ng-container>
                                                    </div>
                                                </div> -->







                                                




                                              </div>
                                          </div>
                                      </div>
                                    </div>

                                    <div class="row rowDatosPersonalesRegister2">
                                      <div class="col-md-12 datosPersonales">
                                        <div class="row">
                                          <div class="col-md-12">

                                            <div class="form-group row hidden-mobile" style="margin-bottom: 8%">
                                              <label class="col-sm-3 col-form-label">
                                                Código Amig&#64;
                                              </label>
                                              <label class="col-sm-1 col-form-label"><i [placement]="['right', 'bottom', 'auto']" ngbPopover="Introduce aquí si tienes un Código Amig@"
                                                class="fas fa-info-circle iconInfo"></i></label>
                                              <div class="col-sm-8">
                                                <input type="text" [(ngModel)]="this.friendscode" name="friendscode" class="form-control inputRegister" (keyup)="this.saveDataObject()" id="friendscode" autocomplete="off">
                                              </div>
                                            </div>

                                            <div class="form-group row hidden-desktop hidden-tablet" style="margin-bottom: 8%">
                                              <label class="col-sm-4 col-form-label">
                                                Código Amig&#64; <label class="col-form-label"><i [placement]="['right', 'bottom', 'auto']" ngbPopover="Introduce aquí si tienes un Código Amig@"
                                                  class="fas fa-info-circle iconInfo"></i></label>
                                              </label>
                                              
                                              <div class="col-sm-8">
                                                <input type="text" [(ngModel)]="this.friendscode" name="friendscode" class="form-control inputRegister" (keyup)="this.saveDataObject()" id="friendscode" autocomplete="off">
                                              </div>
                                            </div>

                                            <div style="background-color: #c0d1d8;border-radius: 8px;" class="d-flex p-2 mb-1 mt-2 flex-column flex-md-row align-items-center">
                                              <!--inicio::Svg Icon -->
                                              <span style="padding-right: 7px;" class="text-center">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="width: 2.5rem;height: 2.5rem;fill: white !important;" fill="#fff">
                                                      <rect opacity="0.3" x="2" y="2" rx="10" width="20" height="20" fill="#fff" style="fill: white !important;opacity: 1;"></rect>
                                                      <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                                      <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                                  </svg>
                                              </span>
                                              <!--fin:Svg Icon-->
                                              <!--inicio:Mensaje-->
                                              <div class="d-flex flex-stack flex-grow-1">
                                                  <div style="font-weight: 100;">
                                                      <p style="font-weight: 100;" class="mb-0 text-center text-md-left">¿Tienes algún amigo miembro del <span style="font-weight: 500;">CLUB</span>?</p>
                                                      <p style="font-weight: 100;" class="mb-0 text-center text-md-left">Pídele su código amigo que encontrará en su perfil y los dos ganaréis <span style="font-weight: 500;">20 estrellas</span>.</p>
                                                  </div>
                                              </div>
                                              <!--fin:Mensaje-->
                                          </div>


                                            <!-- Button with google pixel -->
                                            <div class="form-group" style="text-align: center">
                                                <button type="submit" gtagEvent trackOn="click" (click)="this.sendRegister()" class="btn btn-register" [disabled]="this.buttonSendDisabled">Registrar</button>
                                            </div>

                                            <div class="form-check" style="text-align: left">
                                                <input class="form-check-input" type="checkbox" name="legal" [(ngModel)]="this.checkLegal" (click)="this.saveDataObject()" id="legal">
                                                <label class="form-check-label textconditions" for="defaultCheck1">
                                                    He leído y acepto las <a class="linkList" (click)="this.goToCCGG()">condiciones generales de adhesión a NAOS CLUB</a> y su <a class="linkList" (click)="this.goToPrivacity()" target="_blank">política de privacidad</a>.
                                                        </label>
                                            </div>
                                            <div class="form-check" style="text-align: left">
                                              <input class="form-check-input" type="checkbox" name="coms" [(ngModel)]="this.coms" (click)="this.saveDataObject()" id="coms">
                                              <label class="form-check-label textconditions" for="defaultCheck2">
                                                Sí, deseo recibir comunicaciones comerciales de NAOS por sms, correo electrónico y otros medios de comunicación electrónica equivalentes sobre sus productos, marcas, eventos, novedades y promociones.
                                              </label>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<!-- <app-naosnotification [msgModal]="this.textModal" [textUrl]="this.textUrl" [link]="true"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationRegister" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 filaLogo">
                        <img src="../../../assets/images/LOGO-NaosClub.png" />
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12 filaLogo2" id="contenidodiv">
                        {{ this.textModal }}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalNotificationRegisterRepeatEmail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-body">
              <div class="row">
                  <div class="col-md-12 filaLogo">
                      <img src="../../../assets/images/LOGO-NaosClub.png" />
                  </div>
              </div><br>
              <div class="row">
                  <div class="col-md-12 filaLogo2" id="contenidodiv">
                    No ha sido posible realizar el registro. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub&#64;es.naos.com.<br>
                    Recuerda que si ya te has registrado previamente debes Iniciar Sesión para acceder a NAOS Club.
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
          </div>
      </div>
  </div>
</div>

<script>
    $(document).ready(function() {
        $("[data-toggle='tooltip']").tooltip();
    });


</script>
