<div class="container mw-100">
    <!-- Spinner Icon reload-->
    <div class="row loader" *ngIf="!this.loader">
        <div class="col-md-12  text-center">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>

    <div class="row" *ngIf="this.loader">
        <div class="col-md-12">
            <div class="row mt-5">
                <div class="col-md-6">
                    <div class="form-group row justify-content-center">
                        <label class="profile-user-grey-text smaller">Consigue 20* al completar Tu Perfil.</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <ng-container *ngIf="this.infoAdvServePrivateMGM !== null">
                            <div class="row">
                                <div class="row col-sm-12 ml-3">
                                    <div *ngIf="this.infoAdvServePrivateMGM.offers[0].accomplishment_quantity < this.infoAdvServePrivateMGM.offers[0].max_accomplishment_quantity"
                                        class="col-lg-8 col-md-11 col-11 p-0 inputfriendscode tooltip-primary cursor-hand" (click)="this.copyMessage(this.infoClient.public_code)" ngbTooltip="Copiado"
                                        [autoClose]="false" triggers="manual" #t="ngbTooltip" (click)="t.open()" (mouseleave)="t.close()">
                                        Código amig&#64;: {{this.infoClient.public_code}}
                                        <i class="far fa-copy"></i>
                                    </div>
                                    <div *ngIf="this.infoAdvServePrivateMGM.offers[0].accomplishment_quantity >= this.infoAdvServePrivateMGM.offers[0].max_accomplishment_quantity"
                                        class="inputfriendscodecomplete tooltip-primary" ngbTooltip="Copiado" [autoClose]="false" triggers="manual" #t="ngbTooltip">
                                        Código amig&#64;: {{this.infoClient.public_code}}
                                    </div>
                                    <div class="col-1 p-0">
                                        <i [placement]="['right', 'bottom', 'auto']"
                                        ngbPopover="Comparte este código con tus amig@s. Cuando usen tu código al registrarse en NAOS Club, conseguirás 20* por amig@. Máximo hasta 10 amig@s (200* = 10€) ¡Viva la amistad!"
                                        class="fas fa-info-circle iconInfoFriendCode"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="row progressFriend withoutMargin mt-2 ml-1">
                                <div class="col-sm-6 mt-1 lineCountFriendCode">
                                    <div class="progress">
                                        <div class="progress-bar bg-info" role="progressbar" [ngStyle]="{width: this.infoAdvServePrivateMGM.offers[0].accomplishment_quantity * 10 + '%'}" aria-valuenow="{{this.infoAdvServePrivateMGM.offers[0].accomplishment_quantity}}" aria-valuemin="0" aria-valuemax="{{this.infoAdvServePrivateMGM.offers[0].max_accomplishment_quantity}}"></div>
                                    </div>
                                </div>
                                <div class="col-sm-6 lineCountFriendCode">
                                    <span class="countFriendsCode">{{this.infoAdvServePrivateMGM.offers[0].accomplishment_quantity}}/{{this.infoAdvServePrivateMGM.offers[0].max_accomplishment_quantity}} amig&#64;s registrados</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="row">
                        <label for="name" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 col-form-label">Nombre*</label>
                        <input type="text" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 inputRegister" [(ngModel)]="this.infoClient.firstname" id="name">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label for="dateBirth" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 col-form-label">Fecha de nacimiento</label>
                        <input type="date" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 inputRegister" [min]="this.minDateInput" [max]="this.maxDateInput" [(ngModel)]="this.infoClient.datebirth" id="dateBirth">
                    </div>
                </div>
            </div>
            <div class="row mt-lg-4">
                <div class="col-md-6">
                    <div class="row">
                        <label for="surname" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 col-form-label">Apellidos</label>
                        <input type="text" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 inputRegister" [(ngModel)]="this.infoClient.surname" id="surname">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="sexo" class="col-lg-3 col-md-3 col-xs-12 col-form-label p-4">Sexo</label>
                        <div class="col-sm-3">
                            <button [ngClass]="{'btnSexSelected' : this.infoClient.sex == 'M'}" class="btn btnSex" (click)="this.setSexClient('M')" id="btnFemale">
                                Mujer<br>
                                <i class="fa fa-venus" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="col-sm-3">
                            <button [ngClass]="{'btnSexSelected' : this.infoClient.sex == 'H'}" class="btn btnSex" (click)="this.setSexClient('H')" id="btnMale">
                                Hombre<br>
                                <i class="fa fa-mars" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="col-sm-3">
                            <button [ngClass]="{'btnSexSelected' : this.infoClient.sex == 'O'}" class="btn btnSex" (click)="this.setSexClient('O')" id="btnOther">
                                Otro<br>
                                <i class="fa fa-transgender" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="row">
                        <label for="telf" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 col-form-label">Teléfono móvil</label>
                        <input type="text" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 inputRegister" [(ngModel)]="this.infoClient.contact_telephone_sd" id="telf">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label for="email" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 col-form-label">Email*</label>
                        <input type="email" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 inputRegister" [(ngModel)]="this.infoClient.email" id="email" disabled>
                    </div>
                </div>
            </div>
            <div class="row mt-4 ml-1">
                <div class="col-lg-6 mb-2 order-1 order-lg-1">
                    <i *ngIf="this.infoClient.whereid_1 == ''" class="col-sm-0 fa fa-map-marker iconoSitio" aria-hidden="true"></i>
                    <label *ngIf="this.infoClient.whereid_1 == ''" class="col-sm-6 ml-2 profile-basic-text">Provincia farmacia favorita<i *ngIf="this.infoClient.whereid_1 == ''" [placement]="['right', 'bottom', 'auto']"
                      ngbPopover="La provincia de la farmacia origen es el territorio donde se localiza la farmacia donde obtendrás el doble de estrellas por tus compras. En caso que sea una farmacia online, por favor selecciona el valor 'Farmacia online'"
                      class="fas fa-info-circle iconInfo"></i></label>

                    <div class="form-group" *ngIf="this.infoClient.whereid_1 == ''">
                        <select class="inputRegister form-control cursor-pointer input-select-naos" (change)="this.selectSitesPharmacy()" [(ngModel)]="this.valueProvinceImportantPharmacy" id="exampleFormControlSelect1">
                            <option value=""> </option>
                            <option value="XX">FARMACIA ONLINE</option>
                          <option *ngFor="let province of this.listProvinces" [value]="province.cdprovincia">{{province.dcprovincia}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-6 mb-2 order-3 order-lg-2">
                    <i class="col-sm-0 fa fa-map-marker iconoSitio" *ngIf="this.infoClient.whereid_2 == ''" aria-hidden="true"></i>
                    <label *ngIf="this.infoClient.whereid_2 == ''" class="col-sm-6 ml-2 profile-basic-text">Provincia centro de estética favorito<i *ngIf="this.infoClient.whereid_2 == ''" [placement]="['right', 'bottom', 'auto']"
                      ngbPopover="La provincia del centro de estética origen es el territorio donde se localiza el centro de estética donde obtendrás el doble de estrellas por tus compras. En caso que sea un centro de estética online, por favor selecciona el valor 'Centro de estética online'"
                      class="fas fa-info-circle iconInfo"></i></label>
                    <div class="form-group">
                        <select *ngIf="this.infoClient.whereid_2 == ''" class="inputRegister form-control cursor-pointer input-select-naos" (change)="this.selectSitesEstetica()" [(ngModel)]="this.valueProvinceImportantEstetica" id="exampleFormControlSelect1">
                                <option value=""> </option>
                                <option value="XX">CENTRO DE ESTÉTICA ONLINE</option>
                            <option *ngFor="let provincee of this.listProvinces" [value]="provincee.cdprovincia">{{provincee.dcprovincia}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-6 mb-2 order-2 order-lg-3">
                    <i class="col-sm-0 fa fa-map-marker iconoSitio" aria-hidden="true"></i>
                    <label class="col-sm-6 profile-basic-text">Farmacia favorita <i [placement]="['right', 'bottom', 'auto']" ngbPopover="Campo Opcional. En tu farmacia origen obtendrás el doble de estrellas por tus compras. Una vez seleccionada una farmacia origen no se puede modificar, salvo situación excepcional. Para ello puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com" class="fas fa-info-circle iconInfo"></i></label>
                    <img *ngIf="this.loaderFarmcia" class="loaderSite" src="../../../assets/images/loader.gif" />

                    <div class="form-group" *ngIf="this.infoClient.whereid_1 == ''">
                        <div class="dropdown" *ngIf="!this.showPharmacyInput">
                            <div id="myDropdownPharmacy" class="dropdown-content">
                                <div class="cerrarInputSearchDiv">
                                    <a (click)="this.closedInputSearchPharmacy()" class="cerrarInputSearch">Cerrar</a>
                                </div>
                                <input type="text" placeholder="Buscar.." id="myInputPharmacy" (keyup)="this.filterFunctionPharmacy()" [(ngModel)]="this.stringNamePharmacy">
                                <a *ngFor="let itempharmacy of this.listadoSitiosPharmacy" (click)="this.setSelectedSitePharmacy(itempharmacy.where_id, itempharmacy.fullName)">{{itempharmacy.fullName}}</a>
                            </div>
                        </div>
                        <div *ngIf="this.showPharmacyInput">
                            <input type="text" [(ngModel)]="this.stringNamePharmacy" class="form-control" disabled>
                        </div>
                    </div>
                    <input *ngIf="this.infoClient.whereid_1 !== ''" type="text" class="form-control col-sm-12" [value]="this.stringNamePharmacy" id="farmaciaOrigen" disabled>
                </div>
                <div class="col-lg-6 mb-2 order-4 order-lg-4">
                    <i class="col-sm-0 fa fa-map-marker iconoSitio" aria-hidden="true"></i>
                    <label class="col-sm-6 profile-basic-text">Centro de estética favorito<i [placement]="['right', 'bottom', 'auto']"
                      ngbPopover="Campo Opcional. En tu centro de estética origen obtendrás el doble de estrellas por tus compras. Una vez seleccionado un centro de estética origen no se puede modificar, salvo situación excepcional. Para ello puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com"
                      class="fas fa-info-circle iconInfo"></i></label>
                    <img *ngIf="this.loaderEstetica" class="loaderSite" src="../../../assets/images/loader.gif" />

                    <div class="form-group" *ngIf="this.infoClient.whereid_2 == ''">
                        <div class="dropdown" *ngIf="!this.showEsteticaInput">
                            <div id="myDropdownEstetica" class="dropdown-content">
                                <div class="cerrarInputSearchDiv">
                                    <a (click)="this.closedInputSearchEstetica()" class="cerrarInputSearch">Cerrar</a>
                                </div>
                                <input type="text" placeholder="Buscar.." id="myInputEstetica" (keyup)="this.filterFunctionEstetica()" [(ngModel)]="this.stringNameEstetica">
                                <a *ngFor="let itemestetica of this.listadoSitiosEstetica" (click)="this.setSelectedSiteEstetica(itemestetica.where_id, itemestetica.fullName)">{{itemestetica.fullName}}</a>
                            </div>
                        </div>
                        <div *ngIf="this.showEsteticaInput">
                            <input type="text" [(ngModel)]="this.stringNameEstetica" class="form-control" disabled>
                        </div>
                    </div>
                    <input *ngIf="this.infoClient.whereid_2 !== ''" type="text" class="form-control" [value]="this.stringNameEstetica" id="esteticaOrigen" disabled>
                </div>
            </div>
            <div class="row mt-4" *ngIf="this.infoClient.employee_identifier == ''">
                <div class="col-md-6">
                </div>
                <div class="col-md-6">
                </div>
            </div>
            <div class="row mt-4" *ngIf="this.infoClient.employee_identifier !== ''">
              <div class="col-md-6">
                  <div class="form-group">
                      <label for="name" class="col-sm-4 col-form-label">Código empleado</label>
                      <input type="text" class="col-sm-8 inputRegister" [(ngModel)]="this.nombreEnroladorCompleto" id="codigoEnrolador" disabled>
                  </div>
              </div>
              <div class="col-md-6">
              </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="row">
                        <label for="numson" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 col-form-label">Número de hijos</label>
                        <input type="number" class="col-lg-6 col-md-12 col-xs-12 col-sm-12 inputRegister" max=4 min=0 (keyup)="this.setNumSon(this.numson)" 
                            (change)="this.setNumSon(this.numson)" [(ngModel)]="this.numson" id="numson">
                    </div>
                </div>
                <div class="col-md-6">
                    <div *ngFor="let item of this.arraySonsDate;let i = index">
                        <div class="form-group">
                            <label [for]="'fechaNacimientoHijo' + this.arraySonsDate[i].hijo" class="col-sm-4 col-form-label">
                                Fecha de nacimiento hijo {{ this.arraySonsDate[i].hijo }}
                            </label>
                            <input type="date" [min]="this.minDateInput" [max]="this.currentDateInput" class="col-sm-8 inputRegister" [(ngModel)]="this.arraySonsDate[i].date" [id]="'fechaNacimientoHijo' + i">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                        <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="legal" [(ngModel)]="this.infoClient.legal" id="legal" disabled>
                                <label class="form-check-label textconditions" for="legal">
                                    He leído y acepto <a class="linkList" (click)="this.goToCCGG()">condiciones generales de adhesión</a> a NAOS club y su <a class="linkList" (click)="this.goToPrivacity()">política de privacidad</a>.
                                </label>
                            </div>
                </div>
                <div class="col-md-6">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="this.checkComsClient()" name="coms" [(ngModel)]="this.infoClient.coms" id="coms">
                        <label class="form-check-label textconditions" for="coms">
                            Sí, deseo recibir comunicaciones comerciales de NAOS por sms, correo electrónico y otros medios de comunicación electrónica equivalentes sobre sus productos, marcas, eventos, novedades y promociones.
                        </label>
                    </div>
                </div>
            </div>
            <div class="row rowButton justify-content-center mt-5">
                <div class="col-md-3 col-7 col-sm-4 col-lg-2">
                    <button class="btn btnEnviarForms w-100" (click)="this.updateDataClient()">
                        Enviar
                    </button>
                </div>
            </div>
            <div class="row mt-2 text-end">
                <div class="col-12 mb-2 ml-auto p-2 mb-3">
                        <a class="profile-deleted-account-text cursor-pointer" data-bs-toggle="modal" data-bs-target="#bajaModal">Borrar cuenta</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="confirmaFarmaciaModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 2060">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-body">
              <ng-container *ngIf="this.loaderFarmcia">
                  <div class="row loader">
                      <div class="col-md-12">
                          <img src="../../../assets/images/loader.gif" />
                      </div>
                  </div>
              </ng-container>
              <ng-container *ngIf="!this.loaderFarmcia">
                  <div class="row">
                      <div class="col-md-12">
                          <p>Vas a informar tu farmacia origen, en la que obtendrás el doble de estrellas por tus compras. Recuerda que, salvo situación excepcional, este dato no se puede modificar.</p>
                          <br>
                      </div>
                  </div>
              </ng-container>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary btnNaosProductModal" (click)="this.closedModalConfirm()">Cerrar</button>
              <button type="button" class="btn btnEnviarForms" (click)="this.setCenterSiteFarmacia(this.ppvvFarmacia)">Confirmar</button>
          </div>
      </div>
  </div>
</div>

<div class="modal fade" id="confirmaEsteticaModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-body">
              <ng-container *ngIf="!this.loaderEstetica">
                  <div class="row">
                      <div class="col-md-12">
                          <p>Vas a informar tu centro de estética origen, en el que obtendrás el doble de estrellas por tus compras. Recuerda que, salvo situación excepcional, este dato no se puede modificar.
                          </p><br>
                      </div>
                  </div>
              </ng-container>
              <ng-container *ngIf="this.loaderEstetica">
                  <div class="row loader">
                      <div class="col-md-12">
                          <img src="../../../assets/images/loader.gif" />
                      </div>
                  </div>
              </ng-container>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary btnNaosProductModal" (click)="this.closedModalConfirm()">Cerrar</button>
              <button type="button" class="btn btnEnviarForms" (click)="this.setCenterSiteEstetica(this.ppvvEstetica)">Confirmar</button>
          </div>
      </div>
  </div>
</div>

<!-- <app-naosnotification [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationDataClient" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 filaLogo">
                <img src="../../../assets/images/LOGO-NaosClub.png" />
            </div>
          </div><br>
          <div class="row">
              <div class="col-md-12 filaLogo2" id="contenidodiv" autofocus>
                {{ this.textModal }}
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
</div>
