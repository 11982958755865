<div class="row" *ngIf="this.isLogin">
  <div class="col-12">
      <app-navbar></app-navbar>
  </div>
</div>

<div class="container" *ngIf="!this.loader">
    <div class="row loaderMap">
        <div class="col-md-12">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
</div>

<div class="container mw-75 w-75 px-0 mt-5" *ngIf="this.loader">

    <div class="row text-center logomapa" *ngIf="!this.isLogin">
      <div class="col-12">
        <img (click)="this.goToAfter()" class="w-50 h-auto cursorPointer" src="https://lsp-images.s3.eu-west-1.amazonaws.com/f3da8c048aa2e1c3ddc2da150cec8768b960a0d7/pre/webcontent/logo_naosclub.png"/>
      </div>
    </div>

    <div class="row">
        <div class="col-md-12 px-0 mb-4">
            <h2 class="titleMap">Selecciona el lugar que buscas:</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-8 col-md-12 col-xs-12 col-sm-12 px-0">
            <div class="form-group row">
                <label for="inputDirection" class="col-sm-2 col-form-label titleAdress">Dirección </label>
                <div class="col-sm-10">
                    <input type="text" (keyup.enter)="this.setDirectionMap()" [(ngModel)]="this.direction" class="form-control" id="inputDirection" placeholder="Dirección, Localidad">
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-6 col-xs-6 col-sm-6 px-0">
            <button class="btn btnNaosProduct" (click)="this.setDirectionMap()">
                Buscar
            </button>
        </div>
        <div class="col-lg-2 col-md-6 col-xs-6 col-sm-6 px-0">
            <button class="btn btnNaosProductMiLocation" (click)="this.getCoordsCurrentPositionButton()" title="Posición actual">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="row" *ngIf="this.directionClient.length > 1">
        <div class="col-md-12 px-0" >
            <p class="titleMap">SELECCIONA UN RESULTADO PARA REFRESCAR EL MAPA:</p>
        </div>
    </div>
    <ng-container *ngIf="this.directionClient.length > 1">
        <div class="row rowDirectionClient alert-info" *ngFor="let item of this.directionClient;let i = index" (click)="this.selectDirectionClient(item, i)" [id]="'directionClient'+i">
            <div class="col-md-12 px-0">
                {{ item.formatted_address }}
            </div>
        </div>
    </ng-container>

    <div class="row" *ngIf="!this.directionClientNotFound">
        <div class="col-md-12 px-0">
            <div class="alert alert-info">
                No hemos encontrado la dirección que has introducido, por favor, introduce una dirección válida. Ej: Calle Gran Via, 9
            </div>
        </div>
    </div>

    <!-- <div class="row rowlegend mb-4" style="text-align: left;">
        <div class="col-lg-6 col-md-12 col-xs-12 mt-3">
          <div class="row rowresponsivelegend">
            <div class="col-lg-1 col-md-12 col-xs-12"><img class="iconlegend" src="../../../assets/images/Group.png"/></div>
            <div class="col-lg-11 col-md-12 col-xs-12">Punto de venta para comprar productos NAOS, canjear tu QR y recoger tu premio NAOS Club</div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-xs-12 mt-3">
          <div class="row rowresponsivelegend">
            <div class="col-lg-1 col-md-12 col-xs-12"><i class="fas fa-map-marker-alt iconlocation"></i></div>
            <div class="col-lg-8 col-md-12 col-xs-12">Punto de venta para comprar productos NAOS</div>
          </div>
        </div>
    </div>
    <br> -->

    <div class="row rowMap">
        <div class="col-md-12 mapaOpen px-0">

            <google-map [center]="this.center" [zoom]="this.zoom" height="600px" width="100%">
                <ng-container *ngIf="this.sitesNear.length > 0">
                    <ng-container *ngFor="let item of this.sitesNear">
                        <ng-container *ngIf="item.jwhere.allowExchanges">
                            <map-marker [position]="item.pointMap" [icon]="this.markericonNaos" (mapClick)="openMarkerInMap(marker, item)" #marker="mapMarker">
                                <map-info-window [disableAutoPan]="false" [isOpen]="item?.isOpen" #infoWindow>
                                    <div class="container containerInfoTooltiop mw-100 w-100" *ngIf="this.infoWindow !== undefined">
                                            <div class="row" style="text-align: center">
                                                    <div class="col-md-12">
                                                        <img class="tooltipMarkerLogo" src="../../../assets/images/LOGO-NaosClub@1x.png"/>
                                                    </div>
                                                </div><br>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        {{this.selectedInfo?.jwhere.where_ld}}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <b>{{this.selectedInfo?.where_address[0].jwhereAddress.street_address_type}} {{this.selectedInfo?.where_address[0].jwhereAddress.street_address_vld}} {{this.selectedInfo?.where_address[0].jwhereAddress.number}}, {{this.selectedInfo?.where_address[0].jwhereAddress.postal_cd}}, {{this.checkLocalityProvince(this.selectedInfo?.where_address[0].jwhereAddress.municipality,item.where_address[0].jwhereAddress.province)}}</b>
                                                    </div>
                                                </div><br>
                                    </div>
                                </map-info-window>
                            </map-marker>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="this.sitesNear.length == 0">
                    <map-marker [position]="this.center"></map-marker>
                </ng-container>
            </google-map>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-12 px-0 titleMap mb-4">
            <h4>Puntos de venta cercanos:</h4>
        </div>
    </div>
    <div class="row loaderMap" *ngIf="this.loaderSitesNear">
        <div class="col-md-12 px-0">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
    <div *ngIf="this.hasSearch">
        <div class="row" *ngIf="this.sitesNear.length == 0 && !this.loaderSitesNear">
            <div class="col-md-12 px-0">
                <div class="alert alert-info">
                    Lo sentimos, no tenemos puntos de venta cercanos a la dirección indicada.
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="this.sitesNear.length > 0 && !this.loaderSitesNear">
        <ng-container *ngFor="let item of this.sitesNear">
            <div class="row rowDirectionNear" *ngIf="item.jwhere.allowExchanges">
                <div class="col-md-9" *ngIf="item.jwhere.allowExchanges">
                    <span class="iconDirection">
                        <img class="iconlegend" src="../../../assets/images/Group.png"/>
                    </span>&nbsp; {{item.jwhere.where_ld}}, {{item.where_address[0].jwhereAddress.street_address_type}} {{item.where_address[0].jwhereAddress.street_address_vld}} {{item.where_address[0].jwhereAddress.number}}, {{item.where_address[0].jwhereAddress.postal_cd}}, {{this.checkLocalityProvince(item.where_address[0].jwhereAddress.address_locality_vld,item.where_address[0].jwhereAddress.province)}}.
                </div>
                <!-- <div class="col-md-9" *ngIf="!item.jwhere.allowExchanges">
                    <span class="iconDirection">
                        <i class="fa fa-map-marker iconlocation" aria-hidden="true"></i>
                    </span>&nbsp; {{item.jwhere.where_ld}}, {{item.where_address[0].jwhereAddress.street_address_type}} {{item.where_address[0].jwhereAddress.street_address_vld}} {{item.where_address[0].jwhereAddress.number}}, {{item.where_address[0].jwhereAddress.postal_cd}}, {{this.checkLocalityProvince(item.where_address[0].jwhereAddress.address_locality_vld,item.where_address[0].jwhereAddress.province)}}.
                </div>
                <div class="col-md-3"></div> -->
            </div>
        </ng-container>
    </ng-container>
    <br>
    <br>
</div>

<div class="row" *ngIf="this.isLogin">
    <div class="col-12">
        <app-footer></app-footer>
    </div>
</div>

<!-- <app-naosnotification [textUrl]="this.textUrl" [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationMap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
    <div class="modal-body">
        <div class="row">
        <div class="col-md-12 filaLogo">
            <img src="../../../assets/images/LOGO-NaosClub.png" />
        </div>
        </div><br>
        <div class="row">
            <div class="col-md-12 filaLogo2" id="contenidodiv">
            {{ this.textModal }}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
    </div>
    </div>
</div>
</div>
