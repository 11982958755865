import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';
declare var $: any;

@Component({
    selector: 'app-naos-leavecomers',
    templateUrl: './leavecomers.component.html',
    styleUrls: ['./leavecomers.component.css'],
    providers: [UserService, NAOSService]
})
export class LeaveComersComponent implements OnInit {

    public idclient: any;
    public infoClient: any;
    public textModalLeave: string;
    public textUrl: string;
    public textModal: string;

    constructor(
        private _userService: UserService,
        private _router: Router,
        private _naosService: NAOSService
    ) {

    }

    ngOnInit(): void {
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        this.getDataClient();
    }

    public goToHome(): void {
        this._router.navigate(['/home']);
    }

    /**
     * Funcion para obtener los datos del cliente
     */
    public getDataClient(): void {
        this._userService.getDataClient(this.idclient).subscribe(
            response => {
                this.infoClient = response;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    public showPopupConfirmacion(): void {
        $('#exampleModalLeaveConfirm').modal('show');
    }

    public leaveComers(): void {
        this.infoClient.coms = false;
        this._userService.updateDataClient(this.idclient, this.infoClient).subscribe(
            () => {
                this.textUrl = '/home';
                this.textModal = 'Hemos recibido correctamente tu solicitud de baja de las comunicaciones comerciales de NAOS CLUB y la estamos tramitando para que dejes de recibir nuestras newsletters cuanto antes. Aún así, este proceso no es automático y puede que te llegue todavía alguna comunicación. Si es así, por favor, no la tengas en cuenta e ignórala. Recuerda que si quieres volver a recibir comunicaciones sobre las ventajas que te ofrece NAOS Club por vías electrónicas, podrás darte de alta otra vez dentro del apartado MI PERFIL de NAOS Club. ¡Hasta pronto!';
                $('#exampleModalNotificationLeave').modal('show');
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
                this.textUrl = null;
                this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                $('#exampleModalNotificationLeave').modal('show');
            }
        );
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }
}
