import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterEsthedermComponent } from './footer-esthederm.component';
import { NgbModule } 
from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [FooterEsthedermComponent],
  imports: [ CommonModule, NgbModule ],
  exports: [FooterEsthedermComponent],
  providers: [],
})
export class FooterEsthedermModule {}
