import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GeocoderResponse } from '../model/geocoder-response.model';
import { Observable, of, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

var google: any;

@Injectable({
  providedIn: 'root',
})
export class GeocodingService {

  private geocoder: any;


  constructor(private http: HttpClient) {}

  public geocodeLatLng(location: google.maps.LatLngLiteral): Promise<GeocoderResponse> {
    let geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ 'location': location }, (results, status) => {
        const response = new GeocoderResponse(status, results);
        resolve(response);
      });
    });
  }

  public getLocation(term: string): Observable<GeocoderResponse> {
    const url = `https://maps.google.com/maps/api/geocode/json?address=${term}&sensor=false&key=${environment.APIKEYGEOCODINGBIODERMA}`;
    return this.http.get<GeocoderResponse>(url);
  }





}