
import { Component, OnInit } from '@angular/core';
import { ExchangesService } from 'src/app/services/exchanges.service';
import { NAOSService } from 'src/app/services/naos.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
    selector: 'app-exchange',
    templateUrl: './exchange.component.html',
    styleUrls: ['./exchange.component.css'],
    providers: [ExchangesService, NAOSService, UserService]
})
export class ExchangeComponent implements OnInit {

    public idclient: any;

    public exchangesList: Array<any>;
    public exchangeDetail: any;

    public dateIni: any;
    public dateFin: any;

    public loader: boolean;
    public contLoaderNum: number;
    public numExchanges: number;

    public infoClient: any;
    public idexchangeCancel: any;

    public textModal: string;
    public textUrl: string;
    public buttonCancel: boolean;
    public loaderCancel: boolean;

    constructor(
        private _exchangesService: ExchangesService,
        private _naosService: NAOSService,
        private _userService: UserService,
        private _router: Router
    ) {
        this.buttonCancel = false;
        this.exchangesList = [];
        this.loader = false;
        this.exchangeDetail = {
            'name': '',
            'text': ''
        };
        this.contLoaderNum = 0;
        this.loaderCancel = false;

        /* this.dateIni = this._naosService.getYear() + '-01-01'; */
        this.dateIni = this._naosService.getThreeMonthLater();
        this.dateFin = this._naosService.getDateShortyyyyMMdd();
    }

    ngOnInit(): void {
        this.UpdateNavbar();

        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        this.getExchanges();

        let intervalo = setInterval(() => {
            if (this.contLoaderNum == 1) {
                this.loader = true;
                clearInterval(intervalo);
            }
        }, 500);
    }

    public openCloseColllapse(nameId: string): void {
        $('#'+nameId).on('click', function () {
            $('.collapse').collapse('hide');
        });
    }

    public openCloseColllapse2(nameId: string): void {
        $('#'+nameId).on('click', function () {
            $('.collapseProducts').collapse('hide');
        });
    }

    /**
     * Metodo para coger solamente las unidades de fidelización con id 7
     * @param arrayUnit array de unidades de fidelización del producto
     */
    public showLoyaltyUnitPrize(arrayUnit: Array<any>): number {
      let prize: number = 0;
      for (let item of arrayUnit) {
        if (item.loyalty_unit_id == 7) {
          prize = item.loyalty_unit_quantity;
        }
      }
      return prize;
    }

    /**
     * Update navbar when return from bag component
     */
    public UpdateNavbar(): void {
        $('#opt2').addClass('selected');
        $('#opt1').removeClass('selected');
        $('#opt3').removeClass('selected');

        $('#btniconbag').addClass('iconBag-disabled');
        $('#btniconbag').removeClass('iconBag');
    }

    /**
     * Inicializar objeto canjes
     */
    public initExchangeDetail(): void {
        this.exchangeDetail.name = '';
        this.exchangeDetail.text = '';
    }

    /**
     * Funcion para obtener el listado de canjes por cliente
     */
    public getExchanges(): void {
        this._exchangesService.getExchangesClient(this.idclient,  this.dateIni, this.dateFin).subscribe(
            response => {
                this.exchangesList = response;
                this.numExchanges = this.exchangesList.length;
                this.contLoaderNum++;
            },
            error => {
                this.contLoaderNum++;
                this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                this.textUrl = null;
                $('#exampleModalNotificationExchange').modal('show');
            }
        );
    }

    /**
     * Funcion para guardar la id del pedido que se quiere cancelar
     * @param id id del pedido que queremos cancelar
     */
    public setExchangeCancel(id: any): void {
        console.log('Entra exchangeCancel')
        this.idexchangeCancel = id;
        $('#modalCancelacion').modal('show')
    }

    // Funcion para formatear la fecha del pedido
    public formatDateExchange(date: string): string {
        let datestring: any = date.split(' ');
        return datestring[0][8] + datestring[0][9] + '/' + datestring[0][5] + datestring[0][6] + '/' + datestring[0][0] + datestring[0][1] + datestring[0][2] + datestring[0][3];
    }

    /**
     * Funcion para ir a la pagina de contacto
     */
    public goToContact(interactionId: any): void {
        this._router.navigate(['/home/store/contact', '1', interactionId]);
    }

    /**
     * Funcion para comprobar si el pedido tiene la opcion de cancelacion
     * @param index indice del pedido en el array
     */
    public comprobarStatusCancelOrder(index: any): boolean {
        let returnvalue: number = 0;
        for (let action of this.exchangesList[index].status.action) {
            if ((action == 'Cancel') && (this.exchangesList[index].status.message.id = '1')) {
                returnvalue++;
            }
        }
        if (returnvalue > 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Funcion para comprobar si el pedido tiene la opcion de comentario
     * @param index indice del pedido en el array
     */
    public comprobarStatusComment(index: any): boolean {
        let returnvalue: number = 0;
        for (let action of this.exchangesList[index].status.action) {
            if (action !== 'N/A') {
                returnvalue++;
            }
        }
        if (returnvalue > 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Funcion para comprobar si el pedido tiene la opcion de reenviar
     * @param index indice del pedido en el array
     */
    public comprobarStatusReenviarOrder(index: any): boolean {
        let returnvalue: number = 0;
        for (let action of this.exchangesList[index].status.action) {
            if (action == 'ResendDocs') {
                returnvalue++;
            }
        }
        if (returnvalue > 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Funcion para formatear el array de productos para poder mostrarlo
     * @param arrayDoble array que vamos a formatear
     */
    public formatArrayProductosPedido(arrayDoble: Array<any>): Array<any> {
        let arrayBien: Array<any> = [];
        for (let item of arrayDoble) {
            for (let item2 of item) {
                arrayBien.push(item2);
            }
        }
        return arrayBien;
    }

    /**
     * Funcion para cambiar el color del canje
     * @param estado estado del canje
     */
    public setColorStatus(estado: string): string {
        let color: string;
        switch (estado) {
            case 'Enviado':
                color = 'green';
                break;
            case 'Cancelado':
                color = 'red';
                break;
            case 'En tránsito':
                color = 'blue';
                break;
        }
        return color;
    }

    /**
     * Funcion para formatear el nombre del sitio del pedido
     * @param item nombre del sitio del pedido
     */
    public formatSiteExchange(item: any): string {
        if (item.where_ld == 'naos_where') {
            return 'ENVÍO SEUR';
        } else {
            return item;
        }
    }

    /**
     * Funcion para cancelar un canjeo
     * @param id del canje a cancelar
     */
    public cancelExchange(): void {
        this.buttonCancel = true;
        $('#modalCancelacion').modal('hide');
        this._exchangesService.cancelExchange(this.idclient, this.idexchangeCancel).subscribe(
            response => {
                this.buttonCancel = false;
                this.textModal = 'Tu pedido ha sido anulado.';
                this.textUrl = null;
                $('#exampleModalNotificationExchange').modal('show');
                this.getExchanges();
            },
            error => {
                this.buttonCancel = false;
                this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                this.textUrl = null;
                $('#exampleModalNotificationExchange').modal('show');
            }
        );
    }

    public refreshCancelData(): void {
      this._router.navigateByUrl('/refresh', {skipLocationChange: true}).then(()=> this._router.navigate(['/home/store/exchange']));
    }

    /**
     * Funcion para reenviar los datos del pedido
     * @param idinteraction id de la interaccion del pedido
     */
    public reSendDataExchange(idinteraction: any): void {
        let obj = {};
        this._userService.getDataClient(this.idclient).subscribe(
            response => {
                this.infoClient = response;
                this._exchangesService.resendOrderData(this.idclient, idinteraction, this.infoClient.email, obj).subscribe(
                    () => {
                        this.textModal = 'Te hemos vuelto a enviar un email con la información de tu pedido.';
                        this.textUrl = null;
                        $('#exampleModalNotificationExchange').modal('show');
                        this.getExchanges();
                    },
                    error => {
                        this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                        this.textUrl = null;
                        $('#exampleModalNotificationExchange').modal('show');
                    }
                );
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    /**
     * Funcion para buscar y cambiar la fecha de inicio del filtro
     * @param dateIni fecha inicio del filtro
     */
    public setFilterValuesIni(dateIni: any): void {
        this.loader = false;
        this.dateIni = dateIni;
        this._exchangesService.getExchangesClient(this.idclient, this.dateIni, this.dateFin).subscribe(
            response => {
                this.exchangesList = response;
                this.loader = true;
            },
            error => {
                this.loader = true;
                this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                this.textUrl = null;
                $('#exampleModalNotificationExchange').modal('show');
            }
        );
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }

    /**
     * Funcion para buscar y cambiar la fecha de fin del filtro
     * @param dateFin fecha fin del filtro
     */
    public setFilterValuesFin(dateFin: any): void {
        this.loader = false;
        this.dateFin = dateFin;
        this._exchangesService.getExchangesClient(this.idclient, this.dateIni, this.dateFin).subscribe(
            response => {
                this.exchangesList = response;
                this.loader = true;
            },
            error => {
                this.loader = true;
                this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                this.textUrl = null;
                $('#exampleModalNotificationExchange').modal('show');
            }
        );

    }
}
