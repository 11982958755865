import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';

@Injectable()
export class RegisterService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl:string;
    private url:string;

    constructor(private _http: HttpClient){
        this.url = environment.urlAPI;
    }

    /**
     * Peticion de registro usuario
     */
    public sendRegister(body: any): Observable<any>{
        this._apiUrl = this.url + AppSettings.REGISTER;
        return this._http.post( this._apiUrl, body);
    }

    /**
     * Funcion para validar el codigo amigo con LSP
     * @param code código para validar
     */
    public validateFriendsCode(code: string): Observable<any> {
        this._apiUrl = this.url + AppSettings.VALIDATEFRIENSCODE;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('publiccode', code);
        return this._http.get(this._apiUrl, {params});
    }
}


