import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';
import { UserService } from 'src/app/services/user.service';
declare var jQuery: any;
declare var $: any;


@Component({
    selector: 'app-naos-store',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.css'],
    providers: [UserService, NAOSService]
})
export class StoreComponent implements OnInit {

    public bag: Array<any>;
    public scoreSclient: string;
    public idclient: string;
    public invervaloRefreshStars: any;

    constructor(
        private _userService: UserService,
        private _router: Router,
        private _naosService: NAOSService
    ) {
        this.bag = [];
        this.scoreSclient = '0';
    }

    ngOnInit(): void {
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        setInterval(() => {
            if (localStorage.getItem('BC' + this.idclient)) {
                this.bag = JSON.parse(localStorage.getItem('BC' + this.idclient));
            }
        }, 500);
        this.initSubMenu();
        this.getScoreClient();

        this.invervaloRefreshStars = setInterval(() => {
            if (localStorage.getItem('refresh')) {
                this.getScoreClient();
                localStorage.removeItem('refresh');
            }
        }, 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this.invervaloRefreshStars);
    }

    public goToHome(): void {
        this._router.navigate(['/home']);
    }

    /**
     * Funcion para ir a la bolsa de compra
     */
    public goToBag(): void {
        $('#opt1').removeClass('selected');
        $('#opt2').removeClass('selected');
        this._router.navigate(['/home/store/bag']);
    }

    /**
     * Funcion para obtener las estrellas del cliente
     */
    public getScoreClient(): void {
        this._userService.getScoreClient(this.idclient).subscribe(
            response => {
                this.scoreSclient = response[0].available;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    /**
     * Función para inicializar la primera opción del menu al entrar
     */
    public initSubMenu(): void {
        $('#opt1').addClass('selected');
    }

    /**
     * Funcion que actua de submenu dentro de store
     * @param opt numero de opción sobre la que se ha echo clic
     */
    public clickOpt(opt: number): void {
        switch (opt) {
            case 1:
                $('#opt1').addClass('selected');
                $('#opt2').removeClass('selected');
                $('#opt3').removeClass('selected');

                $('#btniconbag').addClass('iconBag-disabled');
                $('#btniconbag').removeClass('iconBag');

                this._router.navigate(['/home/store/catalogo']);
                break;
            case 2:
                $('#opt2').addClass('selected');
                $('#opt1').removeClass('selected');
                $('#opt3').removeClass('selected');

                $('#btniconbag').addClass('iconBag-disabled');
                $('#btniconbag').removeClass('iconBag');

                this._router.navigate(['/home/store/exchange']);
                break;
            case 3:
                if(this.bag.length > 0){
                    $('#opt3').addClass('selected');
                    $('#opt1').removeClass('selected');
                    $('#opt2').removeClass('selected');

                    $('#btniconbag').addClass('iconBag');
                    $('#btniconbag').removeClass('iconBag-disabled');

                    this._router.navigate(['/home/store/bag']);
                }
                break;
        }
    }
}
