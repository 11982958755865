import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class MediktorService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;
    private urlMediktor: string;

    constructor(
        private _http: HttpClient,
        private sanitizer: DomSanitizer
    ) {
        this.urlMediktor = environment.MEDIKTORAPI;
        this.url = environment.urlAPI;
    }

    /**
     * Petición para obtener la confirmacion del email del cliente
     * @param idclient id del cliente
     */
    public authenticationMediktor(obj: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.AUTENTICATIONMEDIKTOR;
        return this._http.post(this._apiUrl, obj);
    }

    /**
     * Funcion para obtener el listado de test de Mediktor
     * @param page numero de pagina inicial que queremos cargar
     * @param numelements numero de elementos que vamos a mostrar
     * @param externUserId id del cliente
     * @param authToken token del cliente
     * @param deviceId UUID del cliente
     */
    public getListTest(idclient: any, page: number, numelements: number, externUserId: any, authToken: any, deviceId: any ): Observable<any> {
        let obj = {
            phaseList: [1],
            offset: page,
            count: numelements,
            deviceId: deviceId,
            deviceToken: authToken,
            externUserId: externUserId
        };
        this._apiUrl = this.url + 'external/' + idclient + '/skinTestList';

        return this._http.post(this._apiUrl, obj);
    }

    /**
     * Funcion para recibir los test de Tribba
     * @param type tipo de test
     * @param idclient id del cliente
     */
    public getListTestTribba(type: string, idclient: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.DATALISTTESTTRIBBA + type;
        let params: HttpParams = new HttpParams();
        params = params.append('memberId', idclient);
        return this._http.get(this._apiUrl, { params });
    }

}
