export const environment = {
  production: true,
  VALIDATIONEMAIL: true,
  urlAPI: 'https://api.loyaltysp.es/',
  API_KEY: 'baade2f3-e3e8-4e3b-ade2-f3e3e80e3b79',
  REGISTERRED: false,
  APIKEYMAPS : 'AIzaSyCETzxLCCqxtITw-3WlVVONtGZbNamZjgY',
  URLAWSBANNERS: 'https://lsp-images.s3.amazonaws.com/f3da8c048aa2e1c3ddc2da150cec8768b960a0d7/banners/',
  URLAWSBANNERSMOVIL: 'https://lsp-images.s3.amazonaws.com/f3da8c048aa2e1c3ddc2da150cec8768b960a0d7/pre/banners/movil/',
  /* APIKEYGEOCODINGBIODERMA: 'AIzaSyCC1yI635TNNhvpNp-vKpoUR7WjXoNdUlM', */
  APIKEYGEOCODINGBIODERMA: 'AIzaSyCETzxLCCqxtITw-3WlVVONtGZbNamZjgY',
  APIKEYVANADIS: 'AIzaSyAlXxn-L_T5W9RHH0c9uUjQxUSy8GerGzQ',
  APIKEYGEOCODINGESTHEDERM: 'AIzaSyAFWZQq3MdjpnhmISkQX0BtVspC6fT5mL8',
  MEDIKTORAPI: 'https://www.mediktor.com/backoffice/',
  MEDIKTORAPITEST: 'https://bioderma.mediktor.com',
  MEDIKTORVERTEST: 'https://bioderma.mediktor.com/cmd/sessionResume',
  ENTORNOGTM: 'https://www.bioderma.es/naosclub',
  ENTORNOCOOKIE: 'naosclub.bioderma.es',
  ENTORNOAPIKEY: 'https://naosclub.bioderma.es',
  ENTORNOPROJECT: 'prod',
  KEYCRIPT: '#foW#WJobMNYK692^MQkXqXQL#d2DLMw'
};
