import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MySkinComponent } from './myskin.component';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';
import { DatePipe } from '@angular/common';

@NgModule({
    declarations: [MySkinComponent],
    imports: [
        CommonModule,
        NaosNotificationModule
    ],
    exports: [MySkinComponent],
    providers: [DatePipe],
})
export class MySkinModule {}
