import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefreshComponent } from './refresh.component';

@NgModule({
  declarations: [RefreshComponent],
  imports: [ CommonModule ],
  exports: [RefreshComponent],
  providers: [],
})
export class RefreshModule {}
