import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterBiodermaComponent } from './footer-bioderma.component';

@NgModule({
  declarations: [FooterBiodermaComponent],
  imports: [ CommonModule ],
  exports: [FooterBiodermaComponent],
  providers: [],
})
export class FooterBiodermaModule {}
