import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
declare var tarteaucitron: any;
declare var ga: any;
declare var fbq: any;
declare var gtag: any;

declare global {
  interface Window {
    fbq: any;
    _fbq: any;
    tarteaucitronCustomText: any;
    gtag: any;
    dataLayer: any[];
    _gaq: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TarteaucitronService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = () => {
        console.log(`${src} loaded`);
        resolve();
      };
      script.onerror = (error: any) => {
        console.error(`Error loading ${src}:`, error);
        reject(error);
      };
      this.renderer.appendChild(document.body, script);
    });
  }

  initializeTarteaucitron(): void {
    // Sobreescribir el texto predeterminado de tarteaucitron
    window.tarteaucitronCustomText = {
      "tarteaucitronTitle": "XXXX",
      "alertBigClick": "",  // Cambia el título del Big Alert
      "alertBig": "NAOS deposita cookies propias y de terceros para optimizar tu experiencia de usuario, las funciones de las redes sociales y la pertinencia de la publicidad relacionada con tus preferencias en base a un perfil elaborado a partir de tus hábitos de navegación. Gestiona las cookies cuyo uso autorizas mediante nuestra herramienta de cookies, todo ello de conformidad con nuestra política de cookies.", // Cambia el texto del Big Alert
      "acceptAll": "Aceptar todo",
      "denyAll": "Rechazar todo",
      "personalize": "Personalizar"
    };
    // Configuración de Tarteaucitron
    tarteaucitron.init({
        "lang": 'es',
        "privacyUrl": '', /* Privacy policy url */
        "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
        "cookieName": "tartaucitron", /* Cookie name */
        "orientation": 'middle', /* Banner position (top - middle - bottom) */
        "showAlertBig": true,
        "showAlertSmall": false, /* Show the small banner on bottom right */
        "cookieslist": true, /* Show the cookie list */
        "adblocker": false, /* Show a Warning if an adblocker is detected */
        "AcceptAllCta": true, /* Show the accept all button when highPrivacy on */
        "highPrivacy": false, /* Disable auto consent */
        "handleBrowserDNTRequest": false, /* If Do Not Track == 1, accept all */
        "removeCredit": false, /* Remove credit link */
        "moreInfoLink": true, /* Show more info link */
        "useExternalCss": true,
        "cookiesLink": '', /* Cookies link */
        "personaliztionFeature": true,
        "customServiceConsents": [],
        "denyAllCta": true, /* Deny all CTA Display (true - false) */
        "denyAllCtaType": 'link' /* Deny all CTA Type (link - button) */
    });

    // Añadimos servicio para GTM de Google
    tarteaucitron.services.googletagmanager = {
      "key": "googletagmanager",
      "type": "api",
      "name": "Google Tag Manager",
      "uri": "https://policies.google.com/privacy",
      "needConsent": true,
      "cookies": ['_ga', '_ga_', '_gat', '__utma', '__utmb', '__utmc', '__utmt', '__utmz', '__gads', '_drt_', 'FLC', 'exchange_uid', 'id', 'fc', 'rrs', 'rds', 'rv', 'uid', 'UIDR', 'UID', 'clid', 'ipinfo', 'acs'],
      "js": function () {
        "use strict";
        if (tarteaucitron.user.googletagmanagerId === undefined) {
          return;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        tarteaucitron.addScript('https://www.googletagmanager.com/gtm.js?id=' + tarteaucitron.user.googletagmanagerId);
      }
    };

    // Añadimos Google Tag Manager al trabajo
    tarteaucitron.job = tarteaucitron.job || [];
    tarteaucitron.job.push('googletagmanager');

    //Añadimos el pixel de Meta
    tarteaucitron.services.facebookpixel = {
      "key": "facebookpixel",
      "type": "ads",
      "name": "Facebook Pixel",
      "uri": "https://www.facebook.com/policy.php",
      "needConsent": true,
      "cookies": ['datr', 'fr', 'reg_ext_ref', 'reg_fb_gate', 'reg_fb_ref', 'sb', 'wd', 'x-src', '_fbp'],
      "js": function () {
        "use strict";
        let n;
        if (window.fbq) return;
        n = window.fbq = function () { n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments) };
        if (!window._fbq) window._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        tarteaucitron.addScript('https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', tarteaucitron.user.facebookpixelId);
        fbq('track', 'PageView');

        if (typeof tarteaucitron.user.facebookpixelMore === 'function') {
          tarteaucitron.user.facebookpixelMore();
        }
      }
    };

    // Añadimos Pixel Meta al trabajo
    tarteaucitron.job = tarteaucitron.job || [];
    tarteaucitron.job.push('facebookpixel');


    // service local coockies
    tarteaucitron.services.remember = {
      "key": "remember",
      "type": "other",
      "name": "Remember session",
      "uri": "https://naosbiodermacfpro.loyaltysp.es",
      "needConsent": false,
      "cookies": ['remember'],
      "js": function () {
        "use strict";
        // When user allow cookie
      }
    };
    // Añadimos Cookie local al trabajo
    tarteaucitron.job = tarteaucitron.job || [];
    tarteaucitron.job.push('remember');

    tarteaucitron.services.rememberC = {
      "key": "rememberC",
      "type": "other",
      "name": "RememberC session",
      "uri": "https://naosbiodermacfpro.loyaltysp.es",
      "needConsent": false,
      "cookies": ['rememberC'],
      "js": function () {
        "use strict";
        // When user allow cookie
      }
    };
    // Añadimos Cookie local al trabajo
    tarteaucitron.job = tarteaucitron.job || [];
    tarteaucitron.job.push('rememberC');

    tarteaucitron.services.fontawesome = {
      "key": "fontawesome",
      "type": "api",
      "name": "FontAwesome",
      "uri": "https://fontawesome.com/",
      "needConsent": false,
      "cookies": ['__stripe_mid','__stripe_sid','__utma','__utmz','_ga','_ga_BPMS41FJD2',''],
      "js": function () {
        "use strict";
      }
    };
    // Añadimos Cookie local al trabajo
    tarteaucitron.job = tarteaucitron.job || [];
    tarteaucitron.job.push('fontawesome');
    
  }


  // Método para cargar el archivo de idioma
  loadLang(lang: string): Promise<void> {
    const langFile = 'assets/tarteaucitron/lang/tarteaucitron.es.js'; // Ruta a los archivos de idioma
    return this.loadScript(langFile);
  }

  
}