import { Component, OnInit } from '@angular/core';
import { NAOSService } from 'src/app/services/naos.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
    selector: 'app-dataothers-naos',
    templateUrl: './dataothers.component.html',
    styleUrls: ['./dataothers.component.css'],
    providers: [NAOSService, UserService]
})
export class DataOthersComponent implements OnInit {

    public loader: boolean;
    public contErrors: any;
    public idclient: any;
    public infoClient: any;
    public puntosVenta: Array<any>;
    public enroladores: Array<any>;
    public puntoventa1: any;
    public puntoventa2: any;
    public enrolador: string;
    public enrolador_code: boolean;
    public numson: number;
    public arraySonsDate: Array<any>;
    public ppvv: any;
    public idfarmacia: any;
    public idestetica: any;
    public idcodesite: any;

    public intervalRefreshData: any;
    public textModal: string;
    public textUrl: string;

    public nombreEnroladorCompleto: string;

    public pageSite: number;

    constructor(
        private _NAOSService: NAOSService,
        private _userService: UserService,
        private _router: Router
    ) {
        this.loader = false;
        this.puntosVenta = [];
        this.puntoventa1 = '';
        this.puntoventa2 = '';
        this.enrolador = '';
        this.enroladores = [];
        this.contErrors = 0;
        this.enrolador_code = false;
        this.numson = 0;
        this.arraySonsDate = [];

        this.pageSite = 0;
    }

    ngOnInit(): void {
        this.idclient = this._NAOSService.desencryptData(sessionStorage.getItem('ILCK'));
        this.getDataClient();
        this.intervalRefreshData = setInterval(() => {
            if (sessionStorage.getItem('refreshDataClient')) {
                this.getDataClient();
                sessionStorage.removeItem('refreshDataClient');
            }
        }, 500);
        let intervaloError = setInterval(() => {
            if (this.contErrors >= 1) {
                this.loader = true;
                clearInterval(intervaloError);
            }
        }, 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this.intervalRefreshData);
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }

    /**
     * Funcion para inicializar array de hijos
     */
    public initArraySons(): void {
        switch (this.infoClient.childNumber) {
            case '1':
                    this.arraySonsDate.push(
                        {
                            'hijo': 1,
                            'date': this.infoClient.datebirth_child1
                        }
                    );
                break;
            case '2':
                    this.arraySonsDate.push(
                        {
                            'hijo': 1,
                            'date': this.infoClient.datebirth_child1
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 2,
                            'date': this.infoClient.datebirth_child2
                        }
                    );
                break;
            case '3':
                    this.arraySonsDate.push(
                        {
                            'hijo': 1,
                            'date': this.infoClient.datebirth_child1
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 2,
                            'date': this.infoClient.datebirth_child2
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 3,
                            'date': this.infoClient.datebirth_child3
                        }
                    );
                break;
            case '4':
                    this.arraySonsDate.push(
                        {
                            'hijo': 1,
                            'date': this.infoClient.datebirth_child1
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 2,
                            'date': this.infoClient.datebirth_child2
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 3,
                            'date': this.infoClient.datebirth_child3
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 4,
                            'date': this.infoClient.datebirth_child4
                        }
                    );
                break;
        }
    }

    /**
     * Funcion para introducir en el array de hijos el numero de objetos como numero de hijos introducido
     * @param num numero de hijos a repetir en el array
     */
    public setNumSon(num: number): void {
        if (num <= 4) {
            let arrayAux: Array<any> = [];
            this.arraySonsDate = [];
            for (let x = 1; x <= num; x++) {
                let obj;
                if (x == 1 && this.infoClient.datebirth_child1 !== '') {
                    obj = {
                        'hijo': x,
                        'date': this.infoClient.datebirth_child1
                    };
                } else if (x == 1 && this.infoClient.datebirth_child1 == '') {
                    obj = {
                        'hijo': x,
                        'date': ''
                    };
                }
                if (x == 2 && this.infoClient.datebirth_child2 !== '') {
                    obj = {
                        'hijo': x,
                        'date': this.infoClient.datebirth_child2
                    };
                } else if (x == 2 && this.infoClient.datebirth_child2 == '') {
                    obj = {
                        'hijo': x,
                        'date': ''
                    };
                }
                if (x == 3 && this.infoClient.datebirth_child3 !== '') {
                    obj = {
                        'hijo': x,
                        'date': this.infoClient.datebirth_child3
                    };
                } else if (x == 3 && this.infoClient.datebirth_child3 == '') {
                    obj = {
                        'hijo': x,
                        'date': ''
                    };
                }
                if (x == 4 && this.infoClient.datebirth_child4 !== '') {
                    obj = {
                        'hijo': x,
                        'date': this.infoClient.datebirth_child4
                    };
                } else if (x == 4 && this.infoClient.datebirth_child4 == '') {
                    obj = {
                        'hijo': x,
                        'date': ''
                    };
                }
                arrayAux.push(obj);
            }
            this.arraySonsDate = arrayAux;
        } else {
            this.textModal = 'Solo puede introducir 4 hijos como máximo.';
            this.textUrl = null;
            $('#exampleModalNotificationDataOther').modal('show');
        }
    }

    /**
     * Funcion para enviar los datos del formulario de otros
     */
    public sendDataOtherClient(): void {
        this.loader = false;
        this.idfarmacia = this.infoClient.whereid_1;
        this.idestetica = this.infoClient.whereid_2;
        this.infoClient.childNumber = this.numson;
        this.infoClient.datebirth_child1 = (this.arraySonsDate[0] !== undefined) ? this.arraySonsDate[0].date : '';
        this.infoClient.datebirth_child2 = (this.arraySonsDate[1] !== undefined) ? this.arraySonsDate[1].date : '';
        this.infoClient.datebirth_child3 = (this.arraySonsDate[2] !== undefined) ? this.arraySonsDate[2].date : '';
        this.infoClient.datebirth_child4 = (this.arraySonsDate[3] !== undefined) ? this.arraySonsDate[3].date : '';
        this.infoClient.whereid_1 = this.idfarmacia;
        this.infoClient.whereid_2 = this.idestetica;
        this.infoClient.employee_identifier = this.enrolador;
        this._userService.updateDataClient(this.idclient, this.infoClient).subscribe(
            () => {
                this.textModal = 'Los cambios se han guardado correctamente. Muchas gracias por actualizar tus datos.';
                this.textUrl = null;
                $('#exampleModalNotificationDataOther').modal('show');
                this.arraySonsDate = [];
                this.getDataClient();
                this.loader = true;
            },
            error => {
                if (error.error.code == '409.24') {
                    this.textModal = 'No ha sido posible actualizar el email, el correo introducido ya está registrado con otro usuario.';
                    this.textUrl = null;
                    $('#exampleModalNotificationDataOther').modal('show');
                } else {
                    this.textModal = 'No ha sido posible realizar los cambios. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                    this.textUrl = null;
                    $('#exampleModalNotificationDataOther').modal('show');
                }
                this.loader = true;
            }
        );
    }

    /**
     * Funcion para obtener los datos del cliente
     */
    public getDataClient(): void {
        this._userService.getDataClient(this.idclient).subscribe(
            response => {
                this.infoClient = response;
                if (this.infoClient.employee_identifier !== '' && this.infoClient.employee_identifier !== undefined) {
                    this.enrolador_code = true;
                    this.enrolador = this.infoClient.employee_identifier;
                    if (this.infoClient.whereid_1 !== '') {
                        this.idcodesite = this.infoClient.whereid_1;
                    } else if (this.infoClient.whereid_2 !== '') {
                        this.idcodesite = this.infoClient.whereid_2;
                    }
                    this.idfarmacia = this.infoClient.whereid_1;
                    this.idestetica = this.infoClient.whereid_2;
                    this.getEnroladorExist(this.idcodesite);
                } else {
                    this.enrolador_code = false;
                }
                if (!sessionStorage.getItem('refreshDataClient')) {
                    this.initArraySons();
                }
                this.numson = parseInt(this.infoClient.childNumber);
                this.contErrors++;
            },
            error => {
                this.textModal = error.error.message;
                this.textUrl = null;
                $('#exampleModalNotificationDataOther').modal('show');
                this.contErrors++;
            }
        );
    }

    /**
     * Funcion para obtener el enrolador si ya existe
     * @param idppvv id del sitio que ya existe
     */
    public getEnroladorExist(idppvv): void {
        this._NAOSService.getEnroladres(idppvv).subscribe(
            response => {
                this.enroladores = response;
                for (let item of this.enroladores) {
                    if (item.identityVld == this.infoClient.employee_identifier) {
                        this.nombreEnroladorCompleto = item.identityVld;
                    }
                }
            },
            error => {
                console.error('Error al obtener los puntos de venta.');
                this.textModal = error.error.message;
                this.textUrl = null;
                $('#exampleModalNotificationDataOther').modal('show');
            }
        );
    }

}
