<div class="container">
    <div class="row rowMediktor" *ngIf="this.showIframe">
        <div class="col-md-12">
            <iframe #iframeMediktor id="iframeMediktor" class="iframeMediktor" [attr.src]="this.urlIframe"></iframe>
        </div>

    </div>
    <div class="row rowMediktor" *ngIf="!this.showIframe" style="text-align: center">
        <div class="col-md-12">
                <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalNotificationTestSkin" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 filaLogo">
                        <img src="../../../assets/images/LOGO-NaosClub.png" />
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12 filaLogo2" id="contenidodiv">
                        {{ this.textModal }}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="this.redirectPopupNotification()"
                    class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
            </div>
        </div>
    </div>
</div>
