import { Component, OnInit } from '@angular/core';
import { NAOSService } from 'src/app/services/naos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clubperks',
  templateUrl: './clubperks.component.html',
  styleUrls: ['./clubperks.component.css'],
  providers: [NAOSService]
})
export class ClubperksComponent implements OnInit {

  public offerFree: string;
  public showOffer: boolean;
  public loader: boolean;
  public idclient: string;

  public clubperksContentMenu: Array<any>;
  public arrOptionsMenu: Array<any>;

  constructor(
    private _naosService: NAOSService,
    public _router: Router
  ) {
    this.showOffer = false;
    this.loader = false;
    this.clubperksContentMenu = [];
    this.arrOptionsMenu = [];
  }

  ngOnInit(): void {
    if (localStorage.getItem('offerFree')) {
      this.offerFree = localStorage.getItem('offerFree');
      if (this.offerFree == 'true') {
          this.showOffer = true;
      }
      this.loader = true;
    } else {
      this.showOffer = false;
      this.loader = true;
    }

    this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
    this.getAdvserverPrivate();
  }

   /**
     * Funcion para obtener el contenido de advserver de la parte privada
     */
    public getAdvserverPrivate(): void {
      this._naosService.getAdvserverContentPrivate(this.idclient).subscribe(
        response => {
          sessionStorage.setItem('ADVP',this._naosService.encryptData(JSON.stringify(response)));
          this.clubperksContentMenu = this._naosService.getOfferAdvByNameContentPlus('Ventajas -Tarjeta', response);

          let arrayAux: Array<any> = [];
          for (let item of this.clubperksContentMenu) {
            let obj = {
              'name': item.content.item_ld.es,
              'path': item.content.clikLink.es,
              'urlimage': item.content.images.web.es[0].url,
              'order': item.content.locationPriority,
              'description': item.content.instructions.es.web,
              'buttontext': item.content.data.es.split('\"')[3]
            };
            arrayAux.push(obj);
          }
          this.arrOptionsMenu = arrayAux;
        },
        error => {
            console.error('Error ' + JSON.stringify(error));
        }
      );
  }

  public goToLinksCard(url: any): void {
    if (url.indexOf('https') > -1) {
      window.open(url, '_blank');
    } else {
      this._router.navigate([url]);
    }
  }

  public goToPage(page) {
    this._router.navigate([page]);
  }


}
