<div class="container w-100 mw-100">
    <div class="row loader" *ngIf="!this.loader">
        <div class="col-md-12">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>

    <div class="row justify-content-between" *ngIf="this.loader">
        <div class="col-lg-6 mb-3 mt-4 text-center" *ngIf="!this.loaderTicket">
            <!-- <h5 class="d-inline-block">Sube la foto de tu ticket</h5>
            <i [placement]="['right', 'bottom', 'auto']" ngbPopover="Asegúrate de que estás subiendo la foto en un formato válido y con un tamaño que no exceda los 10 MB. Formatos válidos: gif, png, jpg, pdf. Realiza la foto de tu ticket sobre una superficie plana y asegúrate de que el ticket no quede arrugado y que no aparezcan otros elementos en la imagen. Por favor, asegúrate de ocultar cualquier información sensible (nombre, medicamentos, etc.) que aparezca en el ticket." class="fas fa-info-circle iconInfo"></i>

            <br> -->

            <div class="upload-btn-wrapper w-90" *ngIf="!this.showPreview">
                <button class="btnuploadfile cursorHand">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <i class="fas fa-arrow-circle-up cursorHand"></i>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                        <p class="spacingline"><b class="titleExplication">¿Qué debe contener mi ticket para que sea válido?</b></p>
                        </div>
                    </div>
                    <ul class="titleExplicationPoints text-center">
                        <li>CIF del punto de venta</li>
                        <li>Nombre punto de venta</li>
                        <li>Número de ticket</li>
                        <li>Fecha de compra</li>
                        <li>Productos NAOS</li>
                        <li>Importe de los productos NAOS</li>
                    </ul>
                </button>
                <span class="cursorHand"><input class="inputImage cursorHand" #imageInput id="fileUpload" type="file" accept="image/png,image/jpeg,application/pdf"
                    name="myfile" (change)="this.processFile(imageInput)" /></span>
            </div>

            <div *ngIf="this.showPreview" class="contenedorImage">
                <img *ngIf="!this.isPdf" class="imagenPreview mw-100 h-auto mh-100" [src]="this.srcPhoto" />
                <i *ngIf="this.isPdf" class="fa fa-file-pdf-o iconPreview" aria-hidden="true"></i>
                <i (click)="this.deleteTicketPhoto()" class="fas fa-times-circle iconBorrar"></i>
            </div>

            <div class="text-center mt-3">
                <button class="btn btnSendTicket" (click)="this.subirTicketConfirm()">
                    Subir foto del ticket
                </button>
            </div>

            <div class="row rowpromotion">
                <div *ngFor="let bll of this.contentAdvServerBll" class="col-lg-6 col-md-6 col-xs-6">
                    <a target="_blank" [attr.href]="bll.content.clikLink.es" class="linkpromotion" [innerHTML]="bll.content.item_ld.es"></a>
                </div>
            </div>

        </div>
        <div class="col-lg-6 mb-3 mt-4 text-center" *ngIf="this.loaderTicket">
            <img src="../../../assets/images/loader.gif" />
        </div>

        <div class="col-lg-5 mt-4 mr-5 title-tickets">
            <h5 class="d-inline-block blue-text mb-3">Listado tickets de un cliente</h5>
            <i [placement]="['right', 'bottom', 'auto']"
                ngbPopover="Recuerda subir el ticket no más tarde de 30 días naturales después de la fecha de compra.  Una vez subido el ticket, si éste es válido, tendrás las estrellas en tu saldo en un plazo máximo de 2 días laborables."
                class="fas fa-info-circle iconInfo"></i>
            <br>

            <table class="table mt-2 mb-4" *ngIf="this.listTickets.length > 0 && !this.loaderListaTickets">
                <tbody>
                    <tr *ngFor="let item of this.listTickets" style="background-color: whitesmoke !important;">
                        <td style="background-color: whitesmoke !important;">{{ this.formatDateExchange(item.createTime)}}</td>
                        <td style="background-color: whitesmoke !important;">{{ this.formatStatusTicket(item) }}</td>
                    </tr>
                </tbody>
            </table>

            <nav aria-label="Page navigation example" *ngIf="this.listTickets.length > 0 && !this.loaderListaTickets">
                <ul class="pagination">
                    <li *ngFor="let item of this.arrayPages;let i = index" [ngClass]="(item.page)?'page-item linkActive':'page-item'"><a class="page-link" (click)="this.getTicketsUploaded(item.index, i)">{{ item.index + 1 }}</a></li>
                </ul>
            </nav>

            <div class="alert alert-info" *ngIf="this.listTickets.length == 0 && !this.loaderListaTickets">
                No ha subido ningún ticket aún.
            </div>

            <div class="text-center" *ngIf="this.loaderListaTickets">
                <img src="../../../assets/images/loader.gif" />
            </div>

        </div>
    </div>
</div>


<div class="modal fade" id="exampleModalTicketPhoto" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <img class="imagenPreviewTicket" [src]="this.urlImageTicket" />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btnCerrarModal" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

<div class="modal fade" id="exampleModalTicketPhotoConfirm" tabindex="-1" aria-labelledby="exampleModalTicketPhotoConfirm" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 filaLogo">
                    <img src="../../../assets/images/LOGO-NaosClub.png" />
                </div>
            </div><br>
            <div class="row" *ngIf="!this.loaderTicket">
                <div class="col-md-12">
                    NAOS registrará únicamente los productos BIODERMA e INSTITUT ESTHEDERM para asignar las Estrellas a tu compra.
                </div>
            </div>
            <div class="row loader" *ngIf="this.loaderTicket">
                <div class="col-md-12" style="margin-bottom: 10%">
                    <img src="../../../assets/images/loader.gif" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" *ngIf="!this.loaderTicket" (click)="this.sendTicketPhoto()" class="btn btnEnviarForms" data-bs-dismiss="modal">Entendido, gracias.</button>
          <button type="button" data-bs-dismiss="modal" class="btn btnEnviarForms">Cancelar</button>
        </div>
      </div>
    </div>
  </div>





<div class="modal fade" id="exampleModalTicketPhotoError406" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 filaLogo">
                        <img src="../../../assets/images/LOGO-NaosClub.png" />
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12">
                        Lo siento, has alcanzado el límite máximo de tickets permitidos para este mes. Actualmente, en NAOS Club solo se permiten tres tickets por mes. Por favor, espera hasta el próximo mes para subir más tickets. ¡Gracias por tu fidelidad!
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-bs-dismiss="modal" class="btn btnEnviarForms">Aceptar</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalNotificationBuy" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 filaLogo">
                            <img src="../../../assets/images/LOGO-NaosClub.png" />
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-12 filaLogo2" id="contenidodiv" style="text-align: left !important" *ngIf="this.textModalMultiline">
                            <p>{{ this.textModal }} <br> {{ this.textModal2 }} <br> {{ this.textModal3}} <br> {{ this.textModal4}}
                            </p>

                        </div>
                        <div class="col-md-12 filaLogo2" id="contenidodiv" *ngIf="!this.textModalMultiline">
                            {{ this.textModal }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
            </div>
        </div>
    </div>
</div>
