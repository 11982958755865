import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';

@Component({
  selector: 'app-privacitynew',
  templateUrl: './privacitynew.component.html',
  styleUrls: ['./privacitynew.component.css'],
  providers: [NAOSService]
})
export class PrivacityNewComponent implements OnInit {
  constructor(
    private _router: Router,
    private _naosService: NAOSService
  ) { }

  ngOnInit(): void {
  }

  public goToSecond(): void {
      this._router.navigate(['/privacitynewsecond']);
  }

  p

  /**
   * Funcion para volver atras
   */
  public goToAfter(): void {
      this._naosService.returnNavigationPageBack();
  }

}
