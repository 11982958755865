<!-- Loader icon spinner -->
<ng-container *ngIf="!this.loader">
    <div class="row loader">
        <div class="col-12 text-center">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
</ng-container>

<div *ngIf="this.loader" class="container mw-80 mt-5 w-mobile-100 mw-mobile-100 w-mobile-100 mw-mobile-100 text-center">

  <h2 class="text-center title-clubperks mb-5"> ¿Cómo conseguir estrellas?</h2>

  <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-center" *ngFor="let optMenu of this.arrOptionsMenu;let i = index">
      <div class="row text-center mb-4">
        <div class="card card-menu" style="padding-left: 0px !important;padding-right: 0px !important;">
          <img class="card-img-top card-img-menu" [src]="optMenu.urlimage" alt="IMAGEN">
          <div class="card-body d-flex flex-column">
            <label class="font-weight-bold title-card" [innerHTML]="optMenu.name"></label>
            <hr>
            <label class="pt-1 font-weight-bold text-body-card" [innerHTML]="optMenu.description"></label>
            <a (click)="this.goToLinksCard(optMenu.path)" class="btn btnlink mt-auto align-self-center">{{optMenu.buttontext}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <a class="title-clubperks cursor-pointer my-5" href="/home/exchangestars"> ¿Cómo canjear tus estrellas?</a> -->
</div>
