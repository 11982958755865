import { Component, OnInit } from '@angular/core';
import { MediktorService } from 'src/app/services/mediktor.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { v4 as uuidv4 } from 'uuid';
import { NAOSService } from 'src/app/services/naos.service';

@Component({
    selector: 'app-pruebatest',
    templateUrl: './pruebatest.component.html',
    styleUrls: ['./pruebatest.component.css'],
    providers: [MediktorService, UserService, NAOSService]
})
export class PruebaTestComponent implements OnInit {

    public uuidMediktorUSer: string;
    public idclient: any;
    public externUserId: any;
    public infoClient: any;
    public deviceId: any;
    public authToken: any;
    public urlIframe: any;
    public showIframe: boolean;

    constructor(
        private _mediktorService: MediktorService,
        private sanitizer: DomSanitizer,
        private _userService: UserService,
        private _naosService: NAOSService
    ) { }

    ngOnInit(): void {
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        if (localStorage.getItem('uuid')) {
            this.uuidMediktorUSer = localStorage.getItem('uuid');
        } else {
            this.uuidMediktorUSer = uuidv4();
        }
        this.getDataClient();
    }

    /**
     * Funcion para obtener los datos del cliente
     */
    public getDataClient(): void {
        this._userService.getDataClient(this.idclient).subscribe(
            response => {
                this.infoClient = response;
                this.doLoginMediktor();
            },
            error => {
                console.error('Error ' + error.error.message);
            }
        );
    }

    /**
     * Funcion para formatear el sexo del cliente
     * @param sex Sexo del cliente
     */
    public formatSex(sex: string): number {
        switch (sex) {
            case 'H':
                return 0;
            case 'M':
                return 1;
            case 'O':
                return 2;
        }
    }

    /**
     * Funcion para hacer login con Mediktor
     */
    public doLoginMediktor(): void {
        let obj = {
            'deviceId': this.uuidMediktorUSer,
            'externUser': {
                'partnerExternalId': this.idclient,
                'birthDate': this.infoClient.datebirth,
                'gender': this.formatSex(this.infoClient.sex),
                'newPassword': 'Naos0109',
                'username': this.uuidMediktorUSer,
            },
            'includeAuthToken': true
        };
        this._mediktorService.authenticationMediktor(obj).subscribe(
            response => {
                this.externUserId = response.externUser.externUserId;
                this.deviceId = this.uuidMediktorUSer;
                this.authToken = response.authToken;

                if (this.externUserId !== undefined && this.authToken !== undefined && this.deviceId !== undefined) {
                    this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl('https://bioderma.mediktor.com?externUserId=' + this.externUserId + '&deviceId=' + this.deviceId + '&authToken=' + this.authToken);
                    this.showIframe = true;
                } else {
                    this.showIframe = false;
                }

            },
            error => {
                console.error('Error' + JSON.stringify(error));
            }
        );
    }
}
