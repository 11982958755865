import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BagComponent } from './bag.component';
import { FormsModule } from '@angular/forms';
import { MapModule } from 'src/app/components/map/map.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';

@NgModule({
    declarations: [BagComponent],
    imports: [
        CommonModule,
        FormsModule,
        MapModule,
        NgbModule,
        NaosNotificationModule
    ],
    exports: [BagComponent],
    providers: [],
})
export class BagModule {}
