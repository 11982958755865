import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
    selector: 'app-naosnotification',
    templateUrl: './naosnotification.component.html',
    styleUrls: ['./naosnotification.component.css']
})
export class NaosNotificationComponent implements OnInit {

    @Input() msgModal: string;
    @Input() link: boolean;
    @Input() textUrl: string;
    @Output() avisoText = new EventEmitter<string>();
    public linkRegisterToLogin: boolean;
    public msgDefault: boolean;

    constructor(
        private _router: Router
    ) {
        this.link = false;
        this.msgDefault = false;
        this.linkRegisterToLogin = false;
        /* this.textUrl = undefined; */
    }

    ngOnInit(): void {
        let intervaloMsg = setInterval(() => {
            if (this.msgModal !== undefined) {
                this.checkDivContent();
                this.avisoText.emit('true');
                clearInterval(intervaloMsg);
            }
        }, 1000);
    }

    public checkDivContent(): void {
        let contenido = document.getElementById('contenidodiv');
    }

    public selectMsg(): void {
        switch (this.msgModal) {
            case 'registerlogin':
                this.linkRegisterToLogin = true;
                break;
            default:
                this.msgDefault = true;
                break;
        }
    }

    public goToLogin(): void {
        $('#exampleModalNotification').modal('hide');
        this._router.navigate(['/login']);
    }

    public goToMain(): void {
        $('#exampleModalNotification').modal('hide');
        this._router.navigate(['/main']);
    }

    public redirectConfirm(): void {
        if (this.textUrl !== undefined) {
            $('#exampleModalNotification').modal('hide');
            this._router.navigate([this.textUrl]);
        } else {
            $('#exampleModalNotification').modal('hide');
        }
    }
}
