import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css'],
    providers: [NAOSService],
    encapsulation: ViewEncapsulation.None,
})
export class MainComponent implements OnInit {

    public puntosVenta: Array<any>;
    public loader: boolean;

    public pageSite = 0;

    public advservercontent: any;
    public imagenMain: any;
    public urlImagenMain: string;

    public imagesModules: any;

    constructor(
        private _router: Router,
        private router: ActivatedRoute,
        public _naosService: NAOSService,
        private sanitizer: DomSanitizer
    ) {
        this.puntosVenta = [];
        this.loader = false;
    }

    ngOnInit(): void {
        this.getAdvServerContentsPublic();

        /* this.loader = true; */
    }

    /**
     * Funcion para obtener el contenido del advserver en la parte publica
     */
    public getAdvServerContentsPublic(): void {
      this._naosService.getAdvserverContentPublic().subscribe(
        response => {
          this.advservercontent = response;
          this.imagesModules = this._naosService.getOfferAdvByNameContentPlus('Principal-Modulo',this.advservercontent);
          // Cargamos el contenido de la imagen de la chica del advserver y en caso de que no exista metemos uno por defecto
          if (this._naosService.getOfferAdvByNameContent('Principal-Header', this.advservercontent) !== null) {
            this.imagenMain = this._naosService.getOfferAdvByNameContent('Principal-Header', this.advservercontent);
            this.urlImagenMain = this.imagenMain.content.images.web.es[0].url;
          } else {
            this.urlImagenMain = '../../../assets/images/ImagenPrincipal.png';
          }
          this.loader = true;
        },
        error => {
          console.error('Error ' + JSON.stringify(error));
          this.loader = true;
        }
      );
    }

    /**
     * Seleccionamos el elemento por nombre y devolvemos la url de la imagen correspondiente
     * @param name nombre del elemento a seleccionar
     */
    public getImageModuleByName(name: string): string {
      let urlImage: string = '';
      for (let itemImage of this.imagesModules) {
        if (name == itemImage.communication_sd) {
          urlImage = itemImage.content.images.web.es[0].url;
        }
      }
      return urlImage;
    }

    /**
     * Seleccionamos el elemento por nombre y devolvemos el titulo del texto correspondiente
     * @param name nombre del elemento a seleccionar
     */
    public getTitleModuleByName(name: string): string {
      let titleModule: any = '';
      for (let itemImage of this.imagesModules) {
        if (name == itemImage.communication_sd) {
          titleModule = this.sanitizer.bypassSecurityTrustHtml(itemImage.content.item_ld.es);
        }
      }
      return titleModule;
    }

    /**
     * Seleccionamos el elemento por nombre y devolvemos el texto del ALT correspondiente
     * @param name nombre del elemento a seleccionar
     */
    public getAltImageByName(name: string): string {
      let altTextImage: string = '';
      let urlPage: any = window.location.href;
      for (let itemImage of this.imagesModules) {
        if (name == itemImage.communication_sd) {
          altTextImage = itemImage.communication_sd;
        }
      }
      if (urlPage.indexOf('bioderma') > -1) {
        altTextImage = altTextImage + ' Bioderma';
      } else if (urlPage.indexOf('esthederm') > -1) {
        altTextImage = altTextImage + ' Esthederm';
      } else {
        altTextImage = altTextImage + ' Bioderma';
      }
      return altTextImage;
    }

    /**
     * Funcion para ir al login
     */
    public goToLogin(): void {
        this._router.navigate(['/login']);
    }

    public goToPPVVNaos(): void {
      this._router.navigate(['/mapappvv']);
    }

    /**
     * Funcion para ir al registro
     */
    public goToRegister(): void {
        this._router.navigate(['/register']);
    }

    public naosIdentificationCookie(place: string): void {
        if (place === 'up') {
            const message = JSON.stringify ({
                event: 'Naos-Club-registrationclick-ES'
            });
            /* parent.postMessage (message, environment.ENTORNOGTM); */
        } else if (place === 'down') {
            const message = JSON.stringify ({
                event: 'Naos-Club-registrationclick-ES'
            });
            /* parent.postMessage (message, environment.ENTORNOGTM); */
        }
        if (localStorage.getItem('NIN')) {
            this._router.navigate(['/login']);
        } else {
            this._router.navigate(['/register']);
        }
    }

    /**
     * Funcion para formatear el label de los centros
     * @param nombre del centro
     * @param codigo código del centro
     */
    public formatLabelSelectCenters(): any {
        this.puntosVenta.map(
            (i) => {
                if (i.jwhereAddress) {
                    i.fullName = i.jwhere.where_ld.toUpperCase() + ' (' + i.where_cd + ' - ' + i.jwhereAddress.postal_cd + ')';
                } else {
                    i.fullName = i.jwhere.where_ld.toUpperCase() + ' (' + i.where_cd + ')';
                }
            }
        );
        this.loader = true;
    }
}
