import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HealthComponent } from './health.component';

@NgModule({
  declarations: [HealthComponent],
  imports: [],
  exports: [HealthComponent],
  providers: [],
})
export class HealthModule {}