import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { v4 as uuidv4 } from 'uuid';
declare var $: any;


@Component({
    selector: 'app-naos-activation',
    templateUrl: './activation.component.html',
    styleUrls: ['./activation.component.css'],
    providers: [LoginService]
})
export class ActivationComponent implements OnInit {

    public token: any;
    public confirm: boolean;
    public sub: any;
    public textModal: string;
    public textUrl: string;
    public uuid: any;

    public disabledButton: boolean;

    constructor(
        private _route: Router,
        private route: ActivatedRoute,
        private _loginService: LoginService
    ) {
        this.confirm = false;
        this.token = undefined;
        this.uuid = uuidv4();
        this.disabledButton = false;
    }

    ngOnInit(): void {
        let intervalo = setInterval(() => {
            this.token = localStorage.getItem('tkn');
            if (this.token !== undefined && this.token !== null && this.token !== '') {
                this.disabledButton = true;
                let f = new Date();
                let obj = {
                    'token': this.token,
                    'endpoint': 'init-page',
                    'status': 'init-page',
                    'date': f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear() + ' ' + f.getHours() + ':' + f.getMinutes() + ':' + f.getSeconds(),
                    'uuid': this.uuid
                };
                this._loginService.sendLogsLSP(obj, this.token).subscribe(
                    () => {},
                    error => {
                        console.error('Error logs ' + JSON.stringify(error));
                    }
                );
                this.confirmEmailToken(this.token);
                clearInterval(intervalo);
            }
        }, 1500);
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._route.navigate([this.textUrl]);
        }
    }

    /**
     * Petición para confirmar la cuenta valida
     * @param token que recogemos de la url
     */
    public confirmEmailToken(token: any): void {
                let f = new Date();
                let obj = {
                    'token': this.token,
                    'endpoint': 'user/members/mail-confirmation',
                    'status': 'confirmation',
                    'date': f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear() + ' ' + f.getHours() + ':' + f.getMinutes() + ':' + f.getSeconds(),
                    'uuid': this.uuid
                };
                this._loginService.sendLogsLSP(obj, this.token).subscribe(
                    () => {},
                    error => {
                        console.error('Error logs ' + JSON.stringify(error));
                    }
                );
                this._loginService.confirmationEmail(token).subscribe(
                    () => {
                        this.confirm = true;
                    },
                    error => {
                        if (error.status == '200') {
                            this.textModal = 'Tu cuenta ha sido confirmada, puedes iniciar sesión con el botón "Entrar" introduciendo tu email y contraseña.';
                            this.textUrl = '/login';
                            $('#exampleModalNotificationActivation').modal('show');
                            this.confirm = true;
                        } else if (error.status == '400') {
                            this.textModal = 'Lo sentimos, ha expirado el plazo para activar tu cuenta. Pero si lo deseas puedes volver a registrarte en NAOS Club.';
                            this.textUrl = '/login';
                            $('#exampleModalNotificationActivation').modal('show');
                            this.confirm = true;
                        } else if (error.status == '409') {
                            this._route.navigate(['/login']);
                        } else {
                            this.textModal = error.error.message;
                            this.textUrl = null;
                            $('#exampleModalNotificationActivation').modal('show');
                            this.confirm = false;
                        }
                    }
                );
    }

    /**
     * Funcion para ir al login
     */
    public goToLogin(): void {
        $('#modalActivation').modal('hide');
        this._route.navigate(['login']);
    }
}
