import { FooterModule } from './../../components/footer/footer.module';
import { NavbarModule } from 'src/app/components/navbar/navbar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapaPPVVComponent } from './mapappvv.component';
import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';
import { HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';

/* const apiKeyBrand: string = (localStorage.getItem('brandweb') === 'BIODERMA') ? environment.APIKEYGEOCODINGBIODERMA : environment.APIKEYGEOCODINGESTHEDERM; */
const apiKeyBrand: string = environment.APIKEYMAPS;

@NgModule({
  declarations: [MapaPPVVComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NaosNotificationModule,
    HttpClientModule,
    NavbarModule,
    GoogleMapsModule,
    FooterModule
  ],
  exports: [MapaPPVVComponent],
  providers: [],
})
export class MapaPPVVModule {}
