import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { NotifierService } from 'angular-notifier';
import { DirectionService } from 'src/app/services/direction.service';
import { NAOSService } from 'src/app/services/naos.service';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Router } from '@angular/router';
declare var jQuery: any;
declare var $: any;


@Component({
    selector: 'app-datadirection-naos',
    templateUrl: './datadirection.component.html',
    styleUrls: ['./datadirection.component.css'],
    providers: [UserService, DirectionService, NAOSService]
})
export class DataDirectionComponent implements OnInit {

    public idclient: any;
    public infoDirection: any;
    public contErrors: number;
    public loader: boolean;
    public puntosVenta: Array<any>;
    // Datos direccion
    public postalCode: string = '';
    public number: string = '';
    public listNumbers: Array<any>;
    public municipalityIne: string = '';
    public provinceIne: string = '';
    public contactName: string = '';
    public addressType: string = '';
    public locality: string = '';
    public localityCode: string = '';
    public listLocality: Array<any>;
    public alias: string = '';
    public mainAddress: boolean;
    public contactSurname2: string = '';
    public stairs: string = '';
    public contactSurname: string = '';
    public contactTelf: string = '';
    public door: string = '';
    public portal: string = '';
    public floor: string = '';
    public country: string = 'España';
    public addressName: string = '';
    public listAddress: Array<any>;
    public directionValue: string = '';
    public province: string = '';
    public additionalAddress: string = '';
    public idremoveaddress: any;
    public loaderConfirm: boolean;

    public localitynotfound: boolean;
    public localitynotfoundvalue: string;
    public streetnotfound: boolean;
    public streetnotfoundvalue: string;
    public numbernotfound: boolean;
    public numbernotfoundvalue: string;

    public municipalityIneAux: any;
    public streetIneAux: any;


    // Variables para localidad nueva
    public dataLocalityNew: Array<any>;
    public termsLocalityNew: string;
    public localityNewSelected: any;
    public localitynewwithoutdata: boolean;
    // Variables para calle nueva
    public dataStreetNew: Array<any>;
    public termsStreetNew: string;
    public streetNewSelected: any;
    public streetnewwithoutdata: boolean;
    // Variables para numero nuevo
    public dataNumberNew: Array<any>;
    public termsNumberNew: string;
    public numberNewSelected: any;
    public numbernewwithoutdata: boolean;
    // Variables para localidad modificar
    public termsLocalityModified: string;
    public localityModifiedSelected: any;
    public localityModifiedWithoutData: boolean;
    // Variables para street modificar
    public termsStreetModified: string;
    public streetModifiedSelected: any;
    public streetModifiedWithoutData: boolean;
    // Variables para numero modificar
    public termsNumberModified: string;
    public numberModifiedSelected: any;
    public numberModifiedWithoutData: boolean;

    public textModal: string;
    public textUrl: string;

    @ViewChild('newlocality') newlocality;
    @ViewChild('newdirection') newdirection;

    public pageSite: number;

    constructor(
        private _userService: UserService,
        private _directionService: DirectionService,
        private config: NgSelectConfig,
        private _NAOSService: NAOSService,
        private _router: Router
    ) {
        this.contErrors = 0;
        this.loader = false;
        this.puntosVenta = [];
        this.config.notFoundText = 'Elemento no encontrado';
        this.mainAddress = false;
        this.loaderConfirm = false;
        this.localitynotfound = true;
        this.streetnotfound = true;
        this.numbernotfound = true;

        this.dataLocalityNew = [];
        this.dataStreetNew = [];
        this.dataNumberNew = [];
        /* this.dataLocalityModified = []; */
        this.localityNewSelected = undefined;
        this.streetNewSelected = undefined;
        this.numberNewSelected = undefined;
        this.localityModifiedSelected = undefined;
        this.localitynewwithoutdata = false;
        this.streetnewwithoutdata = false;
        this.numbernewwithoutdata = false;
        this.localityModifiedWithoutData = false;
        this.streetModifiedWithoutData = false;
        this.numberModifiedWithoutData = false;
        this.pageSite = 0;
    }

    ngOnInit(): void {
        this.idclient = this._NAOSService.desencryptData(sessionStorage.getItem('ILCK'));
        this.getDataDirection();
        let intervaloError = setInterval(() => {
            if (this.contErrors >= 1) {
                this.loader = true;
                clearInterval(intervaloError);
            }
        }, 1000);
    }

    // Funciones para la nueva localidad
    public onFocusedLocalityNew(evento: any) {}

    public selectEventLocalityNew(evento: any) {
        this.localityNewSelected = evento;
        this.province = this.localityNewSelected.provincia;
        this.municipalityIneAux = this.localityNewSelected.codigo;
    }

    public closedEventLocalityNew() {
        if (this.localityNewSelected == undefined) {
            this.localitynewwithoutdata = true;
            this.localityNewSelected = this.termsLocalityNew;
            this._directionService.autoCompletionLocality(this.localityNewSelected).subscribe(
                response => {
                    if (response.poblaciones.length > 0) {
                        for (let item of response.poblaciones) {
                            if (item.nombre.toUpperCase() == this.localityNewSelected.toUpperCase()) {
                                this.localityNewSelected = item;
                                this.province = this.localityNewSelected.provincia;
                            }
                        }
                    }
                },
                error => {
                    console.error('Error' + JSON.stringify(error));
                }
            );
        }
    }

    public onChangeSearchLocalityNew(evento: any) {
        this.additionalAddress = '';
        this.termsLocalityNew = evento;
        this._directionService.autoCompletionLocality(this.termsLocalityNew).subscribe(
            response => {
                this.dataLocalityNew = response.poblaciones;
                if (this.dataLocalityNew.length === 0 && evento !== '' && evento !== undefined) {
                    this.localityNewSelected = {
                        'nombre': this.termsLocalityNew
                    };
                }
            },
            error => {
                console.error('Error' + JSON.stringify(error));
            }
        );
    }

    public onChangeSearchStreetNew(evento: any) {
        this.additionalAddress = '';
        this.termsStreetNew = evento;
        this._directionService.autoCompletionVias(this.localityNewSelected.codigo, this.termsStreetNew).subscribe(
            response => {
                let auxArray = response.vias;

               /*  if (auxArray.length === 0 && evento !== '' && evento !== undefined) { */
                    this.streetNewSelected = {
                        'nombre': this.termsStreetNew
                    };
                /* } else { */
                    for (let item of auxArray) {
                        item.nombre = item.tipo + ' ' + item.nombre;
                    }
                    this.dataStreetNew = auxArray;
                /* } */

            },
            error => {
                console.error('Error' + JSON.stringify(error));
            }
        );
    }

    // Funciones para calle nueva
    public onFocusedStreetNew(evento: any) {}

    public selectEventStreetNew(evento: any) {
        this.streetNewSelected = evento;
        let arrayAux: Array<any> = [];
        this._directionService.autoCompletionNumbers(this.localityNewSelected.codigo, this.streetNewSelected.codigo, '').subscribe(
            response => {
                if (response.data.numeros.length > 0) {
                    for (let item of response.data.numeros) {
                        let obj = {
                            'numero': item
                        };
                        arrayAux.push(obj);
                    }
                    this.dataNumberNew = arrayAux;
                } else {
                    this.numbernotfound = false;
                }
            },
            error => {
                console.error('Error' + JSON.stringify(error));
            }
        );
    }

    public closedEventStreetNew() {
        if (this.streetNewSelected == undefined) {
            this.streetnewwithoutdata = true;
            this.streetNewSelected = this.termsStreetNew;
            this._directionService.autoCompletionVias(this.localityNewSelected.codigo, this.streetNewSelected).subscribe(
                response => {
                    let auxArray: Array<any> = response.vias;
                    for (let item of auxArray) {
                        item.nombre = item.tipo + ' ' + item.nombre;
                    }
                    this.dataStreetNew = auxArray;
                    if (response.vias.length > 0) {
                        for (let item of response.vias) {
                            if (item.nombre.toUpperCase() == this.streetNewSelected.toUpperCase()) {
                                this.streetNewSelected = item;
                            }
                        }
                    }
                },
                error => {
                    console.error('Error' + JSON.stringify(error));
                }
            );
        }
    }

    /**
     * Resetea el campo aditionalAddress a blanco si se usa otro campo de direccion nueva
     */
    public setWhiteSpaceAdditionalAddress(): void {
        this.additionalAddress = '';
    }

    /**
     * Resetea el campo aditionalAddress a blanco si se usa otro campo de direccion modificar
     */
    public setWhiteSpaceAdditionalAddressModified(index: number): void {
        this.infoDirection[index].additionalAddress = '';
    }



    // Funciones para numero nuevo
    public onFocusedNumberNew(evento: any) {}

    public selectEventNumberNew(evento: any) {
        this.additionalAddress = '';
        this.numberNewSelected = evento;
    }

    public closedEventNumberNew() {
        if (this.numberNewSelected == undefined) {
            this.numbernewwithoutdata = true;
            this.numberNewSelected = this.termsNumberNew;
        }

    }

    public onChangeSearchNumberNew(evento: any) {
        this.termsNumberNew = evento;
        let arrayAux: Array<any> = [];
        this._directionService.autoCompletionNumbers(this.localityNewSelected.codigo, this.streetNewSelected.codigo, this.termsNumberNew).subscribe(
            response => {
                if (response.data.numeros.length > 0) {
                    for (let item of response.data.numeros) {
                        let obj = {
                            'numero': item
                        };
                        arrayAux.push(obj);
                    }
                    this.dataNumberNew = arrayAux;
                } else {
                    this.numbernotfound = false;
                }
            },
            error => {
                console.error('Error' + JSON.stringify(error));
            }
        );
    }

    // Funciones para modificar localidad

    public onFocusedLocalityModified(evento: any) {}

    public selectEventLocalityModified(evento: any, index: any) {
        this.infoDirection[index].municipality = evento.provincia;
        this.infoDirection[index].localty = evento.nombre;
        this.infoDirection[index].municipalityIne = evento.codigo;
    }

    public closedEventLocalityModified(index: any) {
        if (this.infoDirection[index].localty == '') {
            this.localitynewwithoutdata = true;
            this.infoDirection[index].localty = this.termsLocalityNew;
            this._directionService.autoCompletionLocality(this.localityNewSelected).subscribe(
                response => {
                    if (response.poblaciones.length > 0) {
                        for (let item of response.poblaciones) {
                            if (item.nombre.toUpperCase() == this.infoDirection[index].localty.toUpperCase()) {
                                this.infoDirection[index].localty = item.nombre;
                                this.infoDirection[index].municipalityIne = item.codigo;
                            }
                        }
                    }
                },
                error => {
                    console.error('Error' + JSON.stringify(error));
                }
            );
        }
    }

    public onChangeSearchLocalityModified(evento: any, index: any) {
        this.infoDirection[index].additionalAddress = '';
        this.termsLocalityModified = evento;
        this._directionService.autoCompletionLocality(this.termsLocalityModified).subscribe(
            response => {
                if (response.poblaciones.length > 0) {
                    this.infoDirection[index].poblaciones = response.poblaciones;
                } else {
                    this.infoDirection[index].localty = this.termsLocalityModified;
                }

            },
            error => {
                console.error('Error' + JSON.stringify(error));
            }
        );
    }

    // Funciones para modificar direccion
    public onFocusedStreetModified(evento: any) {}

    public selectEventStreetModified(evento: any, index: any) {
        this.infoDirection[index].addressName = evento.nombre;
        this.streetModifiedSelected = evento.nombre;
        this.infoDirection[index].localty = evento.litPobla;
        this._directionService.autoCompletionVias(this.infoDirection[index].municipalityIne, this.streetModifiedSelected).subscribe(
            response => {
                let auxArray: Array<any> = response.vias;
                for (let item of auxArray) {
                    item.nombre = item.tipo + ' ' + item.nombre;
                }
                for (let item of auxArray) {
                    if (item.nombre.toUpperCase() == this.streetModifiedSelected.toUpperCase()) {
                        this.streetModifiedSelected = item;
                        this.infoDirection[index].addressName = this.streetModifiedSelected.nombre;
                        this._directionService.autoCompletionNumbers(this.infoDirection[index].municipalityIne, this.streetModifiedSelected.codigo, '').subscribe(
                            responseNumbers => {
                                let arrayAUx: Array<any> = [];
                                if (responseNumbers.data.numeros.length > 0) {
                                    for (let itemNumber of responseNumbers.data.numeros) {
                                        let obj = {
                                            'numero': itemNumber
                                        };
                                        arrayAUx.push(obj);
                                    }
                                    this.infoDirection[index].numeros = arrayAUx;
                                } else {
                                    this.numbernotfound = false;
                                }
                            },
                            error => {
                                console.error('Error' + JSON.stringify(error));
                            }
                        );
                    }
                }
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    public closedEventStreetModified(index: any) {
        if (this.infoDirection[index].addressName == '') {
            this.streetModifiedWithoutData = true;
            this.streetModifiedSelected = this.termsStreetModified;
            this._directionService.autoCompletionVias(this.infoDirection[index].municipalityIne, this.streetModifiedSelected).subscribe(
                response => {
                    let auxArray: Array<any> = response.vias;
                    for (let item of auxArray) {
                        item.nombre = item.tipo + ' ' + item.nombre;
                    }
                    for (let item of auxArray) {
                        if (item.nombre.toUpperCase() == this.streetModifiedSelected.toUpperCase()) {
                            this.streetModifiedSelected = item;
                            this.infoDirection[index].addressName = this.streetModifiedSelected.nombre;
                            this._directionService.autoCompletionNumbers(this.infoDirection[index].municipalityIne, this.streetModifiedSelected.codigo, '').subscribe(
                                responseNumbers => {
                                    let arrayAUx: Array<any> = [];
                                    if (responseNumbers.data.numeros.length > 0) {
                                        for (let itemNumber of responseNumbers.data.numeros) {
                                            let obj = {
                                                'numero': itemNumber
                                            };
                                            arrayAUx.push(obj);
                                        }
                                        this.infoDirection[index].numeros = arrayAUx;
                                    } else {
                                        this.numbernotfound = false;
                                    }
                                },
                                error => {
                                    console.error('Error' + JSON.stringify(error));
                                }
                            );
                        }
                    }
                },
                error => {
                    console.error('Error ' + JSON.stringify(error));
                }
            );
        }
    }

    public onChangeSearchStreetModified(evento: any, index: any) {
        this.infoDirection[index].additionalAddress = '';
        this.termsStreetModified = evento;
        this._directionService.autoCompletionVias(this.infoDirection[index].municipalityIne, this.termsStreetModified).subscribe(
            response => {
                let auxArray: Array<any> = response.vias;
                for (let item of auxArray) {
                    item.nombre = item.tipo + ' ' + item.nombre;
                }
                this.infoDirection[index].vias = auxArray;
            },
            error => {
                console.error('Error' + JSON.stringify(error));
            }
        );
    }

    // Funciones para numero modificar
    public onChangeSearchNumberModified(evento: any, index: any) {
        this.infoDirection[index].additionalAddress = '';
        this.termsStreetModified = evento;
        this.infoDirection[index].number = evento;
    }

    // Funciones para enviar
    /**
     * Funcion para enviar los datos de alta de dirección
     */
    public sendDataClientDirection(): void {
        if (this.checkValidateDataDirection()) {
            if (this.checkCP(this.postalCode)) {
                if (this.checkCPSpain(this.postalCode)) {
                    let obj = {
                        'postalCode': this.postalCode,
                        'number': this.numberNewSelected.toString(),
                        'contactName': this.contactName,
                        'addressType': this.addressType,
                        'localty': this.localityNewSelected.nombre,
                        'alias': this.alias,
                        'municipalityIne': this.municipalityIneAux,
                        'mainAddress': this.mainAddress,
                        'contactSurname2': this.contactSurname2,
                        'stairs': this.stairs,
                        'contactSurname': this.contactSurname,
                        'door': this.door,
                        'portal': this.portal,
                        'floor': this.floor,
                        'country': this.country,
                        'additionalAddress': this.additionalAddress,
                        'addressName': this.streetNewSelected.nombre,
                        'province': this.province,
                        'contactPhone': this.contactTelf.replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '')
                    };
                    this._directionService.sendDirectionClient(this.idclient, obj).subscribe(
                        () => {
                            this.textModal = 'Los cambios se han guardado correctamente. Muchas gracias por actualizar tus datos.';
                            this.textUrl = null;
                            $('#exampleModalNotificationDataDirection').modal('show');
                            this.resetCampsNewDirection();
                            this.getDataDirection();
                            this.streetnotfoundvalue = '';
                            this.localitynotfoundvalue = '';
                            this.numbernotfoundvalue = '';
                        },
                        error => {
                            console.error('Error' + JSON.stringify(error));
                            this.textModal = 'No ha sido posible registrar la nueva dirección. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com';
                            this.textUrl = null;
                            $('#exampleModalNotificationDataDirection').modal('show');
                        }
                    );
                } else {
                    this.textModal = 'Lo sentimos, pero únicamente se pueden dar de alta en el club los residentes en Península, Baleares y Canarias';
                    this.textUrl = null;
                    $('#exampleModalNotificationDataDirection').modal('show');
                }
            } else {
                this.textModal = 'El código postal es incorrecto. Por favor, introduce uno válido.';
                this.textUrl = null;
                $('#exampleModalNotificationDataDirection').modal('show');
            }
        } else {
            this.textModal = 'Por favor, rellena los campos obligatorios para continuar.';
            this.textUrl = null;
            $('#exampleModalNotificationDataDirection').modal('show');
        }
    }

    /**
     * Funcion para modificar la dirección genérica cuando el cliente añade su primera dirección
     */
    public sendDataClientDirectionGeneric(): void {
        if (this.checkValidateDataDirection()) {
            if (this.checkCP(this.postalCode)) {
                if (this.checkCPSpain(this.postalCode)) {
                    let obj = {
                        'postalCode': this.postalCode,
                        'number': this.numberNewSelected.toString(),
                        'contactName': this.contactName,
                        'addressType': this.addressType,
                        'localty': this.localityNewSelected.nombre,
                        'alias': this.alias,
                        'municipalityIne': this.municipalityIneAux,
                        'mainAddress': this.mainAddress,
                        'contactSurname2': this.contactSurname2,
                        'stairs': this.stairs,
                        'contactSurname': this.contactSurname,
                        'door': this.door,
                        'portal': this.portal,
                        'floor': this.floor,
                        'country': this.country,
                        'additionalAddress': this.additionalAddress,
                        'addressName': this.streetNewSelected.nombre,
                        'province': this.province,
                        'contactPhone': this.contactTelf.replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '')
                    };
                    this._directionService.updateDirectionClient(this.idclient, this.infoDirection[0].idAddress, obj).subscribe(
                        () => {
                            this.textModal = 'Los cambios se han guardado correctamente. Muchas gracias por actualizar tus datos.';
                            this.textUrl = null;
                            $('#exampleModalNotificationDataDirection').modal('show');
                            this.resetCampsNewDirection();
                            this.getDataDirection();
                            this.streetnotfoundvalue = '';
                            this.localitynotfoundvalue = '';
                            this.numbernotfoundvalue = '';
                        },
                        () => {
                            this.textModal = 'No ha sido posible registrar la nueva dirección. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com';
                            this.textUrl = null;
                            $('#exampleModalNotificationDataDirection').modal('show');
                        }
                    );
                } else {
                    this.textModal = 'Lo sentimos, pero únicamente se pueden dar de alta direcciónes en Península y Baleares.';
                    this.textUrl = null;
                    $('#exampleModalNotificationDataDirection').modal('show');
                }
            } else {
                this.textModal = 'El código postal es incorrecto. Por favor, introduce uno válido.';
                this.textUrl = null;
                $('#exampleModalNotificationDataDirection').modal('show');
            }
        } else {
            this.textModal = 'Por favor, rellena los campos obligatorios para continuar.';
            this.textUrl = null;
            $('#exampleModalNotificationDataDirection').modal('show');
        }
    }

    /**
     * Fucnion para obtener las direcciones del cliente
     */
    public getDataDirection(): void {
        this._userService.getDataDirectionClient(this.idclient).subscribe(
            response => {
                this.infoDirection = response;
                if (this.infoDirection !== null && this.infoDirection !== undefined) {
                    for (let item of this.infoDirection) {
                        item.poblaciones = [];
                        item.vias = [];
                        item.numeros = [];
                        item.contact_name = '';
                        item.contact_surname = '';
                        item.contact_surname2 = '';
                        item.contact_telf = '';
                    }
                } else {
                    this.infoDirection = [];
                    this.contErrors++;
                }
                this.contErrors++;
            },
            error => {
                this.contErrors++;
                this.textModal = error.error.message;
                this.textUrl = null;
                $('#exampleModalNotificationDataDirection').modal('show');
            }
        );
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }

    /* public checkUpdateDataDirection(): boolean {

    } */

    /**
     * Funcion para modificar una dirección
     * @param idaddress id de la dirección a modificar
     */
    public sendDataUpdateDirection(idaddress: any, indexDirection: any): void {
        if (this.infoDirection[indexDirection].addressName !== '' && this.infoDirection[indexDirection].localty !== '') {
            if (this.checkCP(this.infoDirection[indexDirection].postalCode)) {
                if (this.checkCPSpain(this.infoDirection[indexDirection].postalCode)) {
                    this._directionService.updateDirectionClient(this.idclient, idaddress, this.formatObjDataAddressUpdate(this.infoDirection[indexDirection])).subscribe(
                        () => {
                            this.textModal = 'Los cambios se han guardado correctamente. Muchas gracias por actualizar tus datos.';
                            this.textUrl = null;
                            $('#exampleModalNotificationDataDirection').modal('show');
                            this.resetCampsNewDirection();
                            this.getDataDirection();
                            this.streetnotfoundvalue = '';
                            this.localitynotfoundvalue = '';
                            this.numbernotfoundvalue = '';
                        },
                        error => {
                            console.error('No ha sido posible modificar la dirección. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.');
                            this.textModal = error.error.message;
                            this.textUrl = null;
                            $('#exampleModalNotificationDataDirection').modal('show');
                        }
                    );
                } else {
                    this.textModal = 'Lo sentimos, pero únicamente se pueden dar de alta dirección en Península y Baleares.';
                    this.textUrl = null;
                    $('#exampleModalNotificationDataDirection').modal('show');
                }
            } else {
                this.textModal = 'El código postal es incorrecto. Por favor, introduce uno válido.';
                this.textUrl = null;
                $('#exampleModalNotificationDataDirection').modal('show');
            }
        } else {
            this.textModal = 'Lo sentimos, debes rellenar todos lo campos obligatorios para continuar.';
            this.textUrl = null;
            $('#exampleModalNotificationDataDirection').modal('show');
        }
}

/**
     * Funcion para formatear el objeto que vamos a enviar para modificar una dirección
     */
    public formatObjDataAddressUpdate(objDirection: any): any {
        let obj = {
            'postalCode': objDirection.postalCode,
            'number': objDirection.number,
            'municipalityIne': objDirection.municipalityIne,
            'provinceIne': objDirection.provinceIne,
            'contactName': objDirection.contactName,
            'addressType': objDirection.addressType,
            'localty': (typeof(objDirection.localty) === 'object') ? objDirection.localty.nombre : objDirection.localty,
            'alias': objDirection.alias,
            'additionalAddress': objDirection.additionalAddress,
            'mainAddress': objDirection.mainAddress,
            'contactSurname2': objDirection.contactSurname2,
            'stairs': objDirection.stairs,
            'contactSurname': objDirection.contactSurname,
            'door': objDirection.door,
            'portal': objDirection.portal,
            'floor': objDirection.floor,
            'country': 'ESPAÑA',
            'addressName': objDirection.addressName,
            'province': objDirection.province,
            'contactPhone': objDirection.contactPhone.replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '')
            };
        return obj;
    }

    /**
     * Funcion para resetear los campos despues de enviar
     */
    public resetCampsNewDirection(): void {
        this.postalCode = '';
        this.addressType = '';
        this.number = '';
        this.floor = '';
        this.door = '';
        this.stairs = '';
        this.portal = '';
        this.province = '';
        this.contactName = '';
        this.additionalAddress = '';
        this.contactSurname = '';
        this.contactSurname2 = '';
        this.contactTelf = '';
        this.localityCode = '';
        this.directionValue = '';
        this.listLocality = [];
        this.listAddress = [];
        this.listNumbers = [];
        this.newlocality.clear();
        this.newdirection.clear();
        this.dataStreetNew = [];
        this.numberNewSelected = '';
        $('#collapseExample').removeClass('collapse show');
        $('#collapseExample').addClass('collapse');
    }























































    /**
     * Funcion para autocompletar las localidades
     * @param terms termino para buscar
     */
    public autoCompleteLocality(terms: string, index: any): void {
        if (terms.length > 2) {
            let aux = terms;
            this._directionService.autoCompletionLocality(terms).subscribe(
                response => {
                    if (response.poblaciones.length > 0) {
                        this.infoDirection[index].localitynotfound = false;
                        $('#localityfound' + index).focus();
                        this.listLocality = response.poblaciones;
                        if (index !== undefined) {
                            this.infoDirection[index].poblaciones = this.listLocality;
                        }
                        this.infoDirection[index].poblaciones = this.listLocality;
                    } else {
                        this.infoDirection[index].localitynotfound = true;
                        this.infoDirection[index].localty = aux;
                        this.localitynotfoundvalue = this.localityCode;
                        $('#localitynotfound' + index).focus();
                        this.infoDirection[index].vias = [];
                    }
                },
                error => {
                    this.infoDirection[index].poblaciones = [];
                    /* this._notifierService.notify('error', error.error.message); */
                }
            );
        }
    }

    public formatNameVia(arrayDirections: any): void {
        for (let item of arrayDirections) {
            item.addressName = item.addressType + ', ' + item.addressName;
        }
    }

    /**
     * Funcion para autocompletar las localidades al modificar
     * @param terms termino para buscar
     */
    public autoCompleteLocalityModified(terms: string, index: any): void {
        if (terms.length > 2) {
            this.localityCode = terms;
            this.localitynotfoundvalue = this.localityCode;
            this._directionService.autoCompletionLocality(terms).subscribe(
                response => {
                    this.listLocality = response.poblaciones;
                    if (this.listLocality.length > 0) {
                        // Aqui va si lo que mete el cliente es igual a una sola respuesta
                        if (this.listLocality.length <= 10) {
                            for (let item of this.listLocality) {
                                if (item.nombre.toUpperCase() === terms.toUpperCase()) {
                                    this.localitynotfound = false;
                                    this.locality = item.nombre;
                                    this.province = item.provincia;
                                    this.localityCode = item.codigo;
                                    this.municipalityIneAux = item.codigo;
                                }
                            }
                        }
                        if (index !== undefined) {
                            this.infoDirection[index].poblaciones = this.listLocality;
                        }
                        // this.infoDirection[index].poblaciones = this.listLocality;
                        this.directionValue = '';
                    } else {
                        this.localitynotfound = false;
                        $('#localitynotfoundundefined').focus();
                        this.localityCode = this.localitynotfoundvalue;
                        this.locality = this.localitynotfoundvalue;
                    }
                },
                error => {
                    this.infoDirection[index].poblaciones = [];
                    /* this._notifierService.notify('error', error.error.message); */
                }
            );
        }
    }

    /**
     * Funcion para autocompletar las vias
     * @param terms termino a buscar
     */
    public autoCompleteVia(terms: string, index: any): void {
        this.infoDirection[index].addressName = terms;
        if (terms.length > 2) {
            if (this.infoDirection[index].municipalityIne !== undefined && this.infoDirection[index].municipalityIne !== '') {
                this.municipalityIneAux = this.infoDirection[index].municipalityIne;
                this._directionService.autoCompletionVias(this.infoDirection[index].municipalityIne, terms).subscribe(
                    response => {
                        if ( response.vias.length > 0) {
                            this.infoDirection[index].viasnotfound = false;
                            this.listAddress = response.vias;
                            for (let item of this.listAddress) {
                                if (item.tipo !== '') {
                                    item.nombre = item.tipo + ', ' + item.nombre;
                                }
                            }
                            if (index !== undefined) {
                                this.infoDirection[index].vias = this.listAddress;
                            }
                        } else {
                            this.infoDirection[index].viasnotfound = true;
                            this.infoDirection[index].municipalityIne = this.municipalityIneAux;
                            $('#viasnotfound' + index).focus();
                        }
                    },
                    error => {
                        /* this._notifierService.notify('error', error.error.message); */
                    }
                );
            } else {
                this._directionService.autoCompletionVias(this.municipalityIneAux, terms).subscribe(
                    response => {
                        if ( response.vias.length > 0) {
                            this.infoDirection[index].viasnotfound = false;
                            this.listAddress = response.vias;
                            for (let item of this.listAddress) {
                                if (item.tipo !== '') {
                                    item.nombre = item.tipo + ', ' + item.nombre;
                                }
                            }
                            if (index !== undefined) {
                                this.infoDirection[index].vias = this.listAddress;
                            }
                        } else {
                            this.infoDirection[index].viasnotfound = true;
                            this.infoDirection[index].municipalityIne = this.municipalityIneAux;
                            $('#viasnotfound' + index).focus();
                        }
                    },
                    error => {
                        /* this._notifierService.notify('error', error.error.message); */
                    }
                );
            }
        }
    }

    /**
     * Funcion para autocompletar las vias al modificar
     * @param terms termino a buscar
     */
    public autoCompleteViaModified(terms: string, index: any): void {
        if (terms.length > 2) {
            this.directionValue = terms;
            this.streetnotfoundvalue = this.directionValue;
            this._directionService.autoCompletionVias(this.localityCode, terms).subscribe(
                response => {
                    this.listAddress = response.vias;
                    if (this.listAddress.length > 0) {
                        if (this.listAddress.length <= 5) {
                            for (let item of this.listAddress) {
                                if (item.nombre.toUpperCase() === terms.toUpperCase()) {
                                    this.streetnotfound = false;
                                    this.addressName = item.nombre;
                                    this.streetIneAux = item.nombre;
                                }
                            }
                        }
                        for (let item of this.listAddress) {
                            if (item.tipo !== '') {
                                item.nombre = item.tipo + ', ' + item.nombre;
                            }
                        }
                        if (index !== undefined) {
                            this.infoDirection[index].vias = this.listAddress;
                        }
                        this.number = '';
                    } else {
                        this.streetnotfound = false;
                        $('#streetnotfoundundefined').focus();
                        this.directionValue = this.streetnotfoundvalue;
                        this.addressName = this.streetnotfoundvalue;
                    }
                },
                error => {
                    /* this._notifierService.notify('error', error.error.message); */
                }
            );
        }
    }

    /**
     * Funcion para autocompletar los numeros
     * @param number numero por el que buscar
     */
    public autoCompleteNumber(number: string, index: any): void {
        this.number = number;
        this._directionService.autoCompletionNumbers(this.localityCode, this.directionValue, number).subscribe(
            response => {
                if (response !== null && response.data.numeros.length > 0) {
                    this.listNumbers = response.data.numeros;
                    if (index !== undefined) {
                        this.infoDirection[index].numbers = this.listAddress;
                    }
                } else {
                    this.numbernotfound = false;
                    $('#numbernotfoundundefined').focus();
                    this.numbernotfoundvalue = this.number;
                    this.number = this.numbernotfoundvalue;
                }
            },
            error => {
                /* this._notifierService.notify('error', error.error.message); */
            }
        );
    }

    /**
     * Funcion para marcar la dirección principal
     */
    public setMainTextDirection(type: number): string {
        if (type == 1) {
            return 'Principal';
        } else {
            return type.toString();
        }
    }

    /**
     * Funcion para autocompletar los numeros al modificar
     * @param number numero por el que buscar
     */
    public autoCompleteNumberModified(number: string, index: any): void {
        let arrayNumeros: Array<any> = [];
        this._directionService.autoCompletionNumbers(this.localityCode, this.directionValue, number).subscribe(
            response => {
                this.listNumbers = response.data.numeros;
                if (response.data.numeros.length > 0) {
                    if (index !== undefined) {
                        for (let num of this.listNumbers) {
                            let obj = {
                                'value': num
                            };
                            arrayNumeros.push(obj);
                        }
                        this.infoDirection[index].numbers = arrayNumeros;
                    }
                } else {
                    if (index !== undefined) {
                        this.infoDirection[index].numbersnotfound = true;
                        this.infoDirection[index].number = number;
                        $('#numbernotfound' + index).focus();
                    }
                }
            },
            error => {
                /* this._notifierService.notify('error', error.error.message); */
            }
        );
    }

    /**
     * Función para recoger el valor de la localidad
     */
    public setValueLocality(evento, index): void {
        this.infoDirection[index].municipalityIne = evento.codigo;
        this.municipalityIneAux = evento.codigo;
        this.infoDirection[index].vias = [];
        this.infoDirection[index].numbers = [];
        this.infoDirection[index].province = evento.provincia;
    }

    /**
     * Función para recoger el valor de la localidad
     */
    public setValueLocalityNew(evento: any) {
        this.locality = evento.nombre;
        this.province = evento.provincia;
        this.municipalityIneAux = evento.codigo;
    }

    /**
     * Funcion para chequear el codigo postal
     */
    public checkCP(cp: any): boolean {
        var valoresAceptados = /^[0-9]+$/;
        if (cp.length == 5 && parseInt(cp) >= 1000 && parseInt(cp) <= 52999) {
            if (cp.match(valoresAceptados)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * Función para comprobar que no esta en los excluidos (Las islas canarias, Ceuta y Melilla)
     */
    public checkCPSpain(cp: any): boolean {
        let codigosExcluidos = ['51', '52', 'AD'];
        let auxError: number = 0;
        for (let item of codigosExcluidos) {
            let aux = cp.substr(0, 2);
            if (aux == item) {
                auxError++;
            }
        }
        if (auxError > 0) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Funcion para validar datos de alta direccion
     */
    public checkValidateDataDirection(): boolean {
        let validate: boolean = false;
        if (this.streetNewSelected !== '' && this.streetNewSelected !== undefined && this.numberNewSelected !== '' && this.postalCode !== '' && this.localityNewSelected !== '' && this.province !== '' && this.contactName !== '' && this.contactSurname !== '') {
            validate = true;
        } else {
            validate = false;
        }
        return validate;
    }

    /**
     * Funcion para borrar la direccion de un cliente
     * @param idclient id del cliente de la direccion
     * @param iddirection id de la direccion a borrar
     */
    public deleteAddressClient(iddirection: any): void {
        this.loaderConfirm = true;
        this._directionService.deleteDirectionClient(this.idclient, iddirection).subscribe(
            () => {
                /* this._notifierService.notify('success', 'Has borrado la dirección.'); */
                this.getDataDirection();
                $('#modalEliminarDireccion').modal('hide');
                this.loaderConfirm = false;
                this.textModal = 'Los cambios se han guardado correctamente. Muchas gracias por actualizar tus datos.';
                this.textUrl = null;
                $('#exampleModalNotificationDataDirection').modal('show');
            },
            error => {
                $('#modalEliminarDireccion').modal('hide');
                this.loaderConfirm = false;
                /* this._notifierService.notify('error', error.error.message); */
            }
        );
    }

    /**
     * Funcion para recoger el valor del numero segun la calle
     * @param evento valor de lo seleccionado en el select
     */
    public setValueNumber(evento, index): void {
        if (index !== undefined) {
            this.number = evento.value;
        } else {
            this.number = evento;
        }
    }

    /**
     * Funcion para formatear el label de los centros
     * @param nombre del centro
     * @param codigo código del centro
     */
    public formatLabelSelectCenters(): any {
        this.puntosVenta.map(
            (i) => {
                if (i.jwhereAddress) {
                    i.fullName = i.jwhere.where_ld + ' (' + i.where_cd + ' - ' + i.jwhereAddress.postal_cd + ')';
                }
            }
        );
        this.contErrors++;
    }

    public deleteAddress(id: any): void {
        this.idremoveaddress = id;
        $('#modalEliminarDireccion').modal('show');
    }

    /**
     * Funcion para recoger el valor de la calle segun la localidad
     * @param evento valor de lo seleccionado en el select
     */
    public setValueVia(evento): void {
        this.addressName = evento.nombre;
        this.streetIneAux = evento.nombre;
    }

    public initDirectionAutocompletion(): void {
        for (let x = 0; x < this.infoDirection.length; x++) {
            let arrayNumeros: Array<any> = [];
            this._directionService.autoCompletionLocality(this.infoDirection[x].localty).subscribe(
                responseAuto1 => {
                    if (responseAuto1.poblaciones.length > 0) {
                        this.infoDirection[x].poblaciones = responseAuto1.poblaciones;
                        for (let itemPoblacion of responseAuto1.poblaciones) {
                            if (itemPoblacion.nombre == this.infoDirection[x].localty) {
                                this.infoDirection[x].codigoPoblacion = itemPoblacion.codigo;
                                this.localityCode = itemPoblacion.codigo;
                            }
                        }
                        if (this.localityCode !== undefined) {
                            this._directionService.autoCompletionVias(this.localityCode, this.infoDirection[x].addressName).subscribe(
                                responseAuto2 => {
                                    if (responseAuto2.vias.length > 0) {
                                        this.infoDirection[x].vias = responseAuto2.vias;
                                        for (let itemVias of responseAuto2.vias) {
                                            if (itemVias.nombre == this.infoDirection[x].addressName) {
                                                this.infoDirection[x].codigoVias = itemVias.codigo;
                                                this.directionValue = itemVias.codigo;
                                            }
                                        }
                                        if (this.infoDirection[x].codigoPoblacion !== undefined && this.infoDirection[x].codigoVias !== undefined) {
                                            this._directionService.autoCompletionNumbers(this.infoDirection[x].codigoPoblacion, this.infoDirection[x].codigoVias, parseInt(this.infoDirection[x].number)).subscribe(
                                                response => {
                                                    if (response.data.numeros.length > 0) {
                                                        this.infoDirection[x].numbers = response.data.numeros;
                                                        for (let itemNumeros of response.data.numeros) {
                                                            if (itemNumeros.toString() == parseInt(this.infoDirection[x].number)) {
                                                                for (let num of this.infoDirection[x].numbers) {
                                                                    let obj = {
                                                                        'value': num
                                                                    };
                                                                    arrayNumeros.push(obj);
                                                                }
                                                                this.infoDirection[x].numbers = arrayNumeros;
                                                                this.number = itemNumeros;
                                                            }
                                                        }
                                                    } else {
                                                        this.infoDirection[x].numbersnotfound = true;
                                                    }
                                                },
                                                error => {
                                                    this.infoDirection[x].numbers = [];
                                                    this.infoDirection[x].numberc = '';
                                                    /* this._notifierService.notify('error', error.error.message); */
                                                }
                                            );
                                        }
                                    } else {
                                        this.infoDirection[x].viasnotfound = true;
                                    }
                                },
                                error => {
                                    this.infoDirection[x].vias = [];
                                    this.infoDirection[x].codigoVias = '';
                                    /* this._notifierService.notify('error', error.error.message); */
                                }
                            );
                        }
                    } else {
                        this.infoDirection[x].localitynotfound = true;
                    }
                },
                error => {
                    this.infoDirection[x].poblaciones = [];
                    this.infoDirection[x].codigo = '';
                    /* this._notifierService.notify('error', error.error.message); */
                }
            );
        }
        this.contErrors++;
    }
}
