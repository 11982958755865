import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RememberPasswordComponent } from './rememberpassword.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';

@NgModule({
    declarations: [RememberPasswordComponent],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NaosNotificationModule
    ],
    exports: [RememberPasswordComponent],
    providers: [],
})
export class RememberPasswordModule {}
