import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
declare var $: any;
declare var require: any
var unorm = require('unorm');
var CryptoJS = require("crypto-js");

@Injectable()
export class NAOSService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;

    constructor(
        private _http: HttpClient,
        private _router: Router
    ) {
        this.url = environment.urlAPI;
    }

    /**
     * Peticion para obtener los puntos de venta
     */
    public getShopSite(page: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPSITES;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('page', page);
        params = params.append('availables', 'true');
        params = params.append('sizePage', '10');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Metodo para dar de baja comercial desde un email
     * @param obj objeto para dar de baja comercial
     */
    public sendUnsubscribeComers(obj: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.SENDUNSUBSCRIBEEMAIL;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        return this._http.put(this._apiUrl, obj, {params});
    }

    /**
     * Peticion para obtener los puntos de venta
     */
    public getShopSiteProvince(provincia: any, typeshop: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPSITES;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('codProvince', provincia);
        params = params.append('availables', 'true');
        params = params.append('filter=shop_type_id', typeshop);
        return this._http.get(this._apiUrl, {params});
    }

    public getShopSiteProvinceWithoutType(provincia: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPSITES;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('codProvince', provincia);
        params = params.append('availables', 'true');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Peticion para obtener los puntos de venta
     */
    public getShopSiteProfile(page: any, typeshop: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPSITES;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('page', page);
        params = params.append('sizePage', '10');
        params = params.append('filter=shop_type_id', typeshop);
        return this._http.get(this._apiUrl, {params});
    }

    public getShopDetailById(id: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPSITES;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('codProvince', '0');
        params = params.append('filter=where_id', id);
        return this._http.get(this._apiUrl, {params});
    }

    public getShopDetailByWhereCd(id: any): Observable<any> {
      this._apiUrl = this.url + AppSettings.SHOPSITES;
      let params: HttpParams = new HttpParams();
      params = params.append('companyId', '4');
      params = params.append('codProvince', '0');
      params = params.append('filter=where_cd', id);
      return this._http.get(this._apiUrl, {params});
  }

    /**
     * Funcion para obtener los puntos de venta online
     */
    public getPuntosVentaOnline(): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPONLINE;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('availables', 'true');
        params = params.append('filter=channel_id', '1');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los puntos de venta online por codigo ppvv
     */
     public getPuntosVentaOnlineByCode(ppvv: string): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPONLINE;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('availables', 'true');
        params = params.append('filter=where_cd', ppvv);
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los puntos de venta online farmacias
     */
    public getPuntosVentaOnlinePharmacy(): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPONLINE;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('availables', 'true');
        params = params.append('filter=channel_id', '1');
        params = params.append('filter=shop_type_id', '1');
        return this._http.get(this._apiUrl, {params});
    }

    public getPuntosVentaPruebaPPVVLISTA(): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPONLINE;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('availables', 'true');
        params = params.append('filter=channel_id', '2');
        params = params.append('filter=where_online', 'true');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los puntos de venta mixtos farmacias
     */
    public getPuntosVentaMixtosPharmacy(): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPONLINE;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('availables', 'true');
        params = params.append('filter=channel_id', '3');
        params = params.append('filter=shop_type_id', '1');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los puntos de venta online centros de estética
     */
    public getPuntosVentaOnlineEsthetic(): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPONLINE;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('filter=channel_id', '1');
        params = params.append('filter=shop_type_id', '2');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los puntos de venta mixtos centros de estética
     */
    public getPuntosVentaMixtosEsthetic(): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPONLINE;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('filter=channel_id', '3');
        params = params.append('filter=shop_type_id', '2');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los puntos de venta online
     */
    public getPuntosVentaOnlineMixtos(): Observable<any> {
        this._apiUrl = this.url + AppSettings.SHOPONLINE;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('availables', 'true');
        params = params.append('filter=channel_id', '3');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Fucnion para obtener los enroladores por ppvv
     * @param idppvv id punto de venta
     */
    public getEnroladres(idppvv: string): Observable<any> {
        this._apiUrl = this.url + AppSettings.ENROLERS;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('whereId', idppvv);
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los contenidos de advserver publica
     */
    public getAdvserverContentPublic(): Observable<any> {
      this._apiUrl = this.url + AppSettings.ADSERVERPUBLIC;
      let params: HttpParams = new HttpParams();
      params = params.append('companyId', '4');
      params = params.append('channelId', '3');
      params = params.append('show', 'active');
      return this._http.get(this._apiUrl, {params});
    }

    public getRatioNaos(): Observable<any> {
        this._apiUrl = this.url + AppSettings.RATIONAOS + '4/parameters';
        let params: HttpParams = new HttpParams();
        params = params.append('parameterSd', 'FROM_STARS_TO_EUROS');
        params = params.append('parameterType', 'FROM_STARS_TO_EUROS');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los contenidos del advserver privados
     * @param idclient idcliente
     */
    public getAdvserverContentPrivate(idclient: any): Observable<any> {
      this._apiUrl = this.url + AppSettings.ADVSERVERPRIVATE + idclient;
      let params: HttpParams = new HttpParams();
      params = params.append('channelId', '3');
      params = params.append('show', 'active');
      return this._http.get(this._apiUrl, {params});
    }

    /**
     * Servicio para obtener los banners de la home
     */
    public getBanners(): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETBANNERS;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para seleccionar el elemento de la info del advserver por nombre del contenido
     * @param name nombre de la info que queremos obtener
     * @param infoadv info del advserver
     */
    public getOfferAdvByNameContent(name: string, infoadv: Array<any>): any {
        let content: any = null;

        if (!infoadv){
            return [];
        }

        for (let item of infoadv) {
        if (item.content.location.sd.es === name) {
            content = item;
        }
        }
        return content;
    }

    /**
     * Funcion para devolver un array con los elementos del advserver que queramos seleccionar
     * @param name nombre del content
     * @param infoadv Array de advserver
     */
    public getOfferAdvByNameContentPlus(name: string, infoadv: Array<any>): any {
      let content: Array<any> = [];
      if (infoadv !== null) {
        for (let item of infoadv) {
            if (item.content.location.sd.es === name) {
              content.push(item);
            }
          }
            return content.sort(function (a, b) {
              if (a.content.locationPriority > b.content.locationPriority) {
                return 1;
              }
              if (a.content.locationPriority < b.content.locationPriority) {
                return -1;
              }
              return 0;
            });;
      } else {
          return null;
      }

    }

    /**
     * Funcion para obtener las promociones del advserver
     * @param name nombre del content que queremos seleccionar
     * @param infoadv Array con los datos del advserver
     */
    public getOfferAdvByNameContentPlusPromotions(name: string, infoadv: Array<any>): any {
      let content: Array<any> = [];
      if (infoadv !== null) {
        for (let item of infoadv) {
            if (item.content.location.sd.es === name) {
              content.push(item);
            }
          }
          for (let promo of content) {
            if (promo.content.data.es !== '') {
              let auxjson = JSON.parse(promo.content.data.es);
              if (typeof promo.content.data.es === 'string' && auxjson.boton == undefined) {
                promo.content.data.es = '';
              } else {
                promo.content.data.es = JSON.parse(promo.content.data.es);
                if (promo.content.data.es.tooltip) {

                } else {
                  promo.content.data.es.tooltip = '';
                }
              }
            } else {
              promo.content.data.es = '';
            }
          }
            return content.sort(function (a, b) {
              if (parseInt(a.content.locationPriority) > parseInt(b.content.locationPriority)) {
                return 1;
              }
              if (parseInt(a.content.locationPriority) < parseInt(b.content.locationPriority)) {
                return -1;
              }
              return 0;
            });;
      } else {
          return null;
      }

    }

    /**
     * Funcion para optener la promocion del advserver para mostrar en landingpromotion
     * @param idpromotion id de la promocion a mostrar
     * @param infoadv informacion de advserver
     */
    public getLandingPromotion(idpromotion: any, infoadv: Array<any>): any {
      let promotion: any = null;
      if (infoadv !== null) {
        for (let promo of infoadv) {
          if (promo.content.externalId === idpromotion) {
            promotion = promo;
            if (promotion.content.data.es !== '') {
              let auxjson = JSON.parse(promotion.content.data.es);
              if (typeof promotion.content.data.es === 'string') {
                promotion.content.data.es = auxjson;
              } else {
                promotion.content.data.es = JSON.parse(promotion.content.data.es);
                if (promotion.content.data.es.tooltip) {

                } else {
                  promotion.content.data.es.tooltip = '';
                }
              }
            } else {
              promotion.content.data.es = '';
            }
          }
        }
      }
      return promotion;
    }

    /**
     * Funcion para seleccionar el elemento de la info del advserver por nombre
     * @param name nombre de la info que queremos obtener
     * @param infoadv info del advserver
     */
    public getOfferAdvByName(name: string, infoadv: Array<any>): any {
      let offer: any = null;
      if (infoadv !== null) {
        for (let item of infoadv) {
          if (item.content.location.sd.es === name) {
            offer = item;
            /* offer.offers[0].accomplishment_progress = 10; */
            offer.offers[0].max_accomplishment_quantity = parseInt(offer.offers[0].max_accomplishment_quantity);
          }
        }
        return offer;
      } else {
        return null;
      }
    }

    /**
     * Funcion para formatear el objeto de las rutas para las migas de pan
     * @param name nombre de la ruta
     */
    public formatObjBreadcrumbs(name: string): any {
        let obj = {};
        if (name.indexOf('detail?') > -1) name = 'Detalle';
        if (isNaN(parseInt(name))) {
            switch (name) {
                case 'home':
                    obj = {
                        name: 'Home',
                        url: '/home'
                    };
                    break;
                case 'store':
                    obj = {
                        name: 'CanjeaTusEstrellas',
                        url: '/home/store'
                    };
                    break;
                case 'exchange':
                    obj = {
                        name: 'ListadoCanjes',
                        url: '/home/store/exchanges'
                    };
                    break;
                case 'main':
                    obj = {
                        name: 'Principal',
                        url: '/main'
                    };
                    break;
                case 'profilepage':
                    obj = {
                        name: 'Perfil',
                        url: '/home/profilepage'
                    };
                    break;
                case 'catalogo':
                    obj = {
                        name: 'Catalogo',
                        url: '/home/store/catalogo'
                    };
                    break;
                case 'myskin':
                    obj = {
                        name: 'MiPiel',
                        url: '/home/myskin'
                    };
                    break;
                case 'bag':
                    obj = {
                        name: 'BolsaCompra',
                        url: '/home/store/bag'
                    };
                    break;
                case 'skintest':
                    obj = {
                        name: 'TestPiel',
                        url: '/home/skintest'
                    };
                    break;
                case 'detail':
                    obj = {
                        name: 'Detalle',
                        url: '/home/store/detail'
                    };
                    break;
                case 'transactions':
                    obj = {
                        name: 'MisCompras',
                        url: '/home/transactions'
                    };
                    break;
                case 'buy':
                    obj = {
                        name: 'Transacción',
                        url: '/home/transactions/buy'
                    };
                    break;
                case 'history':
                    obj = {
                        name: 'Histórico',
                        url: '/home/transactions/history'
                    };
                    break;
                case 'mapnaos':
                    obj = {
                        name: 'Mapa',
                        url: '/home/store/mapnaos'
                    };
                    break;
                case 'leavecomers':
                    obj = {
                        name: 'Baja',
                        url: '/leavecomers'
                    };
                    break;
                case 'contact':
                        obj = {
                            name: 'Contacto',
                            url: '/home/contact'
                        };
                    break;
            }
        }
        return obj;
    }

    /**
     * Funcion para obtener la fecha actual
     */
    public getDate(): string {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        let hh = String((today.getHours() < 10 ? '0' : '') + today.getHours());
        let min = String((today.getMinutes() < 10 ? '0' : '') + today.getMinutes());
        let ss = String((today.getSeconds() < 10 ? '0' : '') + today.getSeconds());
        let mil = String('00');
        return yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + min + ':' + ss + '.' + mil + 'Z';
    }

    /**
     * Funcion que devuelve la fecha sola en formato corto
     */
    public getDateShort(): string {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        return yyyy + '-'  + mm + '-' + dd;
    }

    /**
     * Funcion que devuelve la fecha sola en formato corto formato dd/MM/yyyy
     */
    public getDateShortyyyyMMdd(): string {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    }

    /**
     * Funcion para navegar hacia atras
     */
    public returnNavigationPageBack(): void {
      if (sessionStorage.getItem('ILCK')) {
        this._router.navigate(['/home'])
      } else {
        window.history.back();
      }
    }

    /**
     * Funcion para navegar hacia atras sin salir de la página para la página de canjes de código
     */
    public returnNavigationPageBackLandingPromotion(): void {
      if (sessionStorage.getItem('ILCK')) {
        this._router.navigate(['/home']);
      } else {
        this._router.navigate(['/main']);
      }
    }

    /**
     * Funcion para obtener la fecha de hace 6 meses
     */
    public getSixMonthLater(): string {
        let today = new Date();
        today.setMonth(today.getMonth() - 6);
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    }

    /**
     * Funcion para obtener la fecha de hace 3 meses
     */
    public getThreeMonthLater(): string {
        let today = new Date();
        today.setMonth(today.getMonth() - 3);
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    }

    public convertDateUTCToLocalDate(date: any): string {
        return date + ' UTC';
    }

    /**
     * Funcion para obtener la fecha limite a consultar en el historico
     */
    public getLimitDateHistory(): any {
        let today = new Date();
        today.setMonth(today.getMonth() - 12);
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    }

    /**
     * Funcion que devuelve el año actual
     */
    public getYear(): string {
        let today = new Date();
        let yyyy = today.getFullYear();
        return yyyy.toString();
    }

    /**
     * Funcion para formatear la fecha
     * @param date fecha para formatear
     */
    public formatDate(date: any): string {
        return date[8] + date[9] + '/' + date[5] + date[6] + '/' + date[0] + date[1] + date[2] + date[3]
    }

    /**
     * Eliminar signos diacriticos
     * @param texto texto a modificar
     */
    public eliminarDiacriticosEs(texto) {
        return unorm.nfc(texto)
               .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1");
    }

    public removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    /**
     * Funcion para formatear fechas timestamp
     */
    public setFormatTimestampDate(date: any): string {
        let dateformat: any;
        if (date !== '0' && date !== null && date !== undefined) {
            let a = new Date(date * 1000);
            let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
            let year = a.getFullYear();
            let month = months[a.getMonth()];
            let dateAux = a.getDate();
            let hours = a.getHours();
            let minutes = a.getMinutes();
            let seconds = a.getSeconds();
            let time: string = dateAux + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
            dateformat = time.toString();
        } else {
            dateformat = '';
        }
        return dateformat;
    }

    public setFormatTimestampDate2(date: any): string {
        let dateformat: string;
        if (date !== '0' && date !== null && date !== undefined) {
            let dateaux = new Date(date).toLocaleDateString("en-GB");
            let aux = dateaux.replace('/','-').replace('/','-');
            let arrayFormatDate = aux.split('-');
            dateformat = arrayFormatDate[2][0] + arrayFormatDate[2][1] + arrayFormatDate[2][2] + arrayFormatDate[2][3] + '-' + arrayFormatDate[1][0] + arrayFormatDate[1][1] + '-' + arrayFormatDate[0][0] + arrayFormatDate[0][1];
        } else {
            dateformat = '';
        }
        return dateformat;
    }

    // Metodo para pasar las fechas YYYY-MM-DD a milisegundos
    public toMsYYYYMMDD(dateStr) {
      // desarmamos el string por los '-' los descartamos y lo transformamos en un array
      let parts = dateStr.split("-")
      // parts[2] es dia
      // parts[1] el mes
      // parts[0] el año
      return new Date(parts[0], parts[1] - 1, parts[2]).getTime()
    }

    // Metodo para pasar las fechas DD-MM-YYYY a milisegundos
    public toMsDDMMYYYY(dateStr) {
      // desarmamos el string por los '-' los descartamos y lo transformamos en un array
      let parts = dateStr.split("-");
      // parts[2] es año
      // parts[1] el mes
      // parts[0] el día
      return new Date(parts[2], parts[1] - 1, parts[0]).getTime();
    }

    //Metodo para sacar la fecha de hoy formato dd-mm-yyyy
    public getTodayDDMMYYYY(): any {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();
      let todayFormat: any = dd + '-' + mm + '-' + yyyy;
      return todayFormat;
    }

    // Metodo para sacar la fecha de hace un mes dd-mm-yyyy
    public getTodayNextMonthDDMMYYYY(): any {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 2).padStart(2, '0');
      var yyyy = today.getFullYear();
      let todayFormat: any = dd + '-' + mm + '-' + yyyy;
      return todayFormat;
    }

    // Metodo para añadir la marca a los textos de los alts para las imagenes de la web
    public setBrandToTextAlt(textoDescription: string): string {
      let brandPage: string = 'Bioderma';
      let urlPage: any = window.location.href;
      if (urlPage.indexOf('bioderma') > -1) {
        brandPage = 'Bioderma';
      } else if (urlPage.indexOf('esthederm') > -1) {
        brandPage = 'Esthederm';
      }
      return textoDescription + ' ' + brandPage;
    }

    /**
     * Funcion para redireccionar según el valor que llegue en el link
     * @param redirect nombre de la página a redireccionar
     */
    public redirectLinks(redirect: string, parameter1 ?: any, parameter2 ?: any): void {
        switch (redirect) {
            case 'login':
                this._router.navigate(['/login']);
                break;
            case 'register':
                this._router.navigate(['/register']);
                break;
            case 'mapappvvnaos':
                this._router.navigate(['/mapappvv']);
                break;
            case 'rememberpassword':
                this._router.navigate(['/rememberpassword']);
                break;
            case 'activation':
                this._router.navigate(['/activacion']);
                break;
            case 'unsubscribe':
                this._router.navigate(['/unsubscribe'], { queryParams: {parameter1: parameter1, parameter2: parameter2}});
                break;
            case 'home':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home']);
                }
                break;
            case 'myprofile':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/profilepage']);
                }
                break;
            case 'landingpromotions':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                  this._router.navigate(['/login']);
                } else {
                  this._router.navigate(['/home/landingpromotions']);
                }
                break;
            case 'datadirection':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/profilepage/datadirection']);
                }
                break;
            case 'dataother':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/profilepage/dataothers']);
                }
                break;
            case 'testskin':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/skintest']);
                }
                break;
            case 'clubperks':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/clubperks']);
                }
                break;
            case 'exchangestars':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/exchangestars']);
                }
                break;
            case 'promotions':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/promotions']);
                }
                break;
            case 'myskin':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/myskin']);
                }
                break;
            case 'buy':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/transactions']);
                }
                break;
            case 'history':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/transactions/history']);
                }
                break;
            case 'exchanges':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/store']);
                }
                break;
            case 'historyexchanges':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/store/exchange']);
                }
                break;
            case 'contact':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/home/contact']);
                }
                break;
            case 'privacity':
                this._router.navigate(['/privacity']);
                break;
            case 'privacitynew':
                this._router.navigate(['/privacitynew']);
              break;
            case 'privacitysecond':
                this._router.navigate(['/privacitysecond']);
                break;
            case 'privacitynewsecond':
                this._router.navigate(['/privacitynewsecond']);
              break;
            case 'legals':
                this._router.navigate(['/legals']);
                break;
            case 'sitesnoauthorization':
                this._router.navigate(['/sitesnoauthorization']);
                break;
            case 'leavecomers':
                if (!sessionStorage.getItem('ILCK') && !sessionStorage.getItem('NKT')) {
                    this._router.navigate(['/login']);
                } else {
                    this._router.navigate(['/leavecomers']);
                }
                break;
        }
    }


    public encryptData(data: any): any {
        if (data !== null && data !== undefined) {
            var dataEncrypt: any;
            dataEncrypt = CryptoJS.AES.encrypt(data.toString(), environment.KEYCRIPT).toString();
            return dataEncrypt;
        }
    }

    public desencryptData(data: any): any {
        if (data !== null && data !== undefined) {
            var dataDesencrypt: any;
            var bytes  = CryptoJS.AES.decrypt(data, environment.KEYCRIPT);
            dataDesencrypt = bytes.toString(CryptoJS.enc.Utf8);
            return dataDesencrypt;
        }
    }

}
