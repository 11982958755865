import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class MaposmService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;

    constructor(
        private _http: HttpClient,
        private router: Router
    ) {

    }

    /**
     * Funcion para obtener las coordenadas de una direccion
     * @param address Direccion ha buscar coordenadas
     */
    public sendPlace(address: string): Observable<any> {
        /* this._apiUrl = AppSettings.RUTECOORDS2; */
        this._apiUrl = 'https://nominatim.openstreetmap.org/?format=json&addressdetails=1&q=alberto%20aguilera%207,%20Madrid&limit=1';
        /* let params: HttpParams = new HttpParams();
        params = params.append('format', 'json');
        params = params.append('addressdetails', '1');
        params = params.append('q', address);
        params = params.append('limit', '1'); */
        return this._http.get(this._apiUrl);
    }
}
