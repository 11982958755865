<!-- links paths -->
<!-- <div class="row">
    <div class="col-md-12" style="padding-left: 5%;margin-top: 2%">
        <ng-container *ngFor="let item of this.breadcrumbs">
            <a class="linkBreadcrumbs" [routerLink]="[item.url]">{{item.name}} /</a>
        </ng-container>
    </div>
</div> -->

<app-navbar></app-navbar>

<router-outlet></router-outlet>

<app-footer></app-footer>
