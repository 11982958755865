import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';

@Component({
    selector: 'app-privacity',
    templateUrl: './privacity.component.html',
    styleUrls: ['./privacity.component.css'],
    providers: [NAOSService]
})
export class PrivacityComponent implements OnInit {

    constructor(
        private _router: Router,
        private _naosService: NAOSService
    ) { }

    ngOnInit(): void {

    }

    public goToSecond(): void {
        this._router.navigate(['/privacitysecond']);
    }

    /**
     * Funcion para volver atras
     */
    public goToAfter(): void {
        this._naosService.returnNavigationPageBack();
    }
}
