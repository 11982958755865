import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $ : any;

@Component({
  selector: 'app-footer-bioderma',
  templateUrl: './footer-bioderma.component.html',
  styleUrls: ['./footer-bioderma.component.css']
})
export class FooterBiodermaComponent implements OnInit {

  constructor(
    private _router: Router
  ) { }

  ngOnInit(): void { }

  public scrolltomove(): void {
    setTimeout(()=> {
      window.scrollTo(0,document.body.scrollHeight);
    },250);

  }

  public goToConfigurationCookies(): void {
    $('#tarteaucitronManager').trigger('click');
  }
}
