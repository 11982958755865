<div class="container " *ngIf="!this.loader">
    <div class="row loaderMap">
        <div class="col-md-12">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
</div>

<div class="container mw-100 w-100 px-5 mobilePadding" *ngIf="this.loader">
    <!-- Bloque de busqueda -->
    <div class="row px-4">
        <div class="col-md-12">
            <p class="titleMap">SELECCIONA EL LUGAR DONDE QUIERES RECOGER TU PRODUCTO</p>
        </div>
    </div>
    <div class="row px-4 my-4">
        <div class="col-md-9 col-xs-8">
            <div class="form-group row">
                <label for="inputDirection" class="col-sm-3 col-form-label titleMap">Dirección </label>
                <div class="col-sm-8">
                    <input type="text" (keyup.enter)="this.setDirectionMap()" [(ngModel)]="this.direction" class="form-control" id="inputDirection" placeholder="Dirección, Localidad">
                </div>
            </div>
        </div>
        <div class="col-md-2 col-xs-6 col-sm-6 text-center px-0">
            <button class="btn btnNaosProduct" (click)="this.setDirectionMap()">
                Buscar
            </button>
        </div>
        <div class="col-md-1 col-xs-6 col-sm-6 text-center px-0">
            <button class="btn btnNaosProductMap" (click)="this.getCoordsCurrentPositionButton()" title="Posición actual">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </button>
        </div>
    </div>

     <!-- Bloque busqueda -->
     <div class="row px-4" *ngIf="this.directionClient.length > 1">
        <div class="col-md-12 px-0" >
            <p class="titleMap">SELECCIONA UN RESULTADO PARA REFRESCAR EL MAPA:</p>
        </div>
    </div>
    <ng-container *ngIf="this.directionClient.length > 1">
        <div class="row rowDirectionClient alert-info mx-2" *ngFor="let item of this.directionClient;let i = index" (click)="this.selectDirectionClient(item, i)" [id]="'directionClient'+i">
            <div class="col-md-12 px-0">
                {{ item.formatted_address }}
            </div>
        </div>
    </ng-container>

     <!-- Bloque mapa -->
    <div class="row px-4" *ngIf="!this.directionClientNotFound">
        <div class="col-md-12 px-0">
            <div class="alert alert-info">
                No hemos encontrado la dirección que has introducido, por favor, introduce una dirección válida. Ej: Calle Gran Via, 9
            </div>
        </div>
    </div>
    <div class="row px-4 rowMap mb-5">
        <div class="col-md-12 mapaOpen px-0">
            <google-map [center]="this.center" [zoom]="this.zoom" height="600px" width="100%">
                <ng-container *ngIf="this.sitesNear.length > 0">
                    <map-marker *ngFor="let item of this.sitesNear" [position]="item.pointMap" [icon]="this.markericon" (mapClick)="openMarkerInMap(marker, item)" #marker="mapMarker">
                        <map-info-window #infoWindow>
                            <div class="container containerInfoTooltiop mw-100 w-100" *ngIf="this.selectedInfo !== undefined">
                                    <div class="row" style="text-align: center">
                                            <div class="col-md-12">
                                                <img class="tooltipMarkerLogo" src="../../../assets/images/LOGO-NaosClub@1x.png"/>
                                            </div>
                                        </div><br>
                                        <div class="row">
                                            <div class="col-md-12">
                                                {{this.selectedInfo?.jwhere.where_ld}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12" *ngIf="this.selectedInfo?.where_address.length > 0">
                                                    <b>{{this.selectedInfo?.where_address[0].jwhereAddress.street_address_type}} {{this.selectedInfo?.where_address[0].jwhereAddress.street_address_vld}} {{this.selectedInfo?.where_address[0].jwhereAddress.number}}, {{this.selectedInfo?.where_address[0].jwhereAddress.postal_cd}}, {{this.checkLocalityProvince(this.selectedInfo?.where_address[0].jwhereAddress.municipality,item.where_address[0].jwhereAddress.province)}}</b>
                                            </div>
                                            <div class="col-md-12" *ngIf="this.selectedInfo?.where_address.length == 0"></div>
                                        </div><br>
                                        <div class="row" style="text-align: center">
                                            <div class="col-md-12">
                                                <button class="btn btnNaosProduct2" (click)="this.sendExchangeProduct(item)">
                                                    Seleccionar
                                                </button>
                                            </div>
                                        </div><br>
                            </div>
                        </map-info-window>
                    </map-marker>
                </ng-container>
                <ng-container *ngIf="this.sitesNear.length == 0">
                    <map-marker [position]="this.center"> </map-marker>
                </ng-container>
            </google-map>
        </div>
    </div>

    <!-- Bloque Tabla puntos de recogida -->
    <div class="row px-4">
        <div class="col-md-12 titleMap">
            <h4>Puntos de recogida cercanos:</h4>
        </div>
    </div>

    <div class="row loaderMap px-4" *ngIf="this.loaderSitesNear">
        <div class="col-md-12 px-0">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
    <div *ngIf="this.hasSearch">
        <div class="row px-4 my-3" *ngIf="this.sitesNear.length == 0 && !this.loaderSitesNear">
            <div class="col-md-12">
                <div class="alert alert-info">
                    Lo sentimos, no tenemos puntos de venta cercanos a la dirección indicada.
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="this.sitesNear.length > 0 && !this.loaderSitesNear">
        <div class="row rowDirectionNear px-4" *ngFor="let item of this.sitesNear">
            <div class="col-md-9" *ngIf="item.where_address.length > 0">
                <span class="iconDirection"><i class="fa fa-map-marker" aria-hidden="true"></i></span>&nbsp; {{item.jwhere.where_ld}}, {{item.where_address[0].jwhereAddress.street_address_type}} {{item.where_address[0].jwhereAddress.street_address_vld}} {{item.where_address[0].jwhereAddress.number}}, {{item.where_address[0].jwhereAddress.postal_cd}}, {{this.checkLocalityProvince(item.where_address[0].jwhereAddress.address_locality_vld,item.where_address[0].jwhereAddress.province)}}.
            </div>
            <div class="col-md-9" *ngIf="item.where_address.length == 0">
                
            </div>
            <div class="col-md-3">
                <button class="btn btnNaosProduct2" [disabled]="item.where_address.length == 0" (click)="this.sendExchangeProduct(item)">
                    Seleccionar
                </button>
            </div>
        </div>
    </ng-container>
    <br>
</div>

<!-- Modal -->
<div class="modal fade" id="modalCanjeProductos" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

            <div class="px-4">
                <ng-container *ngIf="this.loaderConfirm">
                    <div class="row loaderMap">
                        <div class="col-md-12">
                            <img src="../../../assets/images/loader.gif" />
                        </div>
                    </div><br><br>
                </ng-container>
                <ng-container *ngIf="!this.loaderConfirm">
                    <h5 class="blue-text text-center my-2">Vas a realizar tu pedido, confírmanos los datos:</h5>
                    <br>

                    <h5 class="text-center my-4">Productos</h5>
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-responsive" style="text-align: right;">
                                <tbody>
                                    <tr class="tabledesign" *ngFor="let item of this.bag">
                                        <td class="text-center">{{item.quantity}}</td>
                                        <td class="text-left">{{item.jwhat.item_sd}}  {{item.jwhat.attribute_desc.es}}</td>
                                        <td class="text-right">{{this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) | number:'1.0-3':'de'}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="text-total">Total de Estrellas</td>
                                        <td class="total-result">
                                            <span class="pr-2">{{this.scoreTotal | number:'1.0-3':'de'}}</span>
                                            <!-- <img src="../../../assets/images/Group.png" /> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br>

                    <div class="row mt-5 mb-4">
                        <div class="col-md-12 mr-2">
                            <h5 class="text-center">Dirección de recogida</h5>
                        </div>
                    </div>

                    <ng-container *ngIf="this.siteSelected !== undefined">
                        <ng-container *ngIf="this.siteSelected.jwhere.channel_id === 3">
                            <div class="row text-body">
                                    <div class="col-md-12 row justify-content-center">
                                        Centro: {{this.siteSelected.jwhere.where_ld}}
                                    </div>
                            </div>
                            <div class="row text-body">
                                <div class="col-md-12  row justify-content-center">
                                    Dirección: {{this.siteSelected.where_address[0].jwhereAddress.normalizedDeyde.nombreVia}}, {{this.siteSelected.where_address[0].jwhereAddress.normalizedDeyde.numeroVia}},
                                </div>
                            </div>
                            <div class="row text-body">
                                <div class="col-md-12 row justify-content-center">
                                        {{this.siteSelected.where_address[0].jwhereAddress.normalizedDeyde.codigoPostal}}, {{this.checkLocalityProvince(this.siteSelected.where_address[0].jwhereAddress.normalizedDeyde.poblacion, this.siteSelected.where_address[0].jwhereAddress.normalizedDeyde.provincia)}}
                                </div>
                            </div>
                            <div class="row text-body">
                                <div class="col-md-12 row justify-content-center">
                                    Persona de contacto: {{this.siteSelected.jwhere.contact_person}}
                                </div>
                            </div>
                            <div class="row text-body">
                                <div class="col-md-12 row justify-content-center">
                                    Nº Telf: {{this.siteSelected.jwhere.contact_telephone_sd}}
                                </div>
                            </div>
                            <div class="row text-body" *ngIf="this.siteSelected.jwhere.url !== ''">
                                <div class="col-md-12 row justify-content-center">
                                    <div class="col-md-10">
                                        <p class="cursor-pointer text-right">¿Cómo canjear el producto en la web?</p>
                                        <!-- <p id="colapsetext" [innerHTML]="this.centroRecogida.jwhere.url"></p> -->
                                    </div>
                                    <div class="col-md-1">
                                        <ng-template #popContent>
                                            <p [innerHTML]="this.siteSelected.jwhere.url"></p><br>
                                            <!-- <b>¿Cómo canjear el producto en la web?</b><br>
                                            1.- Accede a: <a href="https://www.dosfarma.com/bioderma" target="_blank">www.dosfarma.com/bioderma</a>.<br>
                                            2.- Haz clic en el botón "añadir" del producto que quieres recibir como regalo<br>
                                            3.- Ve al carrito<br>
                                            4.- Introduce el Código del producto (ver email: empieza por NC...) en el apartado ¿Tiene un código promocional?<br>
                                            5.- Valida el código y...¡listos! Ya tienes tu producto favorito gratis<br>
                                            6.- Tramitar compra, ¡disfrútalo!<br> -->
                                        </ng-template>
                                        <i style="font-size: 16px !important" [placement]="['right', 'bottom', 'auto']" [ngbPopover]="popContent"
                                            class="fas fa-info-circle iconInfo ml-0"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="this.siteSelected.jwhere.channel_id === 2">
                            <div class="row text-body">
                                <div class="col-md-12 row justify-content-center">
                                    Centro: {{this.siteSelected.jwhere.where_ld}}
                                </div>
                            </div>
                            <div class="row text-body">
                                <div class="col-md-12 row justify-content-center">
                                    Dirección: {{this.siteSelected.where_address[0].jwhereAddress.normalizedDeyde.nombreVia}}, {{this.siteSelected.where_address[0].jwhereAddress.normalizedDeyde.numeroVia}},
                                </div>
                            </div>
                            <div class="row text-body">
                                <div class="col-md-12 row justify-content-center">
                                        {{this.siteSelected.where_address[0].jwhereAddress.normalizedDeyde.codigoPostal}}, {{this.checkLocalityProvince(this.siteSelected.where_address[0].jwhereAddress.normalizedDeyde.poblacion, this.siteSelected.where_address[0].jwhereAddress.normalizedDeyde.provincia)}}
                                </div>
                            </div>
                            <div class="row text-body">
                                <div class="col-md-12 row justify-content-center">
                                    Persona de contacto: {{this.siteSelected.jwhere.contact_person}}
                                </div>
                            </div>
                            <div class="row text-body">
                                <div class="col-md-12 row justify-content-center">
                                    Nº Telf: {{this.siteSelected.jwhere.contact_telephone_sd}}
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="this.siteSelected.jwhere.channel_id === 1">
                            <div class="row" *ngIf="this.siteSelected.jwhere.url !== ''">
                                <div class="col-md-12  row justify-content-center">
                                    <div class="col-md-10">
                                        <p class="cursor-pointer text-right">¿Cómo canjear el producto en la web?</p>
                                        <!-- <p id="colapsetext" [innerHTML]="this.centroRecogida.jwhere.url"></p> -->
                                    </div>
                                    <div class="col-md-1">
                                        <ng-template #popContent>
                                            A<p [innerHTML]="this.siteSelected.jwhere.url"></p><br>
                                            <!-- <b>¿Cómo canjear el producto en la web?</b><br>
                                            1.- Accede a: <a href="https://www.dosfarma.com/bioderma" target="_blank">www.dosfarma.com/bioderma</a>.<br>
                                            2.- Haz clic en el botón "añadir" del producto que quieres recibir como regalo<br>
                                            3.- Ve al carrito<br>
                                            4.- Introduce el Código del producto (ver email: empieza por NC...) en el apartado ¿Tiene un código promocional?<br>
                                            5.- Valida el código y...¡listos! Ya tienes tu producto favorito gratis<br>
                                            6.- Tramitar compra, ¡disfrútalo!<br> -->
                                        </ng-template>
                                        <i style="font-size: 16px !important" [placement]="['right', 'bottom', 'auto']" [ngbPopover]="popContent"
                                            class="fas fa-info-circle iconInfo ml-0"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>

            <div class="row px-4 m-2 justify-content-center mb-3 mt-5" *ngIf="this.bag.length > 0">
                <button type="button" class="col-md-5 m-2 btn btn-secondary btncerrarmodal" data-bs-dismiss="modal">Cerrar</button>
                <button (click)="this.exchangeProductsCenter()" type="button" class="col-md-5 m-2 btn btnEnviarForms" [disabled]="this.loaderConfirm">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!-- <app-naosnotification [textUrl]="this.textUrl" [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationMap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
        <div class="modal-body">
            <div class="row">
            <div class="col-md-12 filaLogo">
                <img src="../../../assets/images/LOGO-NaosClub.png" />
            </div>
            </div><br>
            <div class="row">
                <div class="col-md-12 filaLogo2" id="contenidodiv">
                {{ this.textModal }}
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
        </div>
        </div>
    </div>
</div>
