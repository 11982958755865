import { Component, OnInit } from '@angular/core';
import { BuyService } from 'src/app/services/buy.service';
import { NotifierService } from 'angular-notifier';
import { NAOSService } from 'src/app/services/naos.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
declare var jQuery: any;
declare var $: any;

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

const TYPESALLOWED: Array<any> = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
];

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.css'],
  providers: [BuyService, NAOSService],
})
export class BuyComponent implements OnInit {
  public dtTrigger: Subject<any> = new Subject();
  public selectedFile: ImageSnippet;
  public idclient: any;

  public showPreview: boolean;
  public srcPhoto: string;

  public listTickets: Array<any>;
  public loader: boolean;
  public dateIni: any;
  public dateFin: any;
  public isPdf: boolean;
  public numPages: number;
  public arrayPages: Array<any>;
  public totalElements: number;
  public urlImageTicket: string;
  public formContent: any;

  public loaderListaTickets: boolean;
  public loaderTicket: boolean;

  public textModal: string;
  public textModalMultiline: boolean;
  public textModal2: string;
  public textModal3: string;
  public textModal4: string;
  public textUrl: string;

  public contentAdvServerBll: Array<any>;
  public intervalRefresAdvserver: any;
  public contErrors: number;

  constructor(
    private _http: HttpClient,
    private _buyService: BuyService,
    private _naosService: NAOSService,
    private _router: Router
  ) {
    this.showPreview = false;
    this.srcPhoto = undefined;
    this.listTickets = [];
    this.loader = false;
    this.urlImageTicket = undefined;
    this.arrayPages = [];
    this.formContent = undefined;
    this.dateIni = this._naosService.getSixMonthLater();
    this.dateFin = this._naosService.getDateShortyyyyMMdd();
    this.isPdf = false;
    this.loaderListaTickets = false;
    this.loaderTicket = false;
    this.textModalMultiline = false;
    this.contentAdvServerBll = [];
    this.contErrors = 0;
  }

  ngOnInit(): void {
    this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
    this.getTicketsUploaded(0, 0);
    this.contentAdvServerBll = this._naosService.getOfferAdvByNameContentPlus('Mis Compras-Pie', JSON.parse(this._naosService.desencryptData(sessionStorage.getItem('ADVP'))));

    this.intervalRefresAdvserver = setInterval(() => {
      /* this.getAdvserverPrivate(); */
      this.contentAdvServerBll = this._naosService.getOfferAdvByNameContentPlus('Mis Compras-Pie', JSON.parse(this._naosService.desencryptData(sessionStorage.getItem('ADVP'))));
    }, 1000);

    this.getAdvserverPrivate();

    let intervaloError = setInterval(() => {
        if (this.contErrors >= 2) {
            this.loader = true;
            clearInterval(intervaloError);
        }
    }, 1000);

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    clearInterval(this.intervalRefresAdvserver);
  }

  public getAdvserverPrivate(): void {
      this._naosService.getAdvserverContentPrivate(this.idclient).subscribe(
        response => {
          sessionStorage.setItem('ADVP',this._naosService.encryptData(JSON.stringify(response)));
          this.contentAdvServerBll = this._naosService.getOfferAdvByNameContentPlus('Mis Compras-Pie', JSON.parse(this._naosService.desencryptData(sessionStorage.getItem('ADVP'))));
          this.contErrors++;
        },
        error => {
            console.error('Error ' + JSON.stringify(error));
            this.contErrors++;
        }
      );
  }

  public goToPromotion(): void {
    window.open('https://www.bioderma.es/sorteo-photoderm-mineral-bebe');
  }

  public goToPromotion2(): void {
    window.open('https://www.bioderma.es/sites/es/files/2021-04/BBLL_ACCION_PROMOCIONAL_BIODERMA_DISCOVERY_ESSENTIALS_KIT.pdf');
  }

  public goToPromotion3(): void {
    window.open('https://www.bioderma.es/bases-legales-reguladoras-de-la-participacion-en-el-sorteo-testing-lipogel');
  }

  public goToPromotion4(): void {
    window.open('https://terms.easypromosapp.com/t/30476');
  }

  public goToPromotion5(): void {
    window.open('https://terms.easypromosapp.com/t/30475');
  }

  public processFile(imageInput: any) {
    this.isPdf = false;
    const file: File = imageInput.files[0];
    let allowed: boolean = false;
    for (let item of TYPESALLOWED) {
      if (file.type == item) {
        allowed = true;
        if (file.type == 'application/pdf') {
          this.isPdf = true;
        }
      }
    }
    if (allowed) {
      if (this.isPdf) {
        this.isPdf = true;
        const formData = new FormData();
        formData.append('fileImage', $('#fileUpload')[0].files[0]);
        formData.append('imageName', 'probando');
        this.formContent = formData;
        this.showPreview = true;
      } else {
        const reader = new FileReader();
        reader.addEventListener('load', (event: any) => {
          this.selectedFile = new ImageSnippet(
            event.target.result,
            imageInput.files[0]
          );
          this.srcPhoto = this.selectedFile.src;
          if (this.srcPhoto !== undefined) {
            this.showPreview = true;
          }
        });
        reader.readAsDataURL(file);
      }
    } else {
      this.textModal =
        'Formato no permitido, por favor solo es posible subir una imagen o pdf.';
      this.textUrl = null;
      $('#exampleModalNotificationBuy').modal('show');
    }
  }

  public redirectPopupNotification(): void {
    if (this.textUrl !== null) {
      this._router.navigate([this.textUrl]);
    }
    this.textModalMultiline = false;
  }

  public formatBase64(base64: string): string {
    let aux = base64.indexOf('base64,');
    let stringAux = base64.substr(aux + 7, base64.length);
    return stringAux;
  }

  public formatBase64Pdf(base64: string): string {
    let aux = base64.indexOf('base64,');
    let stringAux = base64.substr(aux + 7, base64.length);
    return stringAux;
  }

  /**
   * Funcion para subir la foto del ticket
   */
  public sendTicketPhoto() {
    this.loaderTicket = true;
    if (this.isPdf) {
      $.ajax({
        async: true,
        type: 'POST',
        url: environment.urlAPI + 'user/' + this.idclient + '/image/external',
        cache: false,
        contentType: false,
        processData: false,
        enctype: 'multipart/form-data',
        data: this.formContent,
        headers: {
          Accept: 'application/json',
          token: sessionStorage.getItem('NKT'),
        },
        success: (res) => {
          this.selectedFile = undefined;
          this.formContent = undefined;
          this.textModalMultiline = true;
          $('#exampleModalTicketPhotoConfirm').modal('hide');
          this.textModal = 'Muchas gracias 😊 Has subido correctamente el ticket.';
          this.textModal2 = 'La validación del ticket y acumulación de estrellas en tu saldo puede tardar hasta 2 días laborables (ver apartado “Mis Compras”).';
          this.textModal3 = 'Dispondrás de 14 días para presentar cualquier reclamación aportando siempre tu ticket de compra.';
          this.textModal4 = '¡Gracias!';
          this.textUrl = null;
          $('#exampleModalNotificationBuy').modal('show');
          this.loaderTicket = false;
          this.getTicketsUploaded(0, 0);
        },
        error: (res) => {
          if (res.responseJSON.code == '406.45') {
            this.selectedFile = undefined;
            this.formContent = undefined;
            $('#exampleModalTicketPhotoConfirm').modal('hide');
            $('#exampleModalTicketPhotoError406').modal('show');
            this.loaderTicket = false;
          } else {
            this.selectedFile = undefined;
            this.formContent = undefined;
            $('#exampleModalTicketPhotoConfirm').modal('hide');
            this.textModal =
              'No ha sido posible subir el ticket. Inténtelo de nuevo más tarde.';
            this.textUrl = null;
            $('#exampleModalNotificationBuy').modal('show');
            this.loaderTicket = false;
            this.getTicketsUploaded(0, 0);
          }
        },
      });
    } else {
      let formData = new FormData();
      formData.append('base64', this.formatBase64(this.selectedFile.src));
      formData.append('imageName', 'ticket');
      $.ajax({
        async: true,
        type: 'POST',
        url: environment.urlAPI + 'user/' + this.idclient + '/image/external',
        cache: false,
        contentType: false,
        processData: false,
        enctype: 'multipart/form-data',
        data: formData,
        headers: {
          Accept: 'application/json',
          token: sessionStorage.getItem('NKT'),
        },
        success: (res) => {
          this.selectedFile = undefined;
          this.formContent = undefined;
          this.textModalMultiline = true;
          $('#exampleModalTicketPhotoConfirm').modal('hide');
          this.textModal = 'Muchas gracias 😊 Has subido correctamente el ticket.';
          this.textModal2 = 'La validación del ticket y acumulación de estrellas en tu saldo puede tardar hasta 2 días laborables (ver apartado “Mis Compras”).';
          this.textModal3 = 'Dispondrás de 14 días para presentar cualquier reclamación aportando siempre tu ticket de compra.';
          this.textModal4 = '¡Gracias!';
          this.textUrl = null;
          $('#exampleModalNotificationBuy').modal('show');
          this.loaderTicket = false;
          this.getTicketsUploaded(0, 0);
        },
        error: (res) => {
          if (res.responseJSON.code == '406.45') {
            this.selectedFile = undefined;
            this.formContent = undefined;
            $('#exampleModalTicketPhotoConfirm').modal('hide');
            $('#exampleModalTicketPhotoError406').modal('show');
            this.loaderTicket = false;
          } else {
            this.selectedFile = undefined;
            this.formContent = undefined;
            $('#exampleModalTicketPhotoConfirm').modal('hide');
            this.textModal =
              'No ha sido posible subir el ticket. Inténtelo de nuevo más tarde.';
            this.textUrl = null;
            $('#exampleModalNotificationBuy').modal('show');
            this.loaderTicket = false;
            this.getTicketsUploaded(0, 0);
          }
        },
      });
    }
    this.showPreview = false;
    this.loader = true;
  }

  /**
   * Función para devolver el estado del ticket
   * @param obj objeto ticket
   */
  public formatStatusTicket(obj: any): string {
    if (obj.childs !== undefined) {
      for (let x = 0; x < obj.childs.length; x++) {
        if (
          obj.childs[x].jinteraction !== undefined &&
          obj.childs[x].jinteraction.event_ticket_received == 1
        ) {
          return obj.childs[x].status.toUpperCase();
        } else {
          return obj.status.toUpperCase();
        }
      }
    } else {
      return obj.status.toUpperCase();
    }
  }

  /**
   * Funcion para quitar la foto del ticket
   */
  public deleteTicketPhoto(): void {
    this.showPreview = false;
    this.selectedFile = undefined;
    this.formContent = undefined;
  }

  // Funcion para formatear la fecha del pedido
  public formatDateExchange(date: string): string {
    let datestring: any = date.split(' ');
    return (
      datestring[0][8] +
      datestring[0][9] +
      '/' +
      datestring[0][5] +
      datestring[0][6] +
      '/' +
      datestring[0][0] +
      datestring[0][1] +
      datestring[0][2] +
      datestring[0][3]
    );
  }

  /**
   * Funcion para ver la vista previa del ticket
   * @param url dirección de la imagen del ticket
   */
  public viewPreview(url: string): void {
    this.urlImageTicket = url;
    $('#exampleModalTicketPhoto').modal('show');
  }

  /**
   * Funcion para mostrar confirmación de subir ticket
   */
  public subirTicketConfirm(): void {
    if (this.selectedFile != undefined || this.formContent !== undefined) {
      $('#exampleModalTicketPhotoConfirm').modal('show');
    } else {
      this.textModal = 'Debes adjuntar la foto de tu ticket.';
      this.textUrl = null;
      $('#exampleModalNotificationBuy').modal('show');
    }
  }

  /**
   * Funcion para obtener los tickets subidos
   */
  public getTicketsUploaded(page: any, index: any): void {
    this.loaderListaTickets = true;
    this._buyService
      .getTicketsList(this.idclient, this.dateIni, this.dateFin, page)
      .subscribe(
        (response) => {
          this.listTickets = response.content;
          /* this.loader = true; */
          this.contErrors++;
          this.loaderListaTickets = false;
          this.totalElements = response.totalPages;
          this.calcPages(index);
          this.dtTrigger.next();
        },
        (error) => {
          this.textModal = error.error.message;
          this.textUrl = null;
          $('#exampleModalNotificationBuy').modal('show');
          this.loaderListaTickets = false;
          /* this.loader = true; */
          this.contErrors++;
        }
      );
    this.loader = true;
  }

  public calcPages(index: any): void {
    this.arrayPages = [];
    for (let x = 0; x < this.totalElements; x++) {
      if (x === index) {
        this.arrayPages.push({
          index: x,
          page: true,
        });
      } else {
        this.arrayPages.push({
          index: x,
          page: false,
        });
      }
    }
  }


  public goToPrivacity(): void {
    this._router.navigate(['/privacity']);
}
}
