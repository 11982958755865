import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeModule } from './home/home.module';
import { SkinTestModule } from './skintest/skintest.module';
import { LoginModule } from './login/login.module';
import { RegisterModule } from './register/register.module';
import { TransactionsModule } from './transactions/transactions.module';
import { StoreModule } from './store/store.module';
import { ContactModule } from './contact/contact.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ProfilePageModule } from './profilepage/profilepage.module';
import { ActivationModule } from './activation/activation.module';
import { CatalogoModule } from './catalogo/catalogo.module';
import { ExchangeModule } from './exchange/exchange.module';
import { BagModule } from './bag/bag.module';
import { DetalleModule } from './catalogo/detalle/detalle.module';
import { BuyModule } from './buy/buy.module';
import { HistoryModule } from './history/history.module';
import { MySkinModule } from './myskin/myskin.module';
import { MainModule } from './main/main.module';
import { ReportModule } from './report/report.module';
import { AreaRestringedModule } from './arearestringed/arearestringed.module';
import { RememberPasswordModule } from './rememberpassword/rememberpassword.module';
import { MapNAOSModule } from './map/map.module';
import { DataClientModule } from './dataclient/dataclient.module';
import { DataDirectionModule } from './datadirection/datadirection.module';
import { DataOthersModule } from './dataothers/dataothers.module';
import { PrivacityModule } from './privacity/privacity.module';
import { LegalsModule } from './legals/legals.module';
import { ListExclusionModule } from './listexclusion/listexclusion.module';
import { PrivacitySecondModule } from './privacitysecond/privacitysecond.module';
import { LeaveComersModule } from './leavecomers/leavecomers.module';
import { PruebaTestModule } from './pruebatest/pruebatest.module';
import { PrivacityNewModule } from './privacitynew/privacitynew.module';
import { PrivacityNewSecondModule } from './privacitynewsecond/privacitynewsecond.module';
import { MapaPPVVModule } from './mapappvv/mapappvv.module';
import { PromotionsModule } from './promotions/promotions.module';
import { LandingPromotionsModule } from './landingpromotions/landingpromotions.module';
import { ClubperksModule } from './clubperks/clubperks.module';
import { ExchangestarsModule } from './exchangestars/exchangestars.module';
import { RefreshModule } from './refresh/refresh.module';
import { HealthModule } from './health/health.module';
import { UnsubscribeModule } from './unsubscribe/unsubscribe.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HomeModule,
        SkinTestModule,
        LoginModule,
        RegisterModule,
        TransactionsModule,
        StoreModule,
        ContactModule,
        DashboardModule,
        ProfilePageModule,
        ActivationModule,
        CatalogoModule,
        ExchangeModule,
        BagModule,
        DetalleModule,
        BuyModule,
        HistoryModule,
        MySkinModule,
        MainModule,
        ReportModule,
        AreaRestringedModule,
        RememberPasswordModule,
        MapNAOSModule,
        DataClientModule,
        DataDirectionModule,
        DataOthersModule,
        PrivacityModule,
        PrivacitySecondModule,
        PrivacityNewModule,
        PrivacityNewSecondModule,
        LegalsModule,
        ListExclusionModule,
        LeaveComersModule,
        PruebaTestModule,
        MapaPPVVModule,
        PromotionsModule,
        LandingPromotionsModule,
        ClubperksModule,
        ExchangestarsModule,
        RefreshModule,
        HealthModule,
        UnsubscribeModule
    ],
    exports: [
        HomeModule,
        SkinTestModule,
        LoginModule,
        RegisterModule,
        TransactionsModule,
        StoreModule,
        ContactModule,
        DashboardModule,
        ProfilePageModule,
        ActivationModule,
        CatalogoModule,
        ExchangeModule,
        BagModule,
        DetalleModule,
        BuyModule,
        HistoryModule,
        MySkinModule,
        MainModule,
        ReportModule,
        AreaRestringedModule,
        RememberPasswordModule,
        MapNAOSModule,
        DataClientModule,
        DataDirectionModule,
        DataOthersModule,
        PrivacityModule,
        PrivacitySecondModule,
        PrivacityNewModule,
        PrivacityNewSecondModule,
        LegalsModule,
        ListExclusionModule,
        LeaveComersModule,
        PruebaTestModule,
        MapaPPVVModule,
        PromotionsModule,
        LandingPromotionsModule,
        ClubperksModule,
        ExchangestarsModule,
        RefreshModule,
        HealthModule,
        UnsubscribeModule
    ],
    providers: [],
})
export class PagesModule {}
