<div class="container-fluid containerNaosLeave">
    <div class="row rowLeave">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <img class="imgLogo" src="../../../assets/images/LOGO-NaosClub@1x.png" />
                </div>
            </div>
            <div class="row rowparrafo1">
                <div class="col-md-12 rowparrafo">
                    <p class="rowparrafo">
                        ¿Estás segur&#64; de que no quieres seguir recibiendo <b>ofertas, novedades y promociones</b> de <b>NAOS Club</b> a través de comunicaciones electrónicas?
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                        <button class="btn btnBaja" (click)="this.leaveComers()">
                            Darme de baja
                        </button>
                        <button class="btn btnBaja" (click)="this.goToHome()">
                            Cancelar
                        </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalNotificationLeave" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 filaLogo">
                <img src="../../../assets/images/LOGO-NaosClub.png" />
            </div>
          </div><br>
          <div class="row">
              <div class="col-md-12 filaLogo2" id="contenidodiv">
                {{ this.textModal }}
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>