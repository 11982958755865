import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { CardMenuModule } from 'src/app/components/card-menu/cardmenu.module';
import { RouterModule } from '@angular/router';
import { NavbarModule } from 'src/app/components/navbar/navbar.module';
import { FooterModule } from 'src/app/components/footer/footer.module';

@NgModule({
    declarations: [HomeComponent],
    imports: [
        CommonModule,
        RouterModule,
        NavbarModule,
        FooterModule
    ],
    exports: [HomeComponent],
    providers: [],
})
export class HomeModule {}