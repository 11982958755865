import { Component, OnInit } from '@angular/core';
import { NAOSService } from 'src/app/services/naos.service';

@Component({
    selector: 'app-exclusionlist',
    templateUrl: './listexclusion.component.html',
    styleUrls: ['./listexclusion.component.css'],
    providers: [NAOSService]
})
export class ListExclusionComponent implements OnInit {


    constructor(
        private _naosService: NAOSService
    ) { }

    ngOnInit(): void {

    }

    /**
     * Funcion para volver atras
     */
    public goToAfter(): void {
        this._naosService.returnNavigationPageBack();
    }
}
