import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardMenuModule } from './card-menu/cardmenu.module';
import { MenuModule } from './menu-naos/menu.module';
import { ProfileModule } from './profile/profile.module';
import { MapModule } from './map/map.module';
import { NaosNotificationModule } from './naosnotification/naosnotification.module';
import { SelectSitesModule } from './select-sites/selectsites.module';
import { NavbarModule } from './navbar/navbar.module';
import { FooterModule } from './footer/footer.module';
import { NavbarBiodermaModule } from './navbar-bioderma/navbarbioderma.module';
import { NavbarEsthedermModule } from './navbar-esthederm/navbaresthederm.module';
import { FooterEsthedermModule } from './footer-esthederm/footer-esthederm.module';
import { FooterBiodermaModule } from './footer-bioderma/footer-bioderma.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        CardMenuModule,
        MenuModule,
        ProfileModule,
        MapModule,
        NaosNotificationModule,
        NavbarModule,
        FooterModule,
        SelectSitesModule,
        NavbarBiodermaModule,
        NavbarEsthedermModule,
        FooterEsthedermModule,
        FooterBiodermaModule
    ],
    exports: [
        CardMenuModule,
        MenuModule,
        ProfileModule,
        MapModule,
        NaosNotificationModule,
        NavbarModule,
        FooterModule,
        SelectSitesModule,
        NavbarBiodermaModule,
        NavbarEsthedermModule,
        FooterEsthedermModule,
        FooterBiodermaModule
    ],
    providers: [],
})
export class ComponentsModule {}
