import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacityNewComponent } from './privacitynew.component';

@NgModule({
  declarations: [PrivacityNewComponent],
  imports: [ CommonModule ],
  exports: [PrivacityNewComponent],
  providers: [],
})
export class PrivacityNewModule {}
