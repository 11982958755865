import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';
import { NAOSService } from './naos.service';

@Injectable()
export class TestService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _naosService: NAOSService) {
        this.url = environment.urlAPI;
    }

    /**
     * Funcion para obtener los informes de los test de Mediktor por cliente
     */
    public getTestIA(): Observable<any> {
      this._apiUrl = this.url + AppSettings.GETTESTIA;
      let params: HttpParams = new HttpParams();
      params = params.append('memberId', this._naosService.desencryptData(sessionStorage.getItem('ILCK')));
      return this._http.get( this._apiUrl, {params});
    }

    public getSomething(): Observable<any> {
        this._apiUrl = 'http://mediktor.com/cmd/services/operationName?externUserId-e956af58-ca6a-41e7-91d5-36444cb0d185';
        return this._http.get( this._apiUrl);
    }

}
