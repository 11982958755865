<app-naos-login [disabledButton]="this.disabledButton"></app-naos-login>
<div class="modal" id="modalActivation" tabindex="-1" role="dialog" style="text-align: center;">
    <div class="modal-dialog modal-dialog-centered modalActivationNAOS" role="document">
        <div class="modal-content modalActivationNAOS" style="padding: 10%">
            <div class="row">
                <div class="col-md-12">
                    <img class="imgValidation" src="../../../assets/images/LOGO-NaosClub@1x.png" />
                </div>
            </div><br>
            <div class="row lineaParrafo">
                <div class="col-md-12">
                    <p>Tu cuenta ha sido confirmada, puedes iniciar sesión con el botón "Entrar" introduciendo tu email y contraseña.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <button type="submit" (click)="this.goToLogin()" class="btn btn-register">Entrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-naosnotification (avisoText)="this.getMessageNotification($event)" [textUrl]="this.textUrl" [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationActivation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 filaLogo">
                <img src="../../../assets/images/LOGO-NaosClub.png" />
            </div>
          </div><br>
          <div class="row">
              <div class="col-md-12 filaLogo2" id="contenidodiv">
                {{ this.textModal }}
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>

<!-- <button (click)="this.goToLogin()" class="btn btn-primary">Ir al login</button> -->