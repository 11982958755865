import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
declare var $: any;

@Injectable() export class HttpConfigInterceptor implements HttpInterceptor {

  private readonly notifier: NotifierService;

    constructor(
      private _notifierService: NotifierService,
      private _router: Router
    ) {
      this.notifier = _notifierService;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const APIKEY: string = environment.API_KEY;
        if (request.url.indexOf('maps.google.com') > -1 || request.url.indexOf('external/log') > -1 || request.url.indexOf('user/members/mail-confirmation') > -1) {

        } else if (request.url.indexOf('user/login') > -1) {
          request = request.clone({ headers: request.headers.set('Content-type', 'application/json')});
          request = request.clone({ headers: request.headers.set('apiKey', APIKEY)});
        } else if (request.url.indexOf('mediktor') > -1) {
          request = request.clone({ headers: request.headers.set('Content-type', 'application/json')});
        } else {
          request = request.clone({ headers: request.headers.set('Content-type', 'application/json')});
          request = request.clone({ headers: request.headers.set('apiKey', APIKEY)});
          if (sessionStorage.getItem('NKT')) {
            request = request.clone({ headers: request.headers.set('token', sessionStorage.getItem('NKT'))});
          }
        }

        return next.handle(request)
	    .pipe(
	        tap(event => {
	          if (event instanceof HttpResponse) {
	          }
	        }, error => {
                $('#exampleModalNotification').modal('hide');
                $('#exampleModalNotificationRemember').modal('hide');
                $('#exampleModalNotificationLanding').modal('hide');
                $('#exampleModalNotificationLandingError406').modal('hide');
                $('#exampleModalNotificationLandingError401').modal('hide');
                $('#exampleModalNotificationActivation').modal('hide');
                $('#exampleModalNotificationBuy').modal('hide');
                $('#exampleModalNotificationCatalogo').modal('hide');
                $('#exampleModalNotificationDetalle').modal('hide');
                $('#exampleModalNotificationContact').modal('hide');
                $('#exampleModalNotificationDashboard').modal('hide');
                $('#exampleModalNotificationLogin').modal('hide');
                $('#exampleModalNotificationDataClient').modal('hide');
                $('#exampleModalNotificationDataDirection').modal('hide');
                $('#exampleModalNotificationDataOther').modal('hide');
                $('#exampleModalNotificationExchange').modal('hide');
                $('#exampleModalNotificationHistory').modal('hide');
                $('#exampleModalNotificationLeave').modal('hide');
                $('#exampleModalNotificationMap').modal('hide');
                $('#exampleModalNotificationMySkin').modal('hide');
                $('#exampleModalNotificationProfile').modal('hide');
                $('#exampleModalNotificationRegister').modal('hide');
                $('#exampleModalNotificationTestSkin').modal('hide');
              if (error.status == '500') {
                this._router.navigate(['/login']);
              }
              if (error.error.code === '401.16') {
                this._router.navigate(['/login']);
              }
              if (error.error.code === '403.19') {
                this._router.navigate(['/login']);
              }
	          	console.error(error.message);
	        })
	      )
    }
 }
