import { Component, OnInit } from '@angular/core';
import { NAOSService } from 'src/app/services/naos.service';

@Component({
    selector: 'app-privacitysecond',
    templateUrl: './privacitysecond.component.html',
    styleUrls: ['./privacitysecond.component.css'],
    providers: [NAOSService]
})
export class PrivacitySecondComponent implements OnInit {


    constructor(
        private _naosService: NAOSService
    ) { }

    ngOnInit(): void {
    }

    /**
     * Funcion para volver atras
     */
    public goToAfter(): void {
        this._naosService.returnNavigationPageBack();
    }
}
