import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPromotionsComponent } from './landingpromotions.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [LandingPromotionsComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
  ],
  exports: [LandingPromotionsComponent],
  providers: [],
})
export class LandingPromotionsModule {}
