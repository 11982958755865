import { Injectable } from '@angular/core';


@Injectable()
export class LogsTrackService {

    constructor(
    ) {

    }

    public setTrackView(name: string, url: string): void {

    }


}
