import { Component, OnInit } from '@angular/core';
declare var $ : any;

@Component({
  selector: 'app-navbaresthederm',
  templateUrl: './navbaresthederm.component.html',
  styleUrls: ['./navbaresthederm.component.css']
})
export class NavbarEsthedermComponent implements OnInit {
  public showsubmenu: boolean;
  public idsubmenucontent: number;
  constructor() {
    this.showsubmenu = false;
  }

  ngOnInit(): void {


  }
}
