<div class="container">
    <div class="row">
        <div class="col-md-2 cuadradoIcon"></div>
        <div class="col-md-10"></div>
    </div>
    <div class="row">
        <div class="col-md-2 cuadradoBlanco"></div>
        <div class="col-md-10"></div>
    </div>
    <div class="row">
        <div class="col-md-12 cuadradoDatos">
            <h4>Datos personales</h4>
            <div class="row rowDatosPersonales">
                <div class="col-md-12 datosPersonales">
                    <div class="row">
                        <div class="col-md-12">
                            <b>Nombre* :</b> Julia <br>
                            <b>Apellidos :</b> Medina Rubio <br><br>
                            <b>Email* :</b> juliamed@gmail.com <br>
                            <b>Contraseña :</b> ******* <br><br>
                            <b>Sexo :</b> Mujer <br>
                            <b>Fecha de nacimiento :</b> 16/04/2001 <br><br>
                            <b>C.P* :</b> 28695 <br>
                            <b>Dirección :</b> Alberto Aguilera 7<br>
                            <b>Teléfono :</b> 654789123 <br><br>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <p class="textoBaja"><a class="enlaceBaja">Darme de baja en el programa de fidelización.</a></p>
                </div>
                <div class="col-md-4 recuadroEditar">
                    <button class="btn btnEditar">
                        Editar
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row pharmacyFavorite cuadradoDatos">
        <div class="col-md-12 farmaciaFavorita">
            <p class="titleFarmacia">Mi farmacia favorita</p>
        </div>
        <div class="row" style="margin-top: 5%;width: 100%">
            <div class="col-md-12 colInputPharmacy">
                <div class="form-group">
                    <input type="text" class="form-control inputFavoritePharmacy" id="favorite_pharmacy" maxlength="5" placeholder="Introduce el código de la farmacia">
                </div>
            </div>
        </div>
    </div>

    <div class="row pharmacyFavorite cuadradoDatos">
        <div class="col-md-12 farmaciaFavorita">
            <p class="titleFarmacia">Tu saldo disponible</p>
        </div>
        <div class="row" style="margin-top: 5%;width: 100%">
            <div class="col-md-8 colInputPharmacy">
                <div class="form-group">
                    <p class="tusaldo">Tu saldo disponible :</p>
                </div>
            </div>
            <div class="col-md-4">
                <h1 class="stars">54</h1>
            </div>
        </div>
    </div>
</div>