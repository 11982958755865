import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';

@Component({
  selector: 'app-exchangestars',
  templateUrl: './exchangestars.component.html',
  styleUrls: ['./exchangestars.component.css'],
  providers: [NAOSService]
})
export class ExchangestarsComponent implements OnInit {

  public disableMode: boolean;
  public offerFree: string;
  public showOffer: boolean;
  public loader: boolean;
  public idclient: string;

  public exchangestarsContentMenu: Array<any>;
  public arrOptionsMenu: Array<any>;

  constructor(
    public _router: Router,
    private _naosService: NAOSService
  ) {
    this.showOffer = false;
    this.loader = false;
    this.exchangestarsContentMenu = [];
    this.arrOptionsMenu = [];
    this.disableMode = false;
  }

  ngOnInit(): void {
    if (localStorage.getItem('offerFree')) {
      this.offerFree = localStorage.getItem('offerFree');
      if (this.offerFree == 'true') {
          this.showOffer = true;
      }
      this.loader = true;
    } else {
        this.showOffer = false;
        this.loader = true;
    }

    this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
    this.getAdvserverPrivate();
  }

  public goToPage(page) {
    this._router.navigate([page]);
  }

  /**
     * Funcion para obtener el contenido de advserver de la parte privada
     */
   public getAdvserverPrivate(): void {
    this._naosService.getAdvserverContentPrivate(this.idclient).subscribe(
      response => {
        sessionStorage.setItem('ADVP',this._naosService.encryptData(JSON.stringify(response)));
        this.exchangestarsContentMenu = this._naosService.getOfferAdvByNameContentPlus('Canjes - Tarjeta', response);

        let arrayAux: Array<any> = [];
        for (let item of this.exchangestarsContentMenu) {
          if(item.content.data.es){
            var extraData = item.content.data.es
            if(extraData.includes('disabled') && extraData.includes('True')) {
              this.disableMode = true;
            }
          }
          let obj = {
            'name': item.content.item_ld.es,
            'path': item.content.clikLink.es,
            'urlimage': item.content.images.web.es[0].url,
            'order': item.content.locationPriority,
            'description': item.content.instructions.es.web,
            'disabled': this.disableMode
          };
          arrayAux.push(obj);
        }
        this.arrOptionsMenu = arrayAux;
      },
      error => {
          console.error('Error ' + JSON.stringify(error));
      }
    );
}

}
