import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';

@Injectable()
export class TransactionService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;

    constructor(private _http: HttpClient) {
        this.url = environment.urlAPI;
    }

    /**
     * Función para obtener el listado de canjes
     * @param idclient del que vamos a recibir el listado de compras
     */
    public getListTransactions(idclient: any, dateIni: any, dateFin: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETHISTORYTICKETS + idclient + '/purchases';
        let params: HttpParams = new HttpParams();
        params = params.append('dateFrom', dateIni);
        params = params.append('dateTo', dateFin);
        return this._http.get( this._apiUrl, { params });
    }

    /**
     * Funcion para obtener el arbol de marcas para el histórico
     */
    public getBrandsHistory(): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETBRANDS;
        return this._http.get(this._apiUrl);
    }
}


