<div class="container w-mobile-100">
    <div class="row justify-content-center" style="margin-bottom: 5%">
        <div class="col-12">
            <ng-container *ngIf="!this.loader">
                <div class="row">
                    <div class="col-md-12" style="text-align: center;">
                        <img src="../../../assets/images/loader.gif" />
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="this.loader">


              <div class="container mw-70 w-mobile-100">
                <div class="row text-center align-self-center justify-content-center">
                  <div class="card mb-3">
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img src="../../../assets/images/imgdiagnosticopiel.png" class="img-fluid rounded-start">
                      </div>
                      <div class="col-md-8">
                        <div class="card-body paddingtop">
                          <label class="title-card px-4 font-weight-bold"><h4 style="color: #637691">Skin Scanner AI</h4></label>
                          <hr>
                          <label class="text-body-card py-3 px-3">Tu rutina personalizada con un selfie. Escanea tu piel con inteligencia artificial.</label><br>
                          <button class="btn btnEnviarForms" (click)="this.goToTestIA()">Escanea tu piel</button>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row text-center align-self-center justify-content-center">
                  <div class="card mb-3">
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img src="../../../assets/images/1200x900.png" class="img-fluid rounded-start">
                      </div>
                      <div class="col-md-8">
                        <div class="card-body" style="padding-top: 15% !important">
                          <label class="title-card px-4 font-weight-bold"><h4 style="color: #637691">Tu rutina facial Skin Scanner AI personalizada</h4></label>
                          <br>
                          <!-- <button class="btn btnEnviarForms" id="botonOnBioderma" (click)="this.FbotonBioderma()" data-bs-toggle="collapse" href="#testBioderma" aria-expanded="false">Ver rutina</button> -->
                          <button class="btn btnEnviarForms" id="botonOnBioderma" (click)="this.FbotonBioderma()" data-bs-toggle="collapse" data-bs-target="#testBioderma" aria-expanded="false" aria-controls="testBioderma">Ver rutina</button>
                      </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="card card-menu mb-4 estilosprimercardsize">
                      <div class="row">
                          <div class="col-md-5">
                              <img class="card-img card-img-menu h-100" src="../../../assets/images/1200x900.png">
                          </div>
                          <div class="col-md-7">
                              <div class="card-body" style="padding-top: 15% !important">
                                  <label class="title-card px-4 font-weight-bold"><h4 style="color: #637691">Tu rutina facial Skin Scanner AI personalizada</h4></label>
                                  <br>
                                  <button class="btn btnEnviarForms" id="botonOnBioderma" (click)="this.FbotonBioderma()" data-toggle="collapse" href="#testBioderma" aria-expanded="false">Ver rutina</button>
                              </div>
                          </div>
                      </div>
                  </div> -->
              </div>
              <div class="row collapse mb-5" id="testBioderma" style="margin-bottom: 10%">
                <div class="col-12">
                  <div class="row mb-2">
                    <div class="col-4 fontcolorgrey">
                      Descripción
                    </div>
                    <div class="col-4 fontcolorgrey">
                      Fecha
                    </div>
                    <div class="col-4">

                    </div>
                  </div>
                  <div class="row mb-2" *ngFor="let itemIA of this.arrayTestIA " >
                    <div class="col-4">
                      Rutina facial
                    </div>
                    <div class="col-4">
                      {{itemIA.fecha}}
                    </div>
                    <div class="col-4">
                      <i class="fa fa-eye iconLine" (click)="this.openTestIA(itemIA)"></i>&nbsp;&nbsp;<span (click)="this.openTestIA(itemIA)" class="downloadLink cursorPointer">Ver mi rutina</span>
                    </div>
                  </div>
                </div>
              </div>
            <div class="row collapse" id="testEsthederm">
              <div class="col-12">
                <div class="row">
                  <div class="col-4">
                    Descripción
                  </div>
                  <div class="col-4">
                    Fecha
                  </div>
                  <div class="col-4">

                  </div>
                </div>
                <div class="row" *ngFor="let itemEsthederm of this.listTestEsthederm">
                  <div class="col-4">
                    {{itemEsthederm.nombretest}}
                  </div>
                  <div class="col-4">
                    {{itemEsthederm.createTime | date: 'dd/MM/yyyy'}}
                  </div>
                  <div class="col-4">
                    <a class="cursorPointer" (click)="this.downloadFile(itemEsthederm.url)">
                      <i class="fa fa-download iconLine"
                      aria-hidden="true"></i>&nbsp;&nbsp;<span
                      class="downloadLink">Descargar</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            </div>
                <div class="row collapse rowTable" id="collapseExampleEsthederm">
                    <div class="col-12">
                        <div class="row rowTable textoTitleCollapses pl-3 py-3">
                            <div class="col-12 col-md-4 col-xs-4">
                                Diagnóstico
                            </div>
                            <div class="col-12 col-md-4 col-xs-4">
                                Fecha
                            </div>
                        </div>
                        <div class="row rowTable justify-content-between" style="padding: 1%"
                            *ngFor="let itemEsthederm of this.listTestEsthederm">
                            <div class="col-12 col-md-4 titleTest">
                                {{itemEsthederm.nombretest}}
                            </div>
                            <div class="col-12 col-md-4">
                                {{itemEsthederm.createTime | date: 'dd/MM/yyyy'}}
                            </div>
                            <div class="col-12 col-md-4">
                                <a class="cursorPointer" (click)="this.downloadFile(itemEsthederm.url)"><i class="fa fa-download iconLine"
                                        aria-hidden="true"></i>&nbsp;&nbsp;<span
                                        class="downloadLink">Descargar</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>


<div class="modal fade" id="exampleModalIframe" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Rutina Facial</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body modalBodyMediktor overflow-auto" style="width:100%; height:600px;min-height: 400px; overflow: scroll;">
                <div class="row text-center mb-4">
                  <div class="col-12">
                    <img src="../../../assets/images/LOGO-NaosClub.png"/>
                  </div>
                </div>
                <div class="row text-center mb-4">
                  <div class="col-12">
                      Tu recomendación personalizada.
                  </div>
                </div>
                <div class="row text-center mb-4">
                  <div class="col-12">
                      {{this.testIADetail?.fecha}}
                  </div>
                </div>
                <div class="row text-center mb-4">
                  <div class="col-12">
                      Tipo de Piel: {{this.testIADetail?.tipo_de_piel}}
                  </div>
                </div>
                <div class="row mr-4 ml-4 p-3 border rounded">
                  <div class="col-12">
                    <div class="row mb-4 cursorPointer" *ngFor="let product of this.testIADetail?.productos" (click)="this.openUrlNewTab(product?.enlace)">
                      <div class="col-4">
                        <img [src]="product?.imagen" class="img-responsive" width="100%"/>
                      </div>
                      <div class="col-8">
                        <div class="row mb-2">
                          <div class="col-12">
                            {{product?.categoria}}
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-12">
                            <ng-container *ngIf="product?.marca == 'BIODERMA'">
                              <img class="brandproductbioderma" src="../../../assets/images/logobioderma.png"/>
                            </ng-container>
                            <ng-container *ngIf="product?.marca == 'INSTITUT ESTHEDERM'">
                              <img class="brandproductesthederm" src="../../../assets/images/logoesthederm.png"/>
                            </ng-container>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-12">
                            {{product?.nombre}}
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-12">
                              {{product?.descripcion}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col-12">
                        <button class="btn btn-secondary btnCerrarModal" (click)="this.openUrlNewTab(this.testIADetail?.url_compra)">Comprar</button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btnCerrarModal" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="exampleModalNotificationMySkin" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 filaLogo">
                        <img src="../../../assets/images/LOGO-NaosClub.png" />
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12 filaLogo2" id="contenidodiv">
                        {{ this.textModal }}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="this.redirectPopupNotification()"
                    class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
            </div>
        </div>
    </div>
</div>
