import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SkinTestComponent } from './pages/skintest/skintest.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { StoreComponent } from './pages/store/store.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CanActivateLoginRegistarGuard } from './guards/login.guard';
import { ProfilePageComponent } from './pages/profilepage/profilepage.component';
import { ActivationComponent } from './pages/activation/activation.component';
import { CatalogoComponent } from './pages/catalogo/catalogo.component';
import { ExchangeComponent } from './pages/exchange/exchange.component';
import { BagComponent } from './pages/bag/bag.component';
import { DetalleComponent } from './pages/catalogo/detalle/detalle.component';
import { BuyComponent } from './pages/buy/buy.component';
import { HistoryComponent } from './pages/history/history.component';
import { MySkinComponent } from './pages/myskin/myskin.component';
import { MainComponent } from './pages/main/main.component';
import { ReportComponent } from './pages/report/report.component';
import { AreaRestringedComponent } from './pages/arearestringed/arearestringed.component';
import { RememberPasswordComponent } from './pages/rememberpassword/rememberpassword.component';
import { MapNaosComponent } from './pages/map/map.component';
import { DataClientComponent } from './pages/dataclient/dataclient.component';
import { DataDirectionComponent } from './pages/datadirection/datadirection.component';
import { DataOthersComponent } from './pages/dataothers/dataothers.component';
import { PrivacityComponent } from './pages/privacity/privacity.component';
import { LegalsComponent } from './pages/legals/legals.component';
import { ListExclusionComponent } from './pages/listexclusion/listexclusion.component';
import { PrivacitySecondComponent } from './pages/privacitysecond/privacitysecond.component';
import { LeaveComersComponent } from './pages/leavecomers/leavecomers.component';
import { PruebaTestComponent } from './pages/pruebatest/pruebatest.component';
import { PrivacityNewComponent } from './pages/privacitynew/privacitynew.component';
import { PrivacityNewSecondComponent } from './pages/privacitynewsecond/privacitynewsecond.component';
import { MapaPPVVComponent } from './pages/mapappvv/mapappvv.component';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { LandingPromotionsComponent } from './pages/landingpromotions/landingpromotions.component';
import { ClubperksComponent } from './pages/clubperks/clubperks.component';
import { ExchangestarsComponent} from './pages/exchangestars/exchangestars.component';
import { RefreshComponent } from './pages/refresh/refresh.component';
import { HealthComponent } from './pages/health/health.component';
import { UnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main-naos',
    pathMatch: 'full'
  },
  {
    path: '*',
    redirectTo: 'main-naos'
  },
  {
    path: 'noauthorization',
    component: AreaRestringedComponent
  },
  {
    path: 'rememberpassword',
    component: RememberPasswordComponent
  },
  {
    path: 'sitesnoauthorization',
    component: ListExclusionComponent
  },
  {
    path: 'mapappvv',
    component: MapaPPVVComponent
  },
  {
    path: 'privacity',
    component: PrivacityComponent
  },
  {
    path: 'privacitysecond',
    component: PrivacitySecondComponent
  },
  {
    path: 'privacitynew',
    component: PrivacityNewComponent
  },
  {
    path: 'privacitynewsecond',
    component: PrivacityNewSecondComponent
  },
  {
    path: 'legals',
    component: LegalsComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      breadcrumb: 'Login'
    }
  },
  {
    path: 'main-naos/?:redirect/?:brand',
    component: MainComponent,
    data: {
      breadcrumb: 'Main'
    }
  },
  {
    path: 'main-naos',
    component: MainComponent,
    data: {
      breadcrumb: 'Main'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      breadcrumb: 'Registro'
    }
  },
  {
    path: 'register/:id', 
    component: RegisterComponent, 
    data: {breadcrumb: 'Registro'}
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'leavecomers',
    component: LeaveComersComponent
  },
  {
    path: 'refresh',
    component: RefreshComponent
  },
  {
    path: 'activacion',
    component: ActivationComponent,
    data: {
      breadcrumb: 'Activation'
    },
  },
  {
    path: 'unsubscribe',
    component: UnsubscribeComponent
  },
  {
    path: 'unsubscribe/:parameter1/:parameter2',
    component: UnsubscribeComponent
  },
  {
    path: 'health',
    component: HealthComponent,
    data: {
      breadcrumb: 'health'
    },
  },
  {
    path: 'home',
    component: HomeComponent,
    data: { breadcrumb: 'Home'},
    children: [
      {path: '', component: DashboardComponent, data: {breadcrumb: 'Este'}},
      {path: 'dashboard', component: DashboardComponent, data: {breadcrumb: 'Home'}},
      {path: 'clubperks', component: ClubperksComponent, data: {breadcrumb: 'Ventajas del club'}},
      {path: 'exchangestars', component: ExchangestarsComponent, data: {breadcrumb: 'Canjea tus estrellas'}},
      {path: 'contact', component: ContactComponent, data: {breadcrums: 'Contacto'}},
      {path: 'landingpromotions', component: LandingPromotionsComponent, data: {breadcrums: 'Promociones'}},
      {path: 'landingpromotions/:id', component: LandingPromotionsComponent, data: {breadcrums: 'Promociones'}},
      {path: 'promotions', component: PromotionsComponent, data: {breadcrums: 'Promociones'}},
      {path: 'skintest', component: SkinTestComponent, data: {breadcrumb: 'Test Piel'}},
      {path: 'mapa', component: MapNaosComponent, data: {breadcrumb: 'Mapa'}},
      {path: 'skinreport', component: ReportComponent, data: {breadcrumb: 'Informe Piel'}},
      {path: 'pruebatest', component: PruebaTestComponent, data: {breadcrumb: 'PruebaTest'}},
      {path: 'transactions', component: TransactionsComponent, data: {breadcrumb: 'Transacciones'}, children: [
        {path: '', component: BuyComponent, data: {breadcrumb: 'Transacción'}},
        {path: 'buy', component: BuyComponent, data: {breadcrumb: 'Transacción'}},
        {path: 'history', component: HistoryComponent, data: {breadcrumb: 'Histórico'}}
      ]},
      {path: 'store', component: StoreComponent, data: {breadcrumb: 'Store'}, children: [
        {path: '', component: CatalogoComponent, data: {breadcrumb: 'Catálogo'}},
        {path: 'catalogo', component: CatalogoComponent, data: {breadcrumb: 'Catálogo'}},
        {path: 'exchange', component: ExchangeComponent, data: {breadcrumb: 'Canjes'}},
        {path: 'contact/:id/:id2', component: ContactComponent, data: {breadcrumb: 'Contacto'}},
        {path: 'bag', component: BagComponent, data: {breadcrumb: 'Bolsa'}},
        {path: 'detail/:id', component: DetalleComponent, data: {breadcrumb: 'Detalle'}},
        {path: 'mapnaos/:id/:id2', component: MapNaosComponent, data: {breadcrumb: 'Mapa'}}
      ]},
      {path: 'myskin', component: MySkinComponent, data: {breadcrumb: 'Mi Piel'}},
      {path: 'contact', component: ContactComponent, data: {breadcrumb: 'Contacto'}},
      {path: 'profilepage', component: ProfilePageComponent, data: {breadcrumb: 'Perfil'}, children: [
        {path: '', component: DataClientComponent, data: {breadcrumb: 'Datos Cliente'}},
        {path: 'dataclient', component: DataClientComponent, data: {breadcrumb: 'Datos Cliente'}},
        {path: 'datadirection', component: DataDirectionComponent, data: {breadcrumb: 'Datos Direcciones'}},
        {path: 'dataothers', component: DataOthersComponent, data: {breadcrumb: 'Datos adicionales'}}
      ]}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
