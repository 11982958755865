<div class="container mw-80 mt-3 w-mobile-100 mw-mobile-100">
  <div class="row" *ngIf="!this.loader">
      <div class="col-12 text-center">
          <img src="../../../assets/images/loader.gif" />
      </div>
  </div>
</div>

<div *ngIf="this.loader" class="container-lg mw-60 mt-3 mt-sm-5 w-mobile-100 mw-mobile-100 boardContact">

  <div class="row justify-content-around mb-5 mx-5 px-5 text-center">
      <div class="col-sm-8 col-md-5 col-lg-5 nav-contact-option p-2" (click)="this.clickOpt(1)" id="optcontact1">
          <label class="mb-0 mt-2 cursor-pointer">Contáctanos</label>
      </div>
      <div class="col-sm-8 col-md-5 col-lg-5 nav-contact-option p-2" (click)="this.clickOpt(2)" id="optcontact2">
          <label class="mb-0 mt-2 cursor-pointer">Preguntas frecuentes</label>
      </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <ng-container *ngIf="this.loader">
        <div class="container">

          <div *ngIf="this.page == 'contact'" class="contactpage">
            <div class="row">
              <div class="col-md-6">
                  <div class="row form-group">
                      <label for="type" class="col-sm-2 col-form-label">Email</label>
                      <input type="email" class="col-sm-10 form-control inputRegister" id="email" [(ngModel)]="this.email">
                  </div>
              </div>
              <div class="col-md-6">
                <div class="row form-group">
                  <label for="type" class="col-sm-2 col-form-label">Tipo</label>
                  <select class="col-sm-10 form-control input-select-naos inputRegister" id="type" [(ngModel)]="this.type" [disabled]="this.typeDisabled">
                    <option value=""> </option>
                    <option *ngFor="let item of this.typesContactFormated" [value]="item.value">{{item.type}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row form-group">
                  <label for="asunto" class="col-sm-2 col-form-label">Asunto</label>
                  <input type="text" class="col-sm-10 form-control inputRegister" id="asunto" [(ngModel)]="this.asunto">
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <textarea class="form-control text-area" (focus)="this.clearInput(this.contact)" [(ngModel)]="this.contact" rows="5" id="comment"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                  <div class="form-group text-center">
                      <button class="btn btnSendTicket" (click)="this.sendContact()">
                          Enviar
                      </button>
                  </div>
              </div>
            </div>
          </div>

          <div *ngIf="this.page == 'questions'" class="quetionspage">
            <div class="row">
              <div class="col-md-12">
                  <h4>Preguntas Frecuentes</h4>
              </div>
            </div>

            <div class="row faqs">
                <div class="col-md-12">
                    <div id="accordion">
                        <div>
                          <div id="headingOne">
                            <h5 class="mb-0">
                              <button class="btn btnFaqs text-left" data-bs-toggle="collapse" (click)="this.openCloseColllapse('collapseOne')" href="#collapseOne" role="button" aria-expanded="false" aria-controls="collapseOne">
                                ¿Acumularé estrellas por la compra de cualquier producto BIODERMA o INSTITUT ESTHEDERM?
                              </button>
                            </h5>
                          </div>

                          <div class="collapse" id="collapseOne">
                            <div class="card-body">
                                Sí, siempre que:<br>
                                <ul>
                                  <li>hayas subido la foto del ticket de compra de forma legible en el plazo máximo de 30 días desde la fecha de compra;</li>
                                  <li>el ticket corresponda a una compra de productos BIODERMA o INSTITUT ESTHEDERM* realizada en cualquier farmacia de Península, Baleares y Canarias (se excluyen los tickets de Ceuta y Melilla y Andorra); y</li>
                                  <li>el ticket corresponda a una compra realizada en cualquier punto de venta autorizado por NAOS SKIN CARE SPAIN SLU</li>
                                </ul>
                                <br>
                                Se deben cumplir todos los requisitos anteriores para que el importe de la compra realizada de productos BIODERMA e INSTITUT ESTHEDERM se convierta en estrellas.
                                <br>
                                *Los puntos de venta NAOS VIP de las Islas Canarias solo contemplarán la marca Bioderma, no se validarán los tickets de compra de los usuarios de Institut Esthederm.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingTwo">
                            <h5 class="mb-0">
                              <button class="btn btnFaqs collapsed text-left" data-bs-toggle="collapse" (click)="this.openCloseColllapse('collapseTwo')" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                ¿Cuáles son los puntos de venta excluidos de NAOS CLUB?
                              </button>
                            </h5>
                          </div>
                          <div id="collapseTwo" class="collapse">
                            <div class="card-body">
                              No todos los puntos de venta de productos de BIODERMA e INSTITUT ESTHEDERM* están adheridos al programa de fidelización NAOS CLUB. En consecuencia, si realizas una compra de cualquier producto BIODERMA e INSTITUT ESTHEDERM en un punto de venta excluido del programa de fidelización NAOS CLUB, el importe al que ascienda dicha compra no podrá ser convertido en estrellas.
                              <br>
                              Quedan excluidos del programa de fidelización NAOS CLUB y, por tanto, las compras de productos BIODERMA e INSTITUT ESTHEDERM realizadas en los mismos no se convertirán en estrellas, los puntos de venta online y/o físicos que no cumplen con la imagen de marca de NAOS (producto, precio, punto de venta y promoción).
                              <br>
                              *Los puntos de venta NAOS VIP de las Islas Canarias solo contemplarán la marca Bioderma, no se validarán los tickets de compra de los usuarios de Institut Esthederm.
                              <br>
                              Se excluyen todos los puntos de venta que no se encuentren en España. También se excluyen los puntos de venta situados en Ceuta, Melilla y Andorra.
                              <br>
                              Los puntos de venta excluidos son los siguientes: <a class="linkList" (click)="this.goToExclusionList()">puntos de venta excluidos del programa de fidelización</a>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingThree">
                            <h5 class="mb-0">
                              <button class="btn btnFaqs collapsed text-left" data-bs-toggle="collapse" (click)="this.openCloseColllapse('collapseThree')" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                ¿Cuántas estrellas recibiré por mis compras?
                              </button>
                            </h5>
                          </div>
                          <div id="collapseThree" class="collapse">
                            <div class="card-body">
                              Independientemente del producto BIODERMA o INSTITUT ESTHEDERM* comprado, por cada euro (1,00.-€) de una compra realizada en un punto de venta ordinario, acumularás una (1) estrella y por cada euro (1,00.-€) de una compra realizada en el punto de venta que hayas declarado como punto de venta de origen, acumularás dos (2) estrellas.
                              <br>
                              *Los puntos de venta NAOS VIP de las Islas Canarias solo contemplarán la marca Bioderma, no se validarán los tickets de compra de los usuarios de Institut Esthederm.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingFour">
                            <h5 class="mb-0">
                              <button class="btn btnFaqs collapsed text-left" data-bs-toggle="collapse" (click)="this.openCloseColllapse('collapseFour')" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Me da error al subir el ticket, ¿qué puedo hacer?
                              </button>
                            </h5>
                          </div>
                          <div id="collapseFour" class="collapse">
                            <div class="card-body">
                              Asegúrate de que estás subiendo la foto de tu ticket de compra en un formato válido (.gif, .png, .jpg o .pdf) y con un tamaño que no exceda los 10 MB. Lo ideal es realizar la foto del ticket sobre una superficie plana de modo que el ticket no quede arrugado, evitando claroscuros y sin que aparezcan otros elementos en la imagen.
                            </div>
                            <div class="card-body">
                              <b>¿Cuándo un ticket es válido?</b><br>
                              Son tickets válidos los siguientes: una foto del ticket de compra físico o una factura de compra de la página web. Recuerda que en las páginas web siempre puedes solicitar la factura de compra de tus pedidos.<br>
                              Recuerda que los tickets deben contener toda la información necesaria para poder realizar la validación correctamente. Por ello, es necesario que el ticket de compra contenga el CIF del punto de venta, el nombre del punto de venta, el número de ticket, la fecha de la compra, los productos NAOS comprados y su importe. Si alguna de estas variables no aparece en el ticket de compra, no será posible realizar la validación de la compra.
                              <br>
                              <b>¿Cuándo un ticket no es válido?</b><br>
                              No se consideran tickets válidos los siguientes: una captura de pantalla de una confirmación de un pedido web, un albarán, un extracto bancario, un recibo de tarjeta bancaria y fotos de los productos.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingFive">
                            <h5 class="mb-0">
                              <button class="btn btnFaqs collapsed text-left" data-bs-toggle="collapse" data-bs-target="#collapseFive" (click)="this.openCloseColllapse('collapseFive')" aria-expanded="false" aria-controls="collapseFive">
                                ¿Por qué no se me han acumulado estrellas por el total de mi compra?
                              </button>
                            </h5>
                          </div>
                          <div id="collapseFive" class="collapse">
                            <div class="card-body">
                              Recuerda que el programa de fidelización NAOS CLUB es un programa que busca fidelizar a los clientes de NAOS. En consecuencia, sólo acumularás estrellas por la compra de productos BIODERMA o INSTITUT ESTHEDERM*. Si un mismo ticket de compra incluyen productos de otras marcas, éstos no se tendrán en cuenta a la hora de acumular estrellas.
                              <br>
                              Las estrellas no se acumulan por el valor total del ticket de compra, sino por el valor de los productos BIODERMA o INSTITUT ESTHEDERM* según hayan sido comprados en un punto de venta ordinario o en el punto de venta de origen.
                              <br>
                              *Los puntos de venta NAOS VIP de las Islas Canarias solo contemplarán la marca Bioderma, no se validarán los tickets de compra de los usuarios de Institut Esthederm.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingSix">
                            <h5 class="mb-0">
                              <button class="btn btnFaqs collapsed text-left" data-bs-toggle="collapse" (click)="this.openCloseColllapse('collapseSix')" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                ¿Cuándo se van a acumular las estrellas en mi saldo?
                              </button>
                            </h5>
                          </div>
                          <div id="collapseSix" class="collapse">
                            <div class="card-body">
                              NAOS acumulará en tu saldo las estrellas correspondientes a la compra realizada productos BIODERMA o INSTITUT ESTHEDERM* en un plazo máximo de cuarenta y ocho (48) horas desde que hayas subido de forma efectiva el ticket de compra correctamente.
                              <br>
                              *Los puntos de venta NAOS VIP de las Islas Canarias solo contemplarán la marca Bioderma, no se validarán los tickets de compra de los usuarios de Institut Esthederm.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingSeven">
                            <h5 class="mb-0">
                              <button class="btn btnFaqs collapsed text-left" data-bs-toggle="collapse" (click)="this.openCloseColllapse('collapseSeven')" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                ¿Caducan las estrellas acumuladas?
                              </button>
                            </h5>
                          </div>
                          <div id="collapseSeven" class="collapse">
                            <div class="card-body">
                              Las estrellas acumuladas que no hayas canjeado por un obsequio caducan al cabo de dos (2) años desde que su fecha de acumulación.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingEleven">
                            <h5 class="mb-0">
                              <button class="btn btnFaqs collapsed text-left" data-bs-toggle="collapse" (click)="this.openCloseColllapse('collapseEleven')" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                ¿Cómo puedo conseguir el Producto Obsequio?
                              </button>
                            </h5>
                          </div>
                          <div id="collapseEleven" class="collapse">
                            <div class="card-body">
                              Para poder obtener el Producto Obsequio Sensibio H20 250 ml, tienes que subir en el apartado “Mis compras” un ticket de compra de una farmacia de productos BIODERMA y/o ESTHEDERM* de quince (15) euros como mínimo.<br>
                              Una vez tu ticket ha sido validado correctamente y se te han sido asignadas las estrellas correspondientes, en el apartado “Canjea tus estrellas” podrás solicitar la recogida de tu regalo en la farmacia que desees. Una vez solicitada, recibirás un correo con un código QR único para canjear tu producto. Deberás enseñar el código al personal de la farmacia.
                              <br>
                              *Los puntos de venta NAOS VIP de las Islas Canarias solo contemplarán la marca Bioderma, no se validarán los tickets de compra de los usuarios de Institut Esthederm.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingEigth">
                            <h5 class="mb-0">
                              <button class="btn btnFaqs collapsed text-left" data-bs-toggle="collapse" (click)="this.openCloseColllapse('collapseEigth')" data-bs-target="#collapseEigth" aria-expanded="false" aria-controls="collapseEigth">
                                ¿Cuánto tiempo tengo para poder recoger el Producto Obsequio solicitado?
                              </button>
                            </h5>
                          </div>
                          <div id="collapseEigth" class="collapse">
                            <div class="card-body">
                              Dispones de un plazo de treinta (30) días naturales desde la recepción del correo electrónico enviado por NAOS con el Código QR y el código alfanumérico para adquirir el Producto Obsequio en el punto de venta seleccionado. Recuerda que transcurrido el citado plazo de treinta (30) días sin que lo hayas recogido, perderás tu derecho a obtener el Producto Obsequio.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingNine">
                              <h5 class="mb-0">
                                <button class="btn btnFaqs collapsed text-left" data-bs-toggle="collapse" (click)="this.openCloseColllapse('collapseNine')" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                  ¿Cuándo recibiré el Producto Obsequio en mi domicilio?
                                </button>
                              </h5>
                          </div>
                          <div id="collapseNine" class="collapse">
                              <div class="card-body">
                                En caso de que no haya ningún punto de venta adherido a NAOS CLUB en un radio de tres kilómetros (3 km) en torno a tu domicilio, NAOS te enviará el Producto Obsequio a través de una empresa de mensajería a la dirección especificada como tu domicilio en el perfil personal de tu Cuenta de Usuario el en un plazo aproximado de entre diez (10) y quince (15) días naturales desde que realizaste el canje del producto*.
                                <br>
                                *Los consumidores de las Islas Canarias sólo podrán canjear en NAOS Club los productos de Bioderma y recogerlos físicamente en su farmacia (sin envío a domicilio).
                              </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingTen">
                            <h5 class="mb-0">
                              <button class="btn btnFaqs collapsed text-left" data-bs-toggle="collapse" (click)="this.openCloseColllapse('collapseTen')" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                ¿Puedo reclamar la lectura de mi ticket de compra?
                              </button>
                            </h5>
                          </div>
                          <div id="collapseTen" class="collapse">
                            <div class="card-body">
                              Sí. Si consideras que la lectura de tu ticket de compra es incorrecta, dispones de un plazo de catorce (14) días naturales desde la fecha de publicación del resultado de la lectura del ticket en la sección “Mis Compras” de tu Cuenta de Usuario para reclamar dicho resultado.
                              <br>
                              Recuerda que debes conservar siempre tus tickets de compra hasta que finalice el plazo de reclamación. Por favor, asegúrate de ocultar cualquier información sensible (nombre, medicamentos, etc.) que aparezca en el ticket.
                              <br>
                              Para reclamar la lectura de tu ticket de compra simplemente debes ponerte en contacto con NAOS Club a través del apartado “Contáctanos” de la página principal.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="headingTwelve">
                            <h5 class="mb-0">
                              <button class="btn btnFaqs collapsed text-left" data-bs-toggle="collapse" (click)="this.openCloseColllapse('collapseTwelve')" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                ¿Qué significa que haya una nueva versión de la política de privacidad de NAOS CLUB?
                              </button>
                            </h5>
                          </div>
                          <div id="collapseTwelve" class="collapse">
                            <div class="card-body">
                              En NAOS CLUB somos conscientes de que para nuestros afiliados su privacidad es importante. Por ello, hemos modificado nuestra política de privacidad para ofrecer a nuestros afiliados la información relativa a los tratamientos que realizamos de sus datos personales de forma más clara y detallada, de acuerdo con las últimas resoluciones administrativas. Así, la nueva versión de la política de privacidad de NAOS CLUB especifica los distintos datos personales de nuestros afilados que empleamos en cada tratamiento de datos y durante cuánto tiempo los conservamos. La nueva política de privacidad también incluye la dirección de contacto de nuestro Delegado de Protección de Datos, cuya función es, entre otras, la de actuar como punto de contacto entre NAOS y los afiliados de NAOS CLUB para gestionar cualquier cuestión relativa a la protección de los datos personales de estos últimos. Por último, en la nueva política de privacidad también informamos a nuestros afiliados de la contratación de un nuevo proveedor del servicio de atención al afiliado ubicado en Colombia y de que, para garantizar el cumplimiento de la normativa vigente aplicable en protección de datos personales, NAOS ha firmado don dicho proveedor las correspondientes cláusulas contractuales tipo para la transferencia de datos personales a los encargados del tratamiento establecidos en terceros países, aprobadas por la Comisión Europea, garantizando así la privacidad de sus afiliados. En conclusión, la nueva versión de la política de privacidad de NAOS CLUB significa más información de forma más transparente.
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
            </div><br><br>
          </div>

        </div>
      </ng-container>
    </div>
  </div>

</div>

<!-- <app-naosnotification [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationContact" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 filaLogo">
              <img src="../../../assets/images/LOGO-NaosClub.png" />
          </div>
        </div><br>
        <div class="row">
            <div class="col-md-12 filaLogo2" id="contenidodiv">
              {{ this.textModal }}
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
      </div>
    </div>
  </div>
</div>
